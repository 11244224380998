import React, { useMemo } from 'react';
import { Box, FormControl } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import { Config } from 'components/RenderFormField/types';
import MuiTextField from 'components/MuiTextField';
import MuiSelect from 'components/MuiSelect';

interface Props {
    field: ControllerRenderProps<TFieldValues, TName>;
    required: boolean;
    associationFields: Config[];
}

const NewAssociationFields = ({
    field: { value = {}, onChange, onBlur, ref },
    required = false,
    associationFields = [],
}: Props) => {
    const {
        countriesList,
        currenciesList,
        languagesList,
        unitsList,
        productsAttributesSetsList,
        stockLocationsList,
        suppliersList,
        productConditionsList,
    } = useRelatedDataContext();
    const allValueFromRelated = useMemo(() => ({
        countriesList,
        currenciesList,
        languagesList,
        unitsList,
        productsAttributesSetsList,
        stockLocationsList,
        suppliersList,
        productConditionsList,
    }), [
        countriesList,
        currenciesList,
        languagesList,
        unitsList,
        productsAttributesSetsList,
        stockLocationsList,
        suppliersList,
        productConditionsList,
    ]);

    return (
        <Box
            sx={{
                display: 'flex',
                border: theme => `1px solid ${theme.palette.grey[400]}`,
                borderRadius: 2,
                padding: 1,
                marginLeft: -1,
            }}
        >
            {associationFields.map(({ configurationKey, label, type, valueFromRelated, valueOptions }) => {
                const val = value[configurationKey] || '';
                const handleChange = e => onChange({ ...value, [configurationKey]: e.target.value });
                if (type === 'singleSelect') {
                    return (
                        <MuiSelect
                            key={configurationKey}
                            label={label}
                            options={valueOptions || allValueFromRelated[valueFromRelated]}
                            sx={{ marginRight: 4 }}
                            value={val}
                            onChange={handleChange}
                            required={required}
                        />
                    )
                } else {
                    return (
                        <FormControl key={configurationKey} variant="standard" fullWidth>
                            <MuiTextField
                                label={label}
                                value={val}
                                onChange={handleChange}
                                required={required}
                            />
                        </FormControl>
                    )
                }
            })}
        </Box>
    )
}

export default NewAssociationFields;
