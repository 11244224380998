import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useTranslate } from "react-admin";
import MuiSelect from "components/MuiSelect";
import { DataGridPro } from "@mui/x-data-grid-pro";

const WorkflowUsage = ({
  workflowOptions = [],
  workflowOpt = "unknown-value",
  setWorkflowOpt,
  isLoading,
  workflowUsageData = {},
}) => {
  const translate = useTranslate();

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">{translate("bb2.pages.overview")}</Typography>
          <Typography variant="caption">{translate("bb2.fields.workflow_usage_per_month")}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          {workflowOptions.length && (
            <MuiSelect
              fullWidth
              options={workflowOptions}
              value={workflowOpt}
              onChange={(e) => setWorkflowOpt(e.target.value)}
            />
          )}
        </Grid>
        <Box sx={{ width: "100%", height: 422, ml: 2, mt: 2 }}>
          <DataGridPro
            columns={[
              { field: "workflow_name", headerName: translate("bb2.pages.workflow"), flex: 1 },
              { field: "total_usage_per_workflow", headerName: translate("bb2.fields.usage"), minWidth: 150, align: "right", headerAlign: "right" },
            ]}
            rows={workflowUsageData[workflowOpt] || []}
            disableSelectionOnClick
            loading={isLoading}
          />
        </Box>
      </Grid>
    </Paper>
  );
};

export default WorkflowUsage;
