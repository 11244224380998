export const calcPercent = (current, last) => {
  let percent;
  if (last === current) {
    percent = 0;
  } else if (last === 0) {
    percent = 100;
  } else {
    percent = (current - last) * 100.0 / last;
  }
  return percent;
};
