import React, { useState } from 'react';
import { FormGroup, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslate } from 'react-admin';
import MuiTextField from 'components/MuiTextField';

const PasswordVisible = ({
    name = "password",
    label = "Password",
    control,    // reference in useForm
    errors,     // reference in useForm
    required = true,
    rules,
}) => {

    const translate = useTranslate();
    const [show, setShow] = useState(false);

    return (
        <FormGroup>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                rules={rules}
                render={({ field }) => (
                    <MuiTextField
                        label={label}
                        required={required}
                        type={show ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShow(!show)}
                                        onMouseDown={e => e.preventDefault()}
                                        edge="end"
                                    >
                                        {show ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...field}
                    />
                )}
            />
            {(errors[name] && errors[name].type === "minLength") && <FormHelperText error>Please input at least 8 characters.</FormHelperText>}
            {(errors[name] && errors[name].type === "maxLength") && <FormHelperText error>Please input less than 64 characters.</FormHelperText>}
            {(errors[name] && errors[name].type === "validate") && <FormHelperText error>{translate('pos.password_mismatch')}</FormHelperText>}
        </FormGroup>
    )
}

export default PasswordVisible
