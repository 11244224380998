import { defaultTheme } from "react-admin";
import { colors, Theme } from "@mui/material";

export const NOLAYOUT_FORM_WIDTH = 450;
export const DRAWER_WIDTH = 280;
export const CLOSED_DRAWER_WIDTH = 55;
export const MUI_COLORS = colors;

export const OUR_COLORS = {
  BLACK: "#231f20",
  WHITE: "#ffffff",
  PINK: "#fa1a96",
  GOLD: "#ffb759",
  GRAY: {
    200: "#f6f6f6",
    300: "#f0f0f0",
    400: "#cccccc",
    500: "#a5a5a5",
    600: "#7e7e7e",
    700: "#606060",
    800: "#000000",
  },
  GREEN: {
    400: "#c0f2d9",
    500: "#7fe5b3",
    600: "#40d88b",
    700: "#1cb783",
    800: "#00ad6d",
  },
  BLUE: {
    400: "#90caf9",
    500: "#64b5f6",
    600: "#2196f3",
    700: "#007dc7",
    800: "#2979ff",
  },
  ORANGE: {
    400: "#ffCC00",
    600: "#fda769",
    700: "#ff9900",
  },
};

export const GRAY_BG = OUR_COLORS.GRAY[600];
export const COLORS = {
  GREEN: window.Cypress
    ? window.Cypress.env("REACT_APP_THEME_COLORS_PRIMARY_GREEN")
    : process.env.REACT_APP_THEME_COLORS_PRIMARY_GREEN,
  BLUE: window.Cypress
    ? window.Cypress.env("REACT_APP_THEME_COLORS_PRIMARY_BLUE")
    : process.env.REACT_APP_THEME_COLORS_PRIMARY_BLUE,
  SECONDARY: window.Cypress
    ? window.Cypress.env("REACT_APP_THEME_COLORS_SECONDARY")
    : process.env.REACT_APP_THEME_COLORS_SECONDARY,
};
export const spacing = (num) => {
  return `${num * 8}px`;
};

export interface THEME_DATA {
  logo: string;
  pageTitle: string;
  bodyBgColor: string;
  primaryColor: string;
  secondaryColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  disabledTextColor: string;
  boldTextColor: string;
  formTextColor: string;
  formLabelColor: string;
  sidebarBg: string;
  sidebarTextColor: string;
  sidebarTextColor: string;
  sidebarExpandedBg: string;
  sidebarActiveTextColor: string;
  sidebarActiveBg: string;
  sidebarHoverBg: string;
  sidebarHoverTextColor: string;
  error: string;
  success: string;
  false: string;
  true: string;
}

export const theme_data: THEME_DATA = {
  logo: "/logo.png",
  pageTitle: "Intranet",
  bodyBgColor: "",
  primaryColor: "",
  secondaryColor: "",
  primaryTextColor: "",
  secondaryTextColor: "",
  disabledTextColor: "",
  boldTextColor: "",
  formTextColor: "",
  formLabelColor: "#839496",
  error: "#d32f2f",
  success: "#03cc02",
  false: "#f04244",
  true: "#599f57",
  // Sidebar
  sidebarBg: OUR_COLORS.WHITE,
  sidebarTextColor: "#00000080",
  sidebarExpandedBg: OUR_COLORS.WHITE,
  sidebarActiveTextColor: OUR_COLORS.BLACK,
  sidebarActiveBg: OUR_COLORS.GRAY[300],
  sidebarHoverBg: OUR_COLORS.GRAY[300],
  sidebarHoverTextColor: OUR_COLORS.BLACK,
};

/**
 * @param {'GREEN' | 'BLUE'} primaryKey
 */
export const getTheme = (primaryKey = "GREEN"): Theme => {
  const primaryColor = COLORS[primaryKey];
  const secondaryColor = COLORS.SECONDARY;
  const primaryFontFamily = window.Cypress
    ? window.Cypress.env("REACT_APP_THEME_FONT_FAMILY")
    : process.env.REACT_APP_THEME_FONT_FAMILY;
  const theme: Theme = {
    ...defaultTheme,
    sidebarBg: theme_data.sidebarBg,
    sidebarTextColor: theme_data.sidebarTextColor,
    sidebarExpandedBg: theme_data.sidebarExpandedBg,
    sidebarActiveTextColor: theme_data.sidebarActiveTextColor,
    sidebarActiveBg: theme_data.sidebarActiveBg,
    sidebarHoverBg: theme_data.sidebarHoverBg,
    sidebarHoverTextColor: theme_data.sidebarHoverTextColor,
    palette: {
      primary: {
        main: primaryColor,
        contrastText: secondaryColor,
      },
      secondary: {
        main: OUR_COLORS.BLACK,
        contrastText: secondaryColor,
      },
      background: {
        default: secondaryColor,
        paper: secondaryColor,
      },
    },
    typography: {
      fontFamily: primaryFontFamily,
    },
    sidebar: {
      width: DRAWER_WIDTH,
      closedWidth: CLOSED_DRAWER_WIDTH,
    },
    components: {
      MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: primaryColor,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            backgroundColor: theme_data.sidebarBg,
          },
          modal: {
            backgroundColor: "transparent",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "40px !important",
          },
        },
      },
      RaMenuItemLink: {
        styleOverrides: {
          root: {
            color: theme_data.sidebarTextColor,
            "& svg": {
              color: theme_data.sidebarTextColor,
            },
            "&.RaMenuItemLink-active": {
              color: theme_data.sidebarActiveTextColor,
              backgroundColor: theme_data.sidebarActiveBg,
              "& svg": {
                color: theme_data.sidebarActiveTextColor,
              },
              "& .MuiListItemIcon-root": {
                marginLeft: "-4px", // as many as borderLeft width
              },
              borderLeft: `4px solid ${primaryColor}`,
            },
            "&:hover": {
              backgroundColor: theme_data.sidebarHoverBg,
              color: theme_data.sidebarHoverTextColor,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: "12px",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "36px",
          },
          indicator: {
            backgroundColor: primaryColor,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: "36px",
            minWidth: "16px",
            color: theme_data.primaryTextColor,
            marginRight: 8,
            paddingTop: `8px !important`,
            paddingBottom: `8px !important`,
            fontWeight: "bold",
            "&:hover": {
              color: OUR_COLORS.ORANGE[600],
            },
            "&.Mui-selected": {
              color: primaryColor,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              "& svg": {
                color: primaryColor,
              },
            },
          },
        },
      },
      MuiTabScrollButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              "& svg": {
                color: primaryColor,
              },
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          deleteIcon: {
            "&:hover": {
              color: primaryColor,
            },
          },
        },
      },
    },
  };
  return theme;
};

export const commonStyles = {
  content_root: {
    border: "1px solid #cccccc",
    borderRadius: "5px",
    padding: "2em",
    backgroundColor: OUR_COLORS.WHITE,
  },
};
