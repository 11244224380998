import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
    DataGridPro,
    deDE,
    GridActionsCellItem,
    GridCallbackDetails,
    GridCellParams,
    GridColumnVisibilityModel,
    GridEnrichedColDef,
    GridFilterModel,
    GridRowId,
    GridRowModes,
    GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    LicenseInfo,
    MuiEvent,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import { Box, Button, Chip, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import {
    Add as AddIcon,
    Save as SaveIcon,
    Cancel as CancelIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { useLocaleState, useNotify, useSidebarState, useTranslate } from 'react-admin';
import moment from 'moment';
import { defaultDateTimeFormat } from 'utils/time-format';
import MuiModal from 'components/MuiModal';
import CreateModal from './CreateModal';
import { useApi } from 'contexts/useApi';
import { useUserView } from 'contexts/UserViewsContext';
import { useDirtyContext } from 'contexts/DirtyContext';
import Iframe from 'react-iframe';
import { CLOSED_DRAWER_WIDTH, DRAWER_WIDTH, OUR_COLORS } from 'theme/theme2';
import { GridConfig } from 'contexts/UserViewsContext';
import cloneDeep from 'clone-deep';
import MuiTextField from 'components/MuiTextField';
import CustomPagination from './CustomPagination';

LicenseInfo.setLicenseKey(window.Cypress ? window.Cypress.env('REACT_APP_DATA_GRID_PRO_KEY') : process.env.REACT_APP_DATA_GRID_PRO_KEY);

const initialPerPage = parseInt(window.Cypress ? window.Cypress.env('REACT_APP_API_DEFAULT_PER_PAGE') : process.env.REACT_APP_API_DEFAULT_PER_PAGE, 10);

export type SingleSelectType = 'number' | 'string';
export type Column = GridEnrichedColDef & {
    onCellDoubleClick?: (params: GridCellParams, event: MuiEvent) => void;
    singleSelectType?: SingleSelectType; // default is 'number';
    required?: boolean;
    renderNewCell?: (field: ControllerRenderProps<TFieldValues, TName>, required: boolean) => React.ReactNode;
    paramsNewCell?: {
        resource: string;
        parentIdField: string;
    };
    deleteConstraint?: {
        resource: string;
        parentIdField: string;
    };
    sortField?: string; // needs when the column has valueFormatter
    helpdeskLink?: string;
    apiProps?: {
        setRows: (prev) => {};
        setRowModesModel: (prev) => {};
    }
};

type DataOnSaveGridConf = {
    id?: number;
    users: any[];
    title: string;
}

export interface Props {
    isLoading: boolean;
    setRows: (rows: any[]) => void;
    rows: any[];
    columns: Column[];
    page: number; // 1-based
    onPageSizeChange: (perPage) => {};
    onPageChange: (page/*zero-based*/) => {};
    onFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails) => {};
    onSortModelChange: (model: GridSortModel, details: GridCallbackDetails) => {};
    keepFieldsOnUpdate?: string[];
    mutateOnUpdate?: (one) => one;
    exParamsOnCreate?: any;
    resource: string;
    addNewByRecord?: boolean;
    noPagination?: boolean;
    cbAfterSubmitCreateModal?: () => void;
    cbAfterUpdateRows: (deletedRowId: any, updatedRow: any) => void;
    gotoDetailOnCellClick: boolean;
    readonly?: boolean;
    disableCreate?: boolean;
    disableSaveGridConf?: boolean;
    dataOnSaveGridConf?: DataOnSaveGridConf;
    CustomEditModalContentComponent?: React.ComponentType;
    deleteRowCustom?: (row) => Promise<void>;
}

const MuiGrid = ({
    isLoading = false,
    setRows,
    rows,
    columns = [],
    page = 1,
    onPageSizeChange = (perPage) => { },
    onPageChange = (page/*zero-based*/) => { },
    onFilterModelChange,
    onSortModelChange,
    keepFieldsOnUpdate = [],
    mutateOnUpdate,
    exParamsOnCreate = {},
    resource,
    addNewByRecord = false,
    noPagination = false,
    cbAfterSubmitCreateModal = () => { },
    cbAfterUpdateRows = (deletedRowId, updatedRow) => { },
    gotoDetailOnCellClick = false,
    readonly = false,
    disableCreate = false,
    disableSaveGridConf = false,
    dataOnSaveGridConf = {},
    CustomEditModalContentComponent,
    deleteRowCustom,
}: Props) => {

    const apiRef = useGridApiRef();
    const [locale = 'de'] = useLocaleState();
    const navigate = useNavigate();
    const translate = useTranslate();
    const notify = useNotify();
    const [sidebarIsOpen] = useSidebarState();
    const { recursiveGetList, createRecord, updateRecord, deleteRecord } = useApi();
    const { userViews, getGridConfigs, saveOneGridConf } = useUserView();
    const [isSaving, setIsSaving] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const isGridLoading = isLoading || isUpdating || isDeleting || isSaving;
    const [deletingRow, setDeletingRow] = useState();
    const [openAddNewModal, setOpenAddNewModal] = useState(false);
    const [openEditModalRow, setOpenEditModalRow] = useState();
    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    }

    const { setIsDirty } = useDirtyContext();
    const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        setIsDirty(true);
        event.defaultMuiPrevented = true;
    }
    const handleRowEditStop = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        setIsDirty(false);
        event.defaultMuiPrevented = true;
    }

    const [currentColumnVisibilityModel, setCurrentColumnVisibilityModel] = useState();
    const handleColumnVisibilityModelChange = (model: GridColumnVisibilityModel, details: GridCallbackDetails) => {
        setCurrentColumnVisibilityModel(model);
    }
    const [currentSortModel, setCurrentSortModel] = useState();
    const handleSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
        setCurrentSortModel(model);
        if (onSortModelChange) onSortModelChange(model, details);
    }

    const [currentFilterModel, setCurrentFilterModel] = useState();
    const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
        setCurrentFilterModel(model);
        if (onFilterModelChange) onFilterModelChange(model, details);
    }
    const handleDeleteFilter = (columnField) => () => {
        const fModel: GridFilterModel = { ...currentFilterModel };
        const index = fModel.items.findIndex(item => item.columnField === columnField);
        fModel.items.splice(index, 1);
        apiRef.current.setFilterModel(fModel);
    }

    const [contextMenu, setContextMenu] = useState(null);
    const [filterInjecting, setFilterInjecting] = useState(null);
    const [helpdeskLink, setHelpdeskLink] = useState(null);
    const handleContextMenu = (e: MouseEvent) => {
        e.preventDefault();
        const rowId = e.currentTarget.parentElement.getAttribute('data-id');
        const row = apiRef.current.getRow(rowId);
        const columnField = e.currentTarget.getAttribute('data-field');
        const value = row[columnField];
        const column = columns.find(col => col.field === columnField);
        let formattedValue = value;
        if (column.valueFormatter) {
            formattedValue = column.valueFormatter({ value });
        }
        setContextMenu(
            contextMenu === null
                ? { mouseX: e.clientX - 2, mouseY: e.clientY - 4, row, column, columnField, value, formattedValue }
                : null
        );
    }
    const showFilter = () => {
        // eslint-disable-next-line
        const { row, column, columnField, value, formattedValue } = contextMenu;
        setContextMenu(null);
        apiRef.current.showFilterPanel(columnField);
        setFilterInjecting({ columnField, value });
    }
    const showHelpdesk = () => {
        setHelpdeskLink(contextMenu.column.helpdeskLink);
        setContextMenu(null);
    }
    useEffect(() => {
        if (!filterInjecting) return;
        const { columnField, value } = filterInjecting;
        const fModel: GridFilterModel = { ...currentFilterModel };
        const fModelItem = fModel?.items.find(item => item.columnField === columnField);
        if (!fModelItem) return;
        fModelItem.value = value;
        apiRef.current.setFilterModel(fModel);
        setFilterInjecting(null);
        // eslint-disable-next-line
    }, [filterInjecting, currentFilterModel]);

    const [pageSize, setPageSize] = useState(initialPerPage);
    const [realPage, setRealPage] = useState(page); // 1-based
    const handlePaginationModelChange = (pageModel) => {
        if (!pageModel) return;
        const { page: p, pageSize: newPageSize } = pageModel;
        setRealPage(p + 1);
        onPageChange(p);
        setPageSize(newPageSize);
        onPageSizeChange(newPageSize);
    }
    useEffect(() => setRealPage(page), [page]);

    const [configTitle, setConfigTitle] = useState('');
    const [openConfigTitleModal, setOpenConfigTitleModal] = useState(false);
    const [selectedGridConfigIndex, setSelectedGridConfigIndex] = useState(0);
    const [gridConfigs, setGridConfigs] = useState([]);
    const [gridConfigsCountBeforeSave, setGridConfigsCountBeforeSave] = useState();
    const saveGridConf = async () => {
        setOpenConfigTitleModal(false);
        setIsSaving(true);
        const _gridConfigsCount = gridConfigs.length;
        let title = configTitle;
        if (dataOnSaveGridConf?.users) title = dataOnSaveGridConf?.title;
        const { error } = await saveOneGridConf(resource, dataOnSaveGridConf?.id, title, dataOnSaveGridConf?.users, {
            columnModel: currentColumnVisibilityModel,
            sortModel: currentSortModel,
            filterModel: currentFilterModel,
        }, pageSize);
        if (!error) {
            notify('pos.configuration.config_save_success', { type: 'success' });
            setGridConfigsCountBeforeSave(_gridConfigsCount);
        }
        setIsSaving(false);
    }
    const handleSaveGridConf = async () => {
        if (!resource) return;
        if (!dataOnSaveGridConf.users) {
            setConfigTitle('');
            setOpenConfigTitleModal(true);
            return;
        }
        await saveGridConf();
    }
    // We should consider the first member of configs array is default
    const handleChangeGridConfig = (e, newValue, configs) => {
        setSelectedGridConfigIndex(newValue);
        if (newValue === null || newValue === undefined) return;
        const defaultVisibilityModel = {};
        columns.forEach(c => defaultVisibilityModel[c.field] = !(c.hide || false));
        const defaultSortModel = [];
        const defaultFilterModel = {
            items: [],
            linkOperator: "and",
            quickFilterLogicOperator: "and",
            quickFilterValues: [],
        }
        if (newValue === 0) {
            setPageSize(initialPerPage);
            setCurrentColumnVisibilityModel(defaultVisibilityModel);
            apiRef.current.setColumnVisibilityModel(defaultVisibilityModel);
            setCurrentSortModel(defaultSortModel);
            apiRef.current.setSortModel(defaultSortModel);
            setCurrentFilterModel(defaultFilterModel);
            apiRef.current.setFilterModel(defaultFilterModel);
            return;
        }
        const selectedConfig: GridConfig = (configs || gridConfigs)[newValue - 1];
        if (selectedConfig.pageSize) setPageSize(selectedConfig.pageSize);
        if (selectedConfig.models.columnModel) {
            const model = cloneDeep(selectedConfig.models.columnModel);
            setTimeout(() => {
                setCurrentColumnVisibilityModel(model);
                apiRef.current.setColumnVisibilityModel(model);
            }, 200);
        } else {
            setCurrentColumnVisibilityModel(defaultVisibilityModel);
            apiRef.current.setColumnVisibilityModel(defaultVisibilityModel);
        }
        if (selectedConfig.models.sortModel) {
            const model = cloneDeep(selectedConfig.models.sortModel);
            setCurrentSortModel(model);
            apiRef.current.setSortModel(model);
        } else {
            setCurrentSortModel(defaultSortModel);
            apiRef.current.setSortModel(defaultSortModel);
        }
        if (selectedConfig.models.filterModel) {
            const model = cloneDeep(selectedConfig.models.filterModel);
            setCurrentFilterModel(model);
            apiRef.current.setFilterModel(model);
        } else {
            setCurrentFilterModel(defaultFilterModel);
            apiRef.current.setFilterModel(defaultFilterModel);
        }
    }
    useEffect(() => {
        if (!resource) return;
        if (userViews.length === 0) return;
        const _gridConfigs = getGridConfigs(resource, dataOnSaveGridConf.id);
        setGridConfigs(_gridConfigs);
        if (dataOnSaveGridConf.id && _gridConfigs.length >= 0) {
            handleChangeGridConfig(null, 1, _gridConfigs);
        }
        // eslint-disable-next-line
    }, [resource, userViews.length, dataOnSaveGridConf.id]);
    useEffect(() => {
        if (gridConfigsCountBeforeSave === undefined) return;
        if (gridConfigs.length > gridConfigsCountBeforeSave) {
            handleChangeGridConfig(null, gridConfigs.length);
            setGridConfigsCountBeforeSave();
        }
        // eslint-disable-next-line
    }, [gridConfigsCountBeforeSave, gridConfigs.length]);

    const handleCellClick = ({ row }) => {
        if (!gotoDetailOnCellClick) return;
        navigate(`${row.id}`);
    }
    const handleCellDoubleClick = (params: GridCellParams, event: MuiEvent) => {
        if (readonly) {
            event.defaultMuiPrevented = true;
            return;
        }
        if (params.colDef.onCellDoubleClick) {
            params.colDef.onCellDoubleClick(params, event);
        }
    }

    /**
     * @param {'update' | 'remove'} method : must
     * @param {number} id : must
     * @param {any} data : should be defined if method is 'update'.
     */
    const setRow = (method = 'update', id, data = undefined) => {
        let deletedRowId;
        let updatedRow;
        if (method === 'remove') {
            setRows(rows.filter(row => row.id !== id));
            deletedRowId = id;
        } else if (method === 'update') {
            const oldRow = apiRef.current.getRow(id);
            if (oldRow) {
                keepFieldsOnUpdate.forEach(field => data[field] = oldRow[field]);
            }
            updatedRow = mutateOnUpdate ? mutateOnUpdate(data) : data;
            if (id !== data.id) {
                deletedRowId = id;
            }
            setRows(rows.map(row => (row.id === id ? updatedRow : row)));
        } else {
            notify('messages.impossible', { type: 'error' });
            return;
        }
        cbAfterUpdateRows(deletedRowId, updatedRow);
        return updatedRow;
    }

    const getUpdatedObj = ({oldObj, newObj}) => {
        const diff = {};
        for (const key of Object.keys(newObj)) {
            if (newObj[key] && typeof newObj[key] === 'object') {
                if (newObj[key].getTime) {
                    newObj[key] = moment(newObj[key]).utc().format(defaultDateTimeFormat);
                } else {
                    continue;
                }
            }
            if (newObj[key] === oldObj[key]) continue;
            diff[key] = newObj[key];
        }
        diff.id = newObj.id;
        delete diff.gridIsNew;
        return diff;
    }

    const processRowUpdate = async (newRow, oldRow) => {
        if (!resource) {
            console.error('MuiGrid: You should provide resource prop.');
            return oldRow;
        }
        const diff = getUpdatedObj({ oldObj: oldRow, newObj: newRow });
        if (Object.keys(diff).length > 1) {
            setIsUpdating(true);
            if (newRow.gridIsNew) {
                const { data, error } = await createRecord(resource, { ...diff, ...exParamsOnCreate });
                setIsUpdating(false);
                if (!error) {
                    notify('messages.createSuccess', { type: 'success' });
                    return setRow('update', newRow.id, data);
                }
            } else {
                const { data, error } = await updateRecord(resource, diff);
                setIsUpdating(false);
                if (!error) {
                    notify('messages.updateSuccess', { type: 'success' });
                    return setRow('update', newRow.id, data);
                }
            }
        }
        return oldRow;
    }

    const processRowDelete = async (row) => {
        if (!resource) {
            console.error('MuiGrid: You should provide resource prop.');
            return;
        }
        for (const col of columns) {
            if (col.deleteConstraint) {
                const wholeArray = [];
                await recursiveGetList(wholeArray, col.deleteConstraint.resource, {
                    filter: [
                        { type: 'equals', field: col.deleteConstraint.parentIdField, value: row.id }
                    ]
                });
                for (const one of wholeArray) {
                    await deleteRecord(col.deleteConstraint.resource, one.id);
                }
            }
        }
        const { error } = await deleteRecord(resource, row.id);
        if (!error) {
            notify('messages.deleteSuccess', { type: 'success' });
            setRow('remove', row.id);
        }
    }
    const handleDeleteRow = async (e) => {
        e.stopPropagation();
        setIsDeleting(true);
        const oldRow = { ...deletingRow };
        setDeletingRow();
        if (deleteRowCustom) {
            const newRow = await deleteRowCustom(oldRow);
            if (newRow) {
                setRow('update', oldRow.id, newRow);
            }
            setIsDeleting(false);
            return;
        }
        await processRowDelete(oldRow);
        setIsDeleting(false);
    }

    const onCreateNew = (setRows, setRowModesModel) => {
        if (CustomEditModalContentComponent) {
            setOpenEditModalRow(true);
            return;
        }
        if (addNewByRecord) {
            const id = uuidv4();
            setRows(prev => [...prev, { id, gridIsNew: true }]);
            const editable = columns.find(c => c.editable === true)
            setRowModesModel(prev => ({
              ...prev,
              [id]: { mode: GridRowModes.Edit, fieldToFocus: editable?.field },
            }));
            setIsDirty(prev => true);
            return;
        }
        setOpenAddNewModal(true);
    }

    const handleEditClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
      if (CustomEditModalContentComponent) {
            const row = apiRef.current.getRow(id);
            setOpenEditModalRow(row);
            return;
        }
        setIsDirty(true);
    }
    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        setIsDirty(false);
    }
    const handleDeleteClick = (id: GridRowId) => () => {
        const row = rows.find(row => row.id === id);
        if (row.gridIsNew) {
            setRow('remove', row.id);
        } else {
            setDeletingRow(row);
        }
    }
    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const row = rows.find(row => row.id === id);
        if (row.gridIsNew) {
            setRow('remove', row.id);
        }
        setIsDirty(false);
    }

    const columnsWithActions = useMemo(() => {
        return [
            ...columns.map(col => ({ ...col, apiProps: { setRows, setRowModesModel } })),
            {
                field: 'actions', type: 'actions', headerName: translate('pos.actions'), width: 100, cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label={translate('pos.save')}
                                onClick={handleSaveClick(id)}
                                color="primary"
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label={translate('pos.cancel')}
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />,
                        ];
                    }
          
                    return [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label={translate('pos.edit')}
                            className="textPrimary"
                            onClick={handleEditClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label={translate('pos.delete')}
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ];
                },
            },
        ];
        /* eslint-disable-next-line */
    }, [columns, rowModesModel]);

    return (
        <Box
            name="DATAGRID-CONTAINER"
            sx={{
                position: 'relative',
                bgcolor: OUR_COLORS.WHITE,
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                flexBasis: 0,
                height: '100%',
                minHeight: 400,
                maxWidth: `calc(100vw - ${sidebarIsOpen ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH}px - 32px)`
            }}
        >
            <DataGridPro
                apiRef={apiRef}
                loading={isGridLoading}
                rows={rows}
                columns={readonly ? columns : columnsWithActions}
                localeText={locale === 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : undefined}
                initialState={{ pinnedColumns: { left: [], right: ['actions'] } }}
                onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                density='compact'
                onCellClick={handleCellClick}
                onCellDoubleClick={handleCellDoubleClick}
                // edit
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                processRowUpdate={processRowUpdate}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                // pagination
                pagination={!noPagination}
                autoPageSize={false}
                paginationMode="client"
                pageSizeOptions={[initialPerPage, 50, 100]}
                paginationModel={{
                    page: realPage - 1,
                    pageSize,
                }}
                onPaginationModelChange={handlePaginationModelChange}
                // sort
                onSortModelChange={handleSortModelChange}
                // filter
                onFilterModelChange={handleFilterModelChange}
                // selection
                checkboxSelection={false}
                keepNonExistentRowsSelected
                disableSelectionOnClick
                // toolbar
                slots={{
                    pagination: CustomPagination,
                    NoRowsOverlay: React.Fragment,
                    NoResultsOverlay: React.Fragment,
                    toolbar: ({ setRows, setRowModesModel }) => (
                        <>
                            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                                <GridToolbarContainer style={{ flexWrap: 'wrap' }}>
                                    {(!readonly && !disableCreate) && <Button color="secondary" onClick={() => onCreateNew(setRows, setRowModesModel)} startIcon={<AddIcon />} sx={{ whiteSpace: 'nowrap' }}>
                                        {translate('pos.add_new')}
                                    </Button>}
                                    <GridToolbarFilterButton color="secondary" />
                                    <GridToolbarColumnsButton color="secondary" />
                                    <GridToolbarExport color="secondary" />
                                    {(!addNewByRecord && !disableSaveGridConf) && <Button color="secondary" onClick={handleSaveGridConf} startIcon={<SaveIcon />} sx={{ whiteSpace: 'nowrap' }}>
                                        {translate('pos.save_grid_conf')}
                                    </Button>}
                                </GridToolbarContainer>
                                <Box display="flex" flexWrap="wrap" ml={1}>
                                    {currentFilterModel?.items?.length ?
                                        currentFilterModel?.items?.map(filter => {
                                            const headerName = columns.find(c => c.field === filter.columnField).headerName;
                                            return <Chip key={filter.columnField} label={headerName} size="small" sx={{ mt: 1, mr: 1 }} onDelete={handleDeleteFilter(filter.columnField)} />;
                                        })
                                        :
                                        null
                                    }
                                </Box>
                            </Box>
                            {(!disableSaveGridConf && gridConfigs.length > 0 && !dataOnSaveGridConf.id && !dataOnSaveGridConf.users) && (
                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                    <Typography sx={{ mr: 1 }}>{translate('pos.configuration.grid_config_buttons_label')}</Typography>
                                    <ToggleButtonGroup
                                        color="secondary"
                                        exclusive
                                        value={selectedGridConfigIndex}
                                        onChange={handleChangeGridConfig}
                                    >
                                        <ToggleButton value={0} size="small" sx={{ textTransform: 'inherit' }}>
                                            {translate('pos.configuration.default')}
                                        </ToggleButton>
                                        {gridConfigs.map((gridConfig: GridConfig, i) => (
                                            <ToggleButton key={i+1} value={i+1} size="small" sx={{ textTransform: 'inherit' }}>
                                                {gridConfig.title}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                </Box>
                            )}
                        </>
                    ),
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                    cell: { onContextMenu: handleContextMenu },
                }}
            />
            <Menu
                open={contextMenu !== null}
                onClose={() => setContextMenu(null)}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                            e.preventDefault();
                            setContextMenu(null);
                        },
                    },
                }}
            >
                <MenuItem onClick={showFilter}>{`${translate('pos.dataGrid.filterFor')} "${contextMenu?.formattedValue}"`}</MenuItem>
                {contextMenu && contextMenu.column.helpdeskLink && <MenuItem onClick={showHelpdesk}>{translate('pos.dataGrid.showHelpdesk')}</MenuItem>}
            </Menu>
            <CreateModal
                open={openAddNewModal}
                onClose={() => setOpenAddNewModal(false)}
                columns={columns}
                resource={resource}
                cbAfterSubmitCreateModal={cbAfterSubmitCreateModal}
            />
            <MuiModal
                open={!!deletingRow}
                onClose={() => setDeletingRow()}
                title={<Typography color="error">{translate('pos.warning')}</Typography>}
                dialogContents={
                    <>
                        <Typography>{translate('pos.delete_warning.msg1')}</Typography>
                        <Typography>{translate('pos.delete_warning.msg2')}</Typography>
                    </>
                }
                dialogActions={
                    <>
                        <Button variant="outlined" onClick={() => setDeletingRow()}>{translate('pos.cancel')}</Button>
                        <Button variant="contained" color="error" onClick={handleDeleteRow}>{translate('pos.delete')}</Button>
                    </>
                }
            />
            <MuiModal
                open={!!helpdeskLink}
                onClose={() => setHelpdeskLink(null)}
                title={translate('pos.dataGrid.help')}
                fullWidth
                fullHeight
                dialogContents={
                    <Iframe
                        url={helpdeskLink}
                        width='100%'
                        height='100%'
                        position='relative'
                        loading={translate('pos.iframe.loading')}
                    />
                }
            />
            {!!openEditModalRow &&
                <MuiModal
                    open={!!openEditModalRow}
                    onClose={() => {
                        setRowModesModel(prev => ({
                            ...prev,
                            [openEditModalRow.id]: { mode: GridRowModes.View }
                        }));
                        setOpenEditModalRow();
                    }}
                    title={translate(openEditModalRow === true ? 'pos.add_new' : 'pos.edit')}
                    fullWidth
                    fullHeight
                    dialogContents={
                        <CustomEditModalContentComponent isCreating={openEditModalRow === true} editingRow={openEditModalRow} />
                    }
                />
            }
            {openConfigTitleModal &&
                <MuiModal
                    open={openConfigTitleModal}
                    onClose={() => setOpenConfigTitleModal(false)}
                    title={translate('pos.configuration.input_title')}
                    dialogContents={
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <MuiTextField
                                label={translate('pos.configuration.conf_title')}
                                autoFocus
                                value={configTitle}
                                onChange={e => setConfigTitle(e.target.value)}
                            />
                        </Box>
                    }
                    dialogActions={
                        <>
                            <Button variant="outlined" onClick={() => setOpenConfigTitleModal(false)}>{translate('pos.cancel')}</Button>
                            <Button variant="contained" onClick={saveGridConf}>{translate('pos.ok')}</Button>
                        </>
                    }
                />
            }
        </Box>
    );
}

export default MuiGrid;
