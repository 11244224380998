import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { useTheme, useTranslate } from 'react-admin';
import { Box, Switch, Typography } from '@mui/material';
import { COLORS, getTheme } from './theme2';

const SelectTheme = () => {
    // eslint-disable-next-line
    const [theme, setTheme] = useTheme();
    const [selectedThemeKey, setSelectedThemeKey] = useLocalStorageState("themeKey", { ssr: false, defaultValue: "GREEN" });
    const translate = useTranslate();

    const handleChangeTheme = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setSelectedThemeKey("BLUE");
            setTheme(getTheme("BLUE"));
        } else {
            setSelectedThemeKey("GREEN");
            setTheme(getTheme("GREEN"));
        }
    }

    return (
        <Box sx={{
            padding: 2,
            border: theme => `1px solid ${theme.palette.grey[400]}`,
        }}>
            <Typography color={COLORS[selectedThemeKey]}>{ translate('pos.select_theme') }</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                <Typography color={COLORS.GREEN}>{ translate('pos.theme_bf') }</Typography>
                <Switch
                    checked={selectedThemeKey === "BLUE"}
                    onChange={handleChangeTheme}
                />
                <Typography color={COLORS.BLUE}>{ translate('pos.theme_pixi') }</Typography>
            </Box>
        </Box>
    )
}

export default SelectTheme;
