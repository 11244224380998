import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import ApexChart from "components/ApexChart";
import CircularProgressAbsolute from "components/CircularProgressAbsolute";
import { useTranslate } from "react-admin";
import { theme_data } from "theme/theme2";

const ErrorBarChart = ({ series = [], loading }) => {
  const translate = useTranslate();
  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container sx={{ position: "relative" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{translate("bb2.fields.performance")}</Typography>
          <Typography variant="caption">{translate("bb2.fields.total_errors")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <ApexChart
            id="error-bar-chart"
            type="bar"
            dateFormat="MMM"
            colors={[theme_data.error]}
            series={[
              {
                name: "Errors",
                data: series,
              }
            ]}
          />
        </Grid>
        <CircularProgressAbsolute active={loading} />
      </Grid>
    </Paper>
  );
};

export default ErrorBarChart;
