import React, { createContext, useContext, useState } from 'react';

const DirtyContext = createContext({
    isDirty: false,
    setIsDirty: (bool) => { },
});

export const useDirtyContext = () => useContext(DirtyContext);

export const DirtyContextContainer = ({ children }) => {
    const [isDirty, setIsDirty] = useState(false);
    return (
        <DirtyContext.Provider
            value={{
                isDirty,
                setIsDirty,
            }}
        >
            {children}
        </DirtyContext.Provider>
    )
}
