import React, { useEffect, useState } from 'react';
import { GridRenderEditCellParams, GridRowModes } from '@mui/x-data-grid-pro';
import MuiModal from 'components/MuiModal';
import { Box, Button } from '@mui/material';
import MuiGrid, { Column } from 'components/MuiGrid';
import { useTranslate } from 'react-admin';
import { useDirtyContext } from 'contexts/DirtyContext';

interface CustomProps {
    associationColumns: Column[];
    associationResource: string;
    parentIdField: string;
    parentMutate?: (one: any) => one;
    modalTitle: string;
}

const AssociationEditModal = ({
    id,
    colDef: { apiProps: { setRows, setRowModesModel } },
    row,
    cellMode,
    hasFocus,
    field,
    associationColumns,
    associationResource,
    parentIdField,
    parentMutate = (one) => one,
    modalTitle,
}: GridRenderEditCellParams & CustomProps) => {
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const { setIsDirty } = useDirtyContext();
    const [modalRows, setModalRows] = useState([]);
    useEffect(() => {
        if (!open) return;
        const _rows = Object.values(row[field] || {});
        if (!(_rows[0] || {}).id) setModalRows([]);
        setModalRows(_rows);
        // eslint-disable-next-line
    }, [open]);

    const handleClose = () => {
        setOpen(false);
        setRowModesModel(prev => ({
            ...prev,
            [id]: { mode: GridRowModes.View }
        }));
        setIsDirty(false);
    }
    
    const cbAfterUpdateRows = (deletedRowId, updatedRow) => {
        const targetValue = { ...row[field] };
        if (deletedRowId && targetValue[deletedRowId]) {
            delete targetValue[deletedRowId];
        }
        if (updatedRow) {
            targetValue[updatedRow.id] = parentMutate(updatedRow);
        }
        setRows(prev => prev.map(r => {
            if (r.id === row.id) {
                return { ...row, [field]: targetValue };
            } else {
                return r;
            }
        }));
    }

    useEffect(() => {
        setOpen(cellMode === 'edit' && hasFocus);
    }, [cellMode, hasFocus]);

    return (
        <MuiModal
            open={open}
            onClose={handleClose}
            title={modalTitle}
            dialogContents={
                <Box sx={{ height: 400 }}>
                    <MuiGrid
                        setRows={setModalRows}
                        rows={modalRows}
                        columns={associationColumns}
                        exParamsOnCreate={{ [parentIdField]: id }}
                        resource={associationResource}
                        addNewByRecord
                        noPagination
                        cbAfterUpdateRows={cbAfterUpdateRows}
                    />
                </Box>
            }
            dialogActions={
                <Button variant="contained" onClick={handleClose}>
                    {translate("pos.ok")}
                </Button>
            }
        />
    )
}

export default AssociationEditModal;
