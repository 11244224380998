import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNotify, useTranslate, useUpdate } from 'react-admin';
import { getCurrentUser } from 'providers/currentUser';
import useLocalStorageState from 'use-local-storage-state';
import { COLORS } from 'theme/theme2';
import MuiTextField from 'components/MuiTextField';

const resource = 'bf-users';

const ProfileForm = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const [selectedThemeKey] = useLocalStorageState("themeKey", { ssr: false, defaultValue: "GREEN" });
    const [updateApi, { isLoading: isUpdating }] = useUpdate(resource);
    const currentUser = getCurrentUser();
    const { control, handleSubmit, setValue, getValues } = useForm({
        defaultValues: {
            id: currentUser.id,
            userName: currentUser.userName,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            email: currentUser.email,
        }
    });

    const onSubmit = ({ id, userName, firstName, lastName, email }) => {
        const data = { id, firstName, lastName, email };
        updateApi(resource, { data }, {
            onSuccess: (data) => {
                notify('messages.updateSuccess', { type: 'success' });
            },
            onError: (error) => {
                // eslint-disable-next-line
                const { code, detail, status, title } = (error.body?.errors || [])[0] || {};
                notify(`An error occurred with "${detail}"`, { type: 'error' });
            }
        });
    }

    useEffect(() => {
        const { id, userName, firstName, lastName, email } = getValues();
        if (
            id === currentUser.id &&
            userName === currentUser.userName &&
            firstName === currentUser.firstName &&
            lastName === currentUser.lastName &&
            email === currentUser.email
        ) return;
        setValue("id", currentUser.id);
        setValue("userName", currentUser.userName);
        setValue("firstName", currentUser.firstName);
        setValue("lastName", currentUser.lastName);
        setValue("email", currentUser.email);
    }, [currentUser, setValue, getValues]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    padding: 2,
                    border: theme => `1px solid ${theme.palette.grey[400]}`,
                }}
            >
                <Typography color={COLORS[selectedThemeKey]}>{translate('pos.profile')}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Box sx={{ padding: '1em 1em 1em 1em' }}>
                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field }) => (<MuiTextField {...field} label={translate('pos.first_name')} autoFocus required />)}
                        />
                    </Box>
                    <Box sx={{ padding: '1em 1em 1em 1em' }}>
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field }) => (<MuiTextField {...field} label={translate('pos.last_name')} required />)}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ padding: '1em 1em 1em 1em' }}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (<MuiTextField {...field} label={translate('pos.email')} required />)}
                        />
                    </Box>
                </Box>
                <Box sx={{ padding: '1em 1em 1em 1em', width: 235 }}>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isUpdating}
                        fullWidth
                    >
                        {isUpdating && <CircularProgress size={25} thickness={2} />}
                        {translate('pos.save')}
                    </Button>
                </Box>
            </Box>
        </form>
    )
}

export default ProfileForm;
