import {Title, useNotify, useTranslate} from 'react-admin';
import {Grid, Paper} from "@mui/material";
import React, {useState} from "react";
import MuiTextField from "../../components/MuiTextField";
import {Button} from '@mui/material';
import {awsCompleteNewPasswordChallenge} from 'utils/awsCognito';
import {useCookies} from 'react-cookie';
import {cookieDomain} from "../../utils/cookie";
import {setCurrentBB2User} from "../../providers/currentUser";
import {useLocation, useNavigate} from "react-router-dom";

export const BB2PassReset = () => {

    const [, setCookie] = useCookies();
    const [setDeviceName] = useState("");
    const {state = {}} = useLocation();
    const navigate = useNavigate();
    const translate = useTranslate();
    const notify = useNotify();

    const onError = (err) => {
        notify(err.message, {type: 'error'});
    };

    const onPasswortChangeSuccess = (accessToken, idToken, refreshToken, userObj) => {

        setDeviceName("");
        setCookie("accessToken", accessToken, {domain: cookieDomain, path: "/"});
        setCookie("idToken", idToken, {domain: cookieDomain, path: "/"});
        setCookie("refreshToken", refreshToken, {domain: cookieDomain, path: "/"});
        setCurrentBB2User(userObj);
        setLoading(false);

        notify(translate('pos.temp_password_has_changed'), {type: 'success'});
        const target = state.target || 'overview';
        navigate(`/bb2/${target}`);
    };

    const handleSubmit = (event) => {
        const newPassword = event.target.newPassword.value;
        const newPasswordRepeat = event.target.newPasswordRepeat.value;

        if (newPassword === newPasswordRepeat) {
            awsCompleteNewPasswordChallenge(newPassword, onPasswortChangeSuccess, onError);
        } else {
            notify(translate('pos.passwords_mismatch'), {type: 'warning'});
        }

        event.preventDefault();
    };

    const [loading, setLoading] = useState(false);

    return (
        <form onSubmit={handleSubmit}>
            <Title title="Temporäres Passwort ersetzen"/>
            <Paper sx={{padding: 3, maxWidth: 400, margin: 'auto'}}>
                <Grid container rowSpacing="5">
                    <Grid item xs="12">
                        <p>{translate('pos.temporary_password_change_title')}</p>
                    </Grid>
                </Grid>
                <Grid container rowSpacing="10">
                    <Grid item xs="12">
                        <MuiTextField
                            id="newPassword"
                            name="newPassword"
                            label={translate('pos.temporary_password_new')}
                            type="password"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs="12">
                        <MuiTextField
                            id="newPasswordRepeat"
                            name="newPasswordRepeat"
                            label={translate('pos.temporary_password_repeat')}
                            type="password"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            type='submit'
                            fullWidth
                            disabled={loading}
                        >{translate('pos.temporary_password_button_caption')}</Button>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
};