import React from 'react';
import ReactDOM from 'react-dom';
import './fonts.css';
import './index.css';
import App from './App';
import { EntityAccessContextContainer } from 'contexts/EntityAccessContextContainer.js';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { store } from './redux/store';

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <EntityAccessContextContainer>
        <Provider store={store}>
          <App />
        </Provider>
      </EntityAccessContextContainer>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
