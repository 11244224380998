import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useLogout, useTranslate } from 'react-admin';
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
// import { getCurrentUser } from 'providers/currentUser';
import {
    AccountCircle as AccountCircleIcon,
    // PermIdentity as PermIdentityIcon,
    PowerSettingsNew as PowerSettingsNewIcon
} from '@mui/icons-material';
import { useTourContext } from 'contexts/TourContext';

const CustomUserMenu = () => {
    // const navigate = useNavigate();
    const translate = useTranslate();
    const logout = useLogout();
    // const userName = getCurrentUser().userName || 'Unknown';

    // User Menu
    const { buttonUserId, menuUserId, openUserMenu, setOpenUserMenu } = useTourContext();
    const [anchor, _setAnchor] = useState(null);
    const setAnchor = (_anchor) => {
        _setAnchor(_anchor);
        setOpenUserMenu(Boolean(_anchor));
    }
    const closeDropdown = () => setAnchor(null);

    return (
        <Box sx={{ position: 'relative' }}>
            <IconButton
                id={buttonUserId}
                aria-controls={openUserMenu ? 'menu-dropdown' : undefined}
                aria-haspopup="true"
                aria-expanded={openUserMenu ? "true" : undefined}
                onClick={(e) => setAnchor(e.currentTarget)}
            >
                <AccountCircleIcon color="secondary" />
            </IconButton>
            {openUserMenu && <Box id={menuUserId} sx={{ position: 'absolute', top: 40, right: 0, width: 140, height: 90 }} />}
            <Menu
                id='menu-dropdown'
                anchorEl={anchor}
                open={openUserMenu}
                onClose={closeDropdown}
                MenuListProps={{ "aria-labelledby": buttonUserId }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 23,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                {/*<MenuItem*/}
                {/*    onClick={() => {*/}
                {/*        closeDropdown();*/}
                {/*        navigate('/profile');*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <ListItemIcon><PermIdentityIcon /></ListItemIcon>*/}
                {/*    <ListItemText>{translate('pos.profile')}</ListItemText>*/}
                {/*</MenuItem>*/}
                <MenuItem
                    onClick={() => logout(null, '/login', false)}
                >
                    <ListItemIcon><PowerSettingsNewIcon /></ListItemIcon>
                    <ListItemText>{translate('ra.auth.logout')}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default CustomUserMenu;
