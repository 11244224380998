import React from "react";
import MuiPagination from "@mui/material/Pagination";
import { GridPagination } from "@mui/x-data-grid-pro";

export const rowsPerPageOptions = [10, 25, 50, 75, 100];
export const defaultPageSize = rowsPerPageOptions[1];

const Pagination = ({ page, onPageChange, className, rowsPerPage, count }) => {
  // const apiRef = useGridApiContext();
  // const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageCount = Math.ceil(count / rowsPerPage);
  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount || 1}
      page={page + 1}
      onChange={(event, newPage) => onPageChange(event, newPage - 1)}
      variant="outlined"
      shape="rounded"
    />
  );
};

const CustomPagination = (props) => {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
};

export default CustomPagination;
