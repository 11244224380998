import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

const MuiTextField = (props: TextFieldProps) => {
    const { sx, ...rest } = props;
    return (
        <TextField
            margin="dense"
            size="small"
            variant="outlined"
            sx={sx}
            {...rest}
        />
    )
}

export default MuiTextField;
