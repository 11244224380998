import React, { useRef } from "react";
import Chart from "react-apexcharts";
import { useLocaleState } from "react-admin";
import moment from "moment";
import "moment/locale/de";

type Type = "line"
| "area"
| "bar"
| "histogram"
| "pie"
| "donut"
| "radialBar"
| "scatter"
| "bubble"
| "heatmap"
| "treemap"
| "boxPlot"
| "candlestick"
| "radar"
| "polarArea"
| "rangeBar";

type ApexAxisChartSeries = {
    name?: string,
    type?: string,
    color?: string,
    data:
      | (number | null)[]
      | {
          x: any;
          y: any;
          fill?: ApexFill;
          fillColor?: string;
          strokeColor?: string;
          meta?: any;
          goals?: any;
          barHeightOffset?: number;
          columnWidthOffset?: number;
        }[]
      | [number, number | null][]
      | [number, (number | null)[]][]
      | number[][];
}[];

interface Props {
    id?: string;
    type?: Type;
    dateFormat?: string;
    stacked?: boolean;
    colors?: string[];
    series: ApexAxisChartSeries; // Use ApexNonAxisChartSeries (number[]) for Pie and Donut charts
}

const ApexChart = ({
    id = "apex-chart",
    type = "line",
    stacked = false,
    dateFormat = "DD.MM.yyyy",
    colors,
    series = [],
}: Props) => {
    const [locale = "de"] = useLocaleState();
    const chartRef = useRef();

    // useEffect(() => {
    //   chartRef.current.chart.setLocale(locale);
    // }, [locale]);

    return (
        <Chart
            ref={chartRef}
            width="100%"
            height={400}
            type={type}
            series={series}
            options={{
                chart: {
                    id,
                    stacked,
                    stackType: stacked ? "100%" : "",
                    zoom: {
                        enabled: false,
                    },
                    pan: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                colors,
                stroke: {
                    curve: "smooth",
                    width: 2,
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: "category",
                    labels: {
                        formatter: (value, opts) => {
                            moment.locale(locale);
                            if (moment(value).isValid()) {
                                return moment(value).format(dateFormat);
                            } else {
                                return value;
                            }
                        },
                    },
                    tickAmount: "dataPoints",
                },
                yaxis: {
                    labels: {
                        formatter: (value, opts) => value,
                    },
                },
            }}
        />
    );
};

export default ApexChart;
