import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Box } from '@mui/material';
import * as mIcons from "@mui/icons-material";
import { DashboardMenuItem, Link, MenuItemLink, useLocaleState, useSidebarState } from 'react-admin';
import SubMenu from './SubMenu';
import { useResponsiveContext } from 'contexts/ResponsiveContextContainer';
import { staticMenuItems } from './menu-items';
import { useEntityAccess } from 'contexts/EntityAccessContextContainer.js';

const MenuItems = ({ dense }) => {
    const { pathname } = useLocation();
    const [locale] = useLocaleState();
    const [sidebarIsOpen] = useSidebarState();
    const { selectedThemeKey } = useResponsiveContext();
    const { bb2Config: { apiUrl, brickfoxBridge }, menuItems, getNameAndWindowTitle, findMenuItem } = useEntityAccess();

    const token = localStorage.getItem("token");
    const iframeTokenUrl = useMemo(() => {
        if (!apiUrl || !token) return "";
        return apiUrl + "/BFpublic/Index/index/userToken/" + token;
    }, [apiUrl, token]);

    const [subMenuToggleState, setSubMenuToggleState] = useState({});
    const handleToggle = (menuName) => {
        setSubMenuToggleState(state => ({ ...state, [menuName]: !state[menuName] }));
    }
    useEffect(() => {
      // auto expand specific parents based on pathname
      const _subMenuToggleState = { ...subMenuToggleState };
      const tokens = pathname.split("/");
      let item = findMenuItem(menuItems, "/" + tokens[1], null);
      while (item && item.parent?.id) {
        const pid = item.parent.id;
        _subMenuToggleState[pid] = true;
        item = findMenuItem(menuItems, null, pid);
      }
      // set toggle states
      setSubMenuToggleState(_subMenuToggleState);
      // eslint-disable-next-line
    }, [menuItems, pathname]);
  

    const renderLinkedMenu = useCallback(
        (id, name, url, target = "_self", IconComponent, level = 0) => {
            let to = url;
            if (target === "_iframe" && !to.startsWith("/")) {
                to = "/" + encodeURIComponent(url);
            }

            if (name === 'Inhalt generierung' && JSON.parse(localStorage.getItem("bb2Config"))?.contentGeneration !== "1") {
                return;
            }

            return (
                <MenuItemLink
                    key={id}
                    style={{ paddingLeft: 56 + 16 * (level - 1) }}
                    primaryText={name || "unknown"}
                    to={to}
                    target={target === "_iframe" ? "_self" : target}
                    // leftIcon={IconComponent && <IconComponent />}
                    state={{ _scrollToTop: true }}
                    dense={dense}
                />
            );
        // eslint-disable-next-line
    }, []);
    const renderMenuItem = useCallback(
        (menuItem, level = 0, noNeedTranslation = false) => {
            const {
                id,
                icon,
                url: _url,
                target,
                menuUrl,
                children = [],
                ChildMenuComponent,
                // descriptions = [],
                // parent,
                // type,
                // accessGroups,
            } = menuItem;
            const url = menuUrl?.url || _url;
            const IconComponent = icon ? mIcons[icon.replace("Icon", "")] : "";
            const { name } = getNameAndWindowTitle(menuItem, locale);
            if (!ChildMenuComponent && children.length === 0 && url) {
                return renderLinkedMenu(id, name, url, menuUrl?.target || target, IconComponent, level);
            }
            return (
                <SubMenu
                    key={id}
                    name={name}
                    noNeedTranslation={noNeedTranslation}
                    isOpen={subMenuToggleState[id]}
                    handleToggle={() => handleToggle(id)}
                    level={level}
                    icon={IconComponent && <IconComponent />}
                    dense={dense}
                >
                    {ChildMenuComponent ? (
                        <ChildMenuComponent dense={dense} />
                    ) : (
                        children.map((m) => renderMenuItem(m, level + 1, noNeedTranslation))
                    )}
                </SubMenu>
            );
        // eslint-disable-next-line
      }, [subMenuToggleState, locale]);

    return (
        <Box name="MENU-ITEMS" sx={{ paddingTop: 1, paddingBottom: 3 }}>
            {/* Logo */}
            <Box sx={{ py: 2, pl: sidebarIsOpen ? undefined : 2, textAlign: sidebarIsOpen ? 'center' : undefined }}>
                <Link to="/">
                    {selectedThemeKey === "GREEN" ?
                        <img src='/logo-orange-bf.jpg' alt="logo" height={sidebarIsOpen ? 120 : 20} />
                        :
                        <img src='/logo-blue-pixi.png' alt="logo" height={38} />
                    }
                </Link>
            </Box>
            <DashboardMenuItem />
            {brickfoxBridge && staticMenuItems.map((m) => renderMenuItem(m, 0))}
            {menuItems.map((m) => renderMenuItem(m, 0, true))}
            <iframe src={iframeTokenUrl} title="iframe-token" style={{ height: 1, opacity: 0 }} />
        </Box>
    )
}

export default MenuItems;
