import React from 'react';
import { LayoutProps } from 'react-admin';
import { RelatedDataContextContainer } from 'contexts/RelatedDataContext';
import AppLayout from './AppLayout';
import { DirtyContextContainer } from 'contexts/DirtyContext';
import { TourContextContainer } from 'contexts/TourContext';
import { UserViewsContextContainer } from 'contexts/UserViewsContext';
import ResponsiveContextContainer from 'contexts/ResponsiveContextContainer';
import { BB2MandantContextContainer } from 'pages/bb2/BB2MandantContext';

const Layout2 = (props: LayoutProps) => {
    return (
        <RelatedDataContextContainer>
            <DirtyContextContainer>
                <TourContextContainer>
                    <UserViewsContextContainer>
                        <ResponsiveContextContainer>
                            <BB2MandantContextContainer>
                                <AppLayout {...props} />
                            </BB2MandantContextContainer>
                        </ResponsiveContextContainer>
                    </UserViewsContextContainer>
                </TourContextContainer>
            </DirtyContextContainer>
        </RelatedDataContextContainer>
    )
}

export default Layout2;
