import React, {useEffect, useMemo, useState} from 'react';
import { Route } from 'react-router';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import useLocalStorageState from 'use-local-storage-state';

import bfDataProvider from 'providers/data/bfDataProvider';
import authProvider from 'providers/auth/authProvider';
// import { brickfoxTheme } from 'theme/brickfoxTheme';
import { getTheme } from 'theme/theme2';
import defaultMessages from 'i18n/de';
// import MainLayout from 'layout';
import Layout2 from 'layout2';

import Dashboard from 'pages/dashboards';
import Login from 'pages/login';
import Profile from 'pages/profile';
import ForgotPassword from 'pages/login/ForgotPassword';
// import Configuration from 'pages/configuration';
// import { CountriesList } from 'pages/countries';
// import { LanguagesList } from 'pages/languages';
// import { SuppliersList } from 'pages/suppliers';
// import { TaxRatesList } from 'pages/taxRates';
// import { StockLocationsList } from 'pages/stockLocations';
// import { CurrenciesList } from 'pages/currencies';
// import { CarriersList } from 'pages/carriers';
// import { UnitsList } from 'pages/units';
// import { CouponsReduceTypesList } from 'pages/coupons-reduce-types';
// import { MediaCategoriesList } from 'pages/media-categories';
// import { DeliveryTimesList } from 'pages/delivery-times';
// import { ShippingMethodsList } from 'pages/shipping-methods';
// import { PaymentMethodsList } from 'pages/payment-methods';
// import { ProductsClassificationList } from 'pages/products-classification';
// import { ProductsConditionsList } from 'pages/products-conditions';
// import { BfUsersList } from 'pages/bf-users';
// import { BfGroupsList } from 'pages/bf-groups';
// import { ProductsByGroup } from 'pages/products-by-group';
// import ProductDetail from 'pages/products-by-group/ProductDetail';
// import BfUserGridConfigs from 'pages/bf-users-views';
import { BB2Billing, BB2Login, BB2Logs, BB2Overview, BB2Workflow } from 'pages/bb2';
import { useEntityAccess } from 'contexts/EntityAccessContextContainer.js';
import EntityAccessFields from 'pages/entity-access-fields';
import IFramePage from 'components/IframePage';
import {BB2PassReset} from "./pages/bb2/bb2-pass-reset";
import { ContentGeneration } from "./pages/content-generation";

const i18nProvider = polyglotI18nProvider(langCode => {
    if (langCode && langCode !== 'de') {
        return import(`i18n/${langCode}`).then(messages => messages.default);
    }
    return defaultMessages;
}, 'de');

const App = () => {
    const [selectedThemeKey] = useLocalStorageState("themeKey", { ssr: false, defaultValue: "GREEN" });
    const theme = useMemo(() => getTheme(selectedThemeKey), [selectedThemeKey]);

    const { bb2Config: { apiUrl }, menuItems, findIframeMenuItems } = useEntityAccess();
    const [needReload, setNeedReload] = useState(false);
    useEffect(() => {
        if (!apiUrl) {
            setNeedReload(true);
        } else if (needReload) {
            setNeedReload(false);
            window.location.reload();
        }
    }, [apiUrl, needReload]);

    const iframeItems = useMemo(() => {
        const results = [];
        findIframeMenuItems(menuItems, results);
        return results.map((item) => ({
            url: "/" + encodeURIComponent(item.menuUrl?.url || item.url),
            iframeUrl: item.menuUrl?.url || item.url,
            id: item.id,
        }));
        // eslint-disable-next-line
    }, [menuItems]);

    return (
        <Admin
            title="Brickfox Admin"
            theme={theme}
            i18nProvider={i18nProvider}
            authProvider={authProvider}
            dataProvider={bfDataProvider}
            loginPage={Login}
            layout={Layout2}
        >
            <CustomRoutes noLayout>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/check-permissions" element={<EntityAccessFields />} />
                <Route path="/require-new-password" element={<BB2PassReset />} />
            </CustomRoutes>
            <CustomRoutes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/content-genration" element={<ContentGeneration />} />
                {/* <Route path="/configuration" element={<Configuration />} />
                <Route path="/user-grid-configurations" element={<BfUserGridConfigs />} />
                <Route path="/countries" element={<CountriesList />} />
                <Route path="/languages" element={<LanguagesList />} />
                <Route path="/currencies" element={<CurrenciesList />} />

                <Route path="/units" element={<UnitsList />} />
                <Route path="/groups/:groupId/products" element={<ProductsByGroup />} />
                <Route path="/groups/:groupId/products/:productId" element={<ProductDetail />} /> */}
                {iframeItems.map(({ url, iframeUrl, id }) => (
                    <Route
                        key={url}
                        path={url}
                        element={<IFramePage url={url} iframeUrl={iframeUrl} id={id} />}
                    />
                ))}
                {/* <Route path="/orders" element={<IframePage domain="https://demo.brickfox.net" path="/BFpublic/Orders/index" titleTranslateKey='pos.iframe.orders' />} />
                <Route path="/helpdesk" element={<IframePage domain="https://ipn.brickfox.net" path="/bf_downloads/brickfox_downloads.html" titleTranslateKey='pos.iframe.helpdesk' />} />
                <Route path="/reports/channel-performance" element={<IframePage domain="https://demo.brickfox.net" path="/BFpublic/Controlling/Shopsperformance" titleTranslateKey='pos.iframe.reports.menus.channelPerformance' />} />
                <Route path="/reports/product-groups" element={<IframePage domain="https://demo.brickfox.net" path="/BFpublic/Controlling/groups" titleTranslateKey='pos.iframe.reports.menus.productGroups' />} />
                <Route path="/reports/assortment" element={<IframePage domain="https://demo.brickfox.net" path="/BFpublic/Controlling/Racersbums" titleTranslateKey='pos.iframe.reports.menus.assortment' />} />
                <Route path="/reports/payment-methods" element={<IframePage domain="https://demo.brickfox.net" path="/BFpublic/Controlling/payments" titleTranslateKey='pos.iframe.reports.menus.paymentMethods' />} /> */}
                {/* BB2 (brickfox bridge 2) */}
                <Route path="/bb2/login" element={<BB2Login />} />
                <Route path="/bb2/renew-temp-pass" element={<BB2PassReset />} />
                <Route path="/bb2/overview" element={<BB2Overview />} />
                <Route path="/bb2/workflow" element={<BB2Workflow />} />
                <Route path="/bb2/logs" element={<BB2Logs />} />
                <Route path="/bb2/billing" element={<BB2Billing />} />
            </CustomRoutes>
            <Resource name="content-generation" list={ContentGeneration} />
            {/* <Resource name="suppliers" list={SuppliersList} />
            <Resource name="suppliers" list={SuppliersList} />
            <Resource name="tax-rates" list={TaxRatesList} />
            <Resource name="stock-locations" list={StockLocationsList} />
            <Resource name="carriers" list={CarriersList} />
            <Resource name="coupons-reduce-types" list={CouponsReduceTypesList} />
            <Resource name="media-categories" list={MediaCategoriesList} />
            <Resource name="delivery-times" list={DeliveryTimesList} />
            <Resource name="shipping-methods" list={ShippingMethodsList} />
            <Resource name="payment-methods" list={PaymentMethodsList} />
            <Resource name="products-classification" list={ProductsClassificationList} />
            <Resource name="products-conditions" list={ProductsConditionsList} />
            <Resource name="bf-users" list={BfUsersList} />
            <Resource name="bf-groups" list={BfGroupsList} /> */}
        </Admin>
    );
}

export default App;
