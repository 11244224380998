import React, { useMemo } from 'react';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { HelpOutline } from '@mui/icons-material';
import { theme_data } from 'theme/theme2';
import CircularProgressAbsolute from 'components/CircularProgressAbsolute';

const StatisticCard = ({
  title = "Title",
  tooltip = "",
  number = 0,
  renderNumber,
  icon,
  footer = { percent: undefined, desc: "" },
  loading = false,
}) => {
  const translate = useTranslate();
  const numerText = useMemo(() => {
    if (!renderNumber) return number.toLocaleString();
    return renderNumber(number);
  }, [number, renderNumber]);
  return (
    <Card sx={{ position: "relative" }}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography variant="button" sx={{ mr: 1, fontSize: 12, color: theme_data.formLabelColor }}>
                {translate(title)}
              </Typography>
              {tooltip && (
                <Tooltip title={translate(tooltip)}>
                  <HelpOutline />
                </Tooltip>
              )}
            </Box>
            {typeof number !== "undefined" && (
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>{numerText}</Typography>
            )}
          </Box>
          <Box sx={{ px: 1.5, py: 1.1, backgroundColor: "#4a4a4a", border: "1px solid #4a4a4a", borderRadius: "48px" }}>{icon}</Box>
        </Box>
        <Box sx={{ display: "flex", mt: 2 }}>
          {typeof footer.percent !== "undefined" && (
            <Typography
              sx={{
                color: footer.percent < 0 ? theme_data.error : footer.percent > 0 ? theme_data.success : "inherit",
                mr: 1,
                fontSize: 13,
              }}
            >
              {(footer.percent > 0 ? "+" : "") + (footer.percent !== 0 ? footer.percent.toFixed(2) : 0)}%
            </Typography>
          )}
          <Typography sx={{ fontSize: 13 }}>{translate(footer.desc)}</Typography>
        </Box>
      </CardContent>
      <CircularProgressAbsolute active={loading} />
    </Card>
  );
};

export default StatisticCard;
