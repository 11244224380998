import React, { useEffect, useState } from "react";
import { Title, useNotify, useSidebarState, useTranslate } from "react-admin";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { bb2api } from "../bb2-api";
import { useBB2MandantContext } from "../BB2MandantContext";
import moment from "moment";
import { calcPercent } from "../calc-percent";
import { Box, Button, FormControlLabel, Grid, Paper, Switch, Typography } from "@mui/material";
import StatisticCard from "components/StatisticCard";
import { PriorityHigh, Warning } from "@mui/icons-material";
import queryString from "query-string";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { makeStyles } from "@mui/styles";
import MuiSearch from "components/MuiSearch";
import CustomPagination from "components/MuiGrid/CustomPagination";
import { CLOSED_DRAWER_WIDTH, DRAWER_WIDTH } from "theme/theme2";
import MandantPicker from "../MandantPicker";

const useStyles = makeStyles(() => ({
  cell: {
    maxHeight: "none !important",
  },
  row: {
    maxHeight: "none !important",
  },
}));

const BB2Logs = () => {
  // Check authentication
  const navigate = useNavigate();
  const { isAuthed, mandant, getMandantTokens } = useBB2MandantContext();
  useEffect(() => {
    if (isAuthed) return;
    navigate("/bb2/login", { state: { target: "logs" } });
  });

  const notify = useNotify();
  const [sidebarIsOpen] = useSidebarState();
  const translate = useTranslate();
  const classes = useStyles();
  const { search } = useLocation();
  const { page = 1, searchTerm = "" } = queryString.parse(search);

  /**
   * ERROR / WARNING Statistics
   */
  const fetchMonthlyReport = async (type, mom) => {
    const from = mom.clone().startOf("month").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
    const to = mom.clone().add(1, "month").startOf("month").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
    const { data, error } = await bb2api.getReporting(type, from, to, getMandantTokens().reportingToken);
    if (error) {
      notify(error.message, { type: "error" });
      return 0;
    }
    return data.totalItems;
  };
  const { data: errorsData, isLoading: isErrorsLoading } = useQuery(["message-reporting-error-3-months", mandant], async () => {
    if (!mandant) return null;
    const startMom = moment();
    const [current, last] = await Promise.all([
      fetchMonthlyReport("ERROR", startMom),
      fetchMonthlyReport("ERROR", startMom.clone().subtract(1, "month")),
    ]);
    const numErrors = current;
    const percent = calcPercent(current, last);
    return { numErrors, percent };
  });
  const { data: warningsData, isLoading: isWarningsLoading } = useQuery(["message-reporting-warning-3-months", mandant], async () => {
    if (!mandant) return null;
    const startMom = moment();
    const [current, last] = await Promise.all([
      fetchMonthlyReport("WARNING", startMom),
      fetchMonthlyReport("WARNING", startMom.clone().subtract(1, "month")),
    ]);
    const numWarnings = current;
    const percent = calcPercent(current, last);
    return { numWarnings, percent };
  });

  /**
   * Logs
   */
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [pretties, setPretties] = useState({});
  const { data: logs, isLoading: isLogsLoading } = useQuery(["get-logs", mandant, page, searchTerm], async () => {
    if (!mandant) return null;
    const { data, error } = await bb2api.getLogs(page, 10, searchTerm, getMandantTokens().reportingToken);
    if (error) {
      notify(error.message, { type: "error" });
      return null;
    }
    return data;
  }, {
    refetchInterval: autoRefresh ? 5000 : false,
  });
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    setTotalCount(0);
  }, [mandant]);
  useEffect(() => {
    if (!logs?.totalItems) return;
    if (logs?.totalItems === totalCount) return;
    setTotalCount(logs?.totalItems);
  }, [logs, totalCount]);

  useEffect(() => {
    setPretties({});
  }, [page, searchTerm]);

  return (
    <>
      <MandantPicker sx={{ mb: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Title title={"bb2.pages.logs"} />
          <Grid item md={4} xs={12}>
            <StatisticCard
              title="bb2.fields.total_errors"
              tooltip="bb2.fields.total_errors_tooltip"
              number={errorsData?.numErrors}
              icon={<PriorityHigh fontSize="large" sx={{ color: "#fff" }} />}
              footer={{
                percent: errorsData?.percent,
                desc: "bb2.fields.since_last_month",
              }}
              loading={isErrorsLoading}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <StatisticCard
              title="bb2.fields.total_warnings"
              tooltip="bb2.fields.total_warnings_tooltip"
              number={warningsData?.numWarnings}
              icon={<Warning fontSize="large" sx={{ color: "#fff" }} />}
              footer={{
                percent: warningsData?.percent,
                desc: "bb2.fields.since_last_month",
              }}
              loading={isWarningsLoading}
            />
          </Grid>
        </Grid>
        <Paper sx={{ mt: 2, p: 1, pt: 0, display: "flex", flexDirection: "column", maxWidth: `calc(100vw - ${sidebarIsOpen ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH}px - 32px)`, flex: 1 }}>
          <Box sx={{ display: "flex" }}>
            <MuiSearch
              placeholder={translate("pos.search")}
              onChange={(term) => navigate(`?page=1&searchTerm=${term}`)}
              iconPosition="left"
              sx={{ backgroundColor: "#fff", mr: 2 }}
            />
            <FormControlLabel
              control={<Switch checked={autoRefresh} onChange={(e) => setAutoRefresh(e.target.checked)} />}
              label={<Typography sx={{ whiteSpace: "nowrap" }}>{translate("bb2.fields.auto_refresh")}</Typography>}
            />
          </Box>
          <DataGridPro
            columns={[
              { field: "service", headerName: translate("bb2.fields.service"), minWidth: 300, sortable: false, filterable: false },
              { field: "title", headerName: translate("bb2.fields.title"), minWidth: 400, sortable: false, filterable: false, flex: 1, renderCell: ({ value, id }) => (
                <Box sx={{ padding: 2 }}>
                  <Typography sx={{ whiteSpace: "normal", wordBreak: "break-all" }}>{value}</Typography>
                  {value.indexOf("{") >= 0 && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        const _pretties = { ...pretties };
                        if (_pretties[id]) delete _pretties[id];
                        else _pretties[id] = true;
                        setPretties(_pretties);
                      }}
                      sx={{ my: 1 }}
                    >
                      {translate("bb2.fields.pretty_print")}
                    </Button>
                  )}
                  {pretties[id] && (
                    <div dangerouslySetInnerHTML={{ __html: JSON.stringify(JSON.parse(value.substring(value.indexOf("{"))), null, 4) }} style={{ whiteSpace: "break-spaces" }} >
                    </div>
                  )}
                </Box>
              ) },
              { field: "type", headerName: translate("bb2.fields.type"), minWidth: 100, sortable: false, filterable: false },
              { field: "creationDate", headerName: translate("bb2.fields.date"), minWidth: 250, sortable: false, filterable: false, renderCell: ({ value }) => moment.utc(value.date).local().format("MM.DD.yyyy HH:mm:ss") },
            ]}
            rows={logs?.data || []}
            classes={{ cell: classes.cell, row: classes.row }}
            disableSelectionOnClick
            loading={isLogsLoading}
            // pagination
            rowCount={totalCount}
            pagination
            paginationMode="server"
            pageSizeOptions={[10]}
            paginationModel={{
              page: page - 1,
              pageSize: 10,
            }}
            onPaginationModelChange={({ page, pageSize }) => {
              navigate(`?page=${page + 1}&searchTerm=${searchTerm}`);
            }}
            slots={{
              pagination: CustomPagination,
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default BB2Logs;
