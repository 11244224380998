import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {Title, useTranslate} from 'react-admin';
import {commonStyles} from 'theme/theme2';
import axios from "axios";
import {useEntityAccess} from "../../contexts/EntityAccessContextContainer.js";
import ClientChannelSalesLineChart from "../../components/Recharts/ClientChannelSalesLineChart";

const bb2ForecastingUrl = process.env.REACT_APP_BB2_FORECASTING_URL;

const Dashboard = () => {
    const translate = useTranslate();
    const {bb2Config} = useEntityAccess();
    let accessToken;

    if (bb2Config.aiForecastingServiceToken) {
        accessToken = bb2Config.aiForecastingServiceToken;
    }

    const [channelSalesDataValue, setChannelSalesDataValue] = useState({
        channelSalesData: []
    });

    const [channelsValue, setChannelsValue] = useState({
        channels: [
            {
                index: 'eBay',
                label: 'eBay',
                hidden: false
            }
        ]
    });

    const [forecastDateValue, setForecastDateValue] = useState({
        forecastDate: "01.2023"
    });

    const [clientBranchValue, setClientBranch] = useState({
        branch: ""
    });

    useEffect(() => {
        if (channelSalesDataValue.channelSalesData.length === 0 && accessToken) {
            loadChannelForecasting();
        }
    });

    const loadChannelForecasting = () => {
        axios.get(bb2ForecastingUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            responseType: 'json'
        })
            .then(response => {
                setChannelSalesDataValue({channelSalesData: response.data.data});
                setForecastDateValue({forecastDate: response.data.forecastDate});
                let channels = [];
                response.data.channels.forEach(channel => {
                    channels.push({index: channel, label: channel, hidden: false});
                })
                setChannelsValue({channels: channels});
                setClientBranch({branch: response.data.branch});
            });
    };

    return (
        <>
            <Title title={translate('pos.dashboard.name')}/>
            <Box sx={{...commonStyles.content_root}}>
                <Typography variant="h5" component="h2" gutterBottom>
                    {translate('pos.dashboard.welcome.title')}
                </Typography>

                <Box sx={{display: 'flex', flexWrap: 'wrap', marginTop: 3}}>
                    <div>
                        <p style={{fontSize: 18, lineHeight: 0}}>Channel Sales Daten</p>
                        <p style={{fontSize: 12, color: 'rgba(0,0,0,.5)'}}>Inkl. Branchen Forecast</p>
                    </div>

                    <Box sx={{minWidth: '100%', minHeight: 500, mt: 3}}>
                        <ClientChannelSalesLineChart
                            data={channelSalesDataValue.channelSalesData}
                            xAxisDataKey='date'
                            forecastBranch={clientBranchValue.branch}
                            forecastDate={forecastDateValue.forecastDate}
                            yAxisDataKeys={channelsValue.channels}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Dashboard;