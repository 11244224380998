import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Title, useNotify } from 'react-admin';
import StatisticCard from 'components/StatisticCard';
import {
  PriorityHigh,
  TrafficRounded,
  Warning,
} from '@mui/icons-material';
import TrafficLineChart from './TrafficLineChart';
import ErrorBarChart from './ErrorBarChart';
import WarningBarChart from './WarningBarChart';
import { bb2api } from '../bb2-api';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useBB2MandantContext } from '../BB2MandantContext';
import { calcPercent } from '../calc-percent';
import MandantPicker from '../MandantPicker';

const BB2Overview = () => {
  // Check authentication
  const navigate = useNavigate();
  const { isAuthed, mandant, getMandantTokens } = useBB2MandantContext();
  useEffect(() => {
    if (isAuthed) return;
    navigate('/bb2/login', { state: { target: "overview" } });
    // eslint-disable-next-line
  }, [isAuthed]);

  const notify = useNotify();

  /**
   * Traffic Statistics
   */
  const { data: traffics, isLoading: isTrafficLoading } = useQuery(["usage-workflow-2-months", mandant], async () => {
    if (!mandant) return null;
    const fetchMonthly = async (mom) => {
      const from = mom.clone().startOf('month').format('yyyy-MM-DDTHH:mm:ss') + ".000Z";
      const to = mom.clone().add(1, 'month').startOf('month').format('yyyy-MM-DDTHH:mm:ss') + ".000Z";
      const { data, error } = await bb2api.getUsageWorkflow(from, to, getMandantTokens().billingToken);
      if (error) {
        notify(error.message, { type: 'error' });
        return 0;
      }
      let sum = 0;
      data.forEach(({ name, data }) => {
        data.forEach(({ total_usage_per_workflow }) => {
          sum += Number(total_usage_per_workflow);
        });
      });
      return sum;
    };
    const startMom = moment();
    const [currentMonthTraffic, prevMonthTraffic] = await Promise.all([
      fetchMonthly(startMom),
      fetchMonthly(startMom.subtract(1, 'month')),
    ]);
    const percent = calcPercent(currentMonthTraffic, prevMonthTraffic);
    return { currentMonthTraffic, percent };
  });

  /**
   * ERROR / WARNING Statistics
   */
  const fetchMonthlyReport = async (type, mom) => {
    const from = mom.clone().startOf("month").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
    const to = mom.clone().add(1, "month").startOf("month").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
    const { data, error } = await bb2api.getReporting(type, from, to, getMandantTokens().reportingToken);
    if (error) {
      notify(error.message, { type: "error" });
      return 0;
    }
    return data.totalItems;
  };
  const { data: errorsData, isLoading: isErrorsLoading } = useQuery(["message-reporting-error-3-months", mandant], async () => {
    if (!mandant) return null;
    const startMom = moment();
    const [current, last, last2] = await Promise.all([
      fetchMonthlyReport("ERROR", startMom),
      fetchMonthlyReport("ERROR", startMom.clone().subtract(1, "month")),
      fetchMonthlyReport("ERROR", startMom.clone().subtract(2, "month")),
    ]);
    const numErrors = current;
    const series = [
      { x: startMom.clone().subtract(2, "month").format("yyyy-MM-DD"), y: last2 },
      { x: startMom.clone().subtract(1, "month").format("yyyy-MM-DD"), y: last },
      { x: startMom.format("yyyy-MM-DD"), y: current },
    ];
    const percent = calcPercent(current, last);
    return { numErrors, percent, series };
  });
  const { data: warningsData, isLoading: isWarningsLoading } = useQuery(["message-reporting-warning-3-months", mandant], async () => {
    if (!mandant) return null;
    const startMom = moment();
    const [current, last, last2] = await Promise.all([
      fetchMonthlyReport("WARNING", startMom),
      fetchMonthlyReport("WARNING", startMom.clone().subtract(1, "month")),
      fetchMonthlyReport("WARNING", startMom.clone().subtract(2, "month")),
    ]);
    const numWarnings = current;
    const series = [
      { x: startMom.clone().subtract(2, "month").format("yyyy-MM-DD"), y: last2 },
      { x: startMom.clone().subtract(1, "month").format("yyyy-MM-DD"), y: last },
      { x: startMom.format("yyyy-MM-DD"), y: current },
    ];
    const percent = calcPercent(current, last);
    return { numWarnings, percent, series };
  });

  if (!mandant) return null;

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Title title={'bb2.pages.overview'} />
      <MandantPicker sx={{ ml: 2, mt: 2 }} />
      <Grid item md={4} xs={12}>
        <StatisticCard
          title='bb2.fields.total_traffic'
          tooltip='bb2.fields.total_traffic_tooltip'
          number={traffics?.currentMonthTraffic}
          icon={<TrafficRounded fontSize="large" sx={{ color: "#fff" }} />}
          footer={{
            percent: traffics?.percent,
            desc: 'bb2.fields.since_last_month',
          }}
          loading={isTrafficLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <StatisticCard
          title='bb2.fields.total_errors'
          tooltip='bb2.fields.total_errors_tooltip'
          number={errorsData?.numErrors}
          icon={<PriorityHigh fontSize="large" sx={{ color: "#fff" }} />}
          footer={{
            percent: errorsData?.percent,
            desc: 'bb2.fields.since_last_month',
          }}
          loading={isErrorsLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <StatisticCard
          title='bb2.fields.total_warnings'
          tooltip='bb2.fields.total_warnings_tooltip'
          number={warningsData?.numWarnings}
          icon={<Warning fontSize="large" sx={{ color: "#fff" }} />}
          footer={{
            percent: warningsData?.percent,
            desc: 'bb2.fields.since_last_month',
          }}
          loading={isWarningsLoading}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TrafficLineChart />
      </Grid>
      <Grid item md={6} xs={12}>
        <ErrorBarChart series={errorsData?.series} loading={isErrorsLoading} />
      </Grid>
      <Grid item md={6} xs={12}>
        <WarningBarChart series={warningsData?.series} loading={isWarningsLoading} />
      </Grid>
    </Grid>
  );
};

export default BB2Overview;
