import React from 'react';
import { MenuProps, useSidebarState, useTranslate } from 'react-admin';
import { Box, Typography } from '@mui/material';
import MenuItems from './MenuItems';
import { CLOSED_DRAWER_WIDTH, COLORS, DRAWER_WIDTH } from 'theme/theme2';
import { useResponsiveContext } from 'contexts/ResponsiveContextContainer';

const Menu = ({ dense = false }: MenuProps) => {
    const translate = useTranslate();
    const [sidebarIsOpen] = useSidebarState();
    const { isPhone } = useResponsiveContext();

    return (
        <Box
            name="MENU-CONTAINER"
            sx={{
                width: sidebarIsOpen ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH,
                height: isPhone ? '100vh' : undefined,
                backgroundColor: (theme) => theme.sidebarBg,
                transition: (theme) => theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            }}
        >
            <Box
                name="MENU-CONTENTS"
                sx={{
                    height: `calc(100vh - ${sidebarIsOpen ? 52 : 0}px)`,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                <MenuItems dense={dense} />
            </Box>
            <Box
                name="FOOTER"
                sx={{ paddingY: 2, display: 'flex', justifyContent: 'center' }}
            >
                {sidebarIsOpen && <Typography color={COLORS.SECONDARY}>{translate('pos.footer.msg1')}</Typography>}
            </Box>
        </Box>
    )
}

export default Menu;
