import React from "react";
import { Box, CircularProgress } from "@mui/material";

const CircularProgressAbsolute = ({ active = false }) => {
  if (!active) return null;
  return (
    <Box sx={{ position: "absolute", zIndex: 2, left: 0, top: 0, bottom: 0, right: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress size={25} thickness={2} />
    </Box>
  );
};

export default CircularProgressAbsolute;
