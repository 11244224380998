export const toMuiIcon = (iconCls: string): string => {
  let muiIconName = "";
  const iconName = iconCls.trim();
  switch (iconName) {
    case "icon-book-open":
      muiIconName = "MenuBook";
      break;
    case "icon-arrow-divide":
      muiIconName = "CallSplit";
      break;
    case "icon-user-gray":
      muiIconName = "ShoppingBag";
      break;
    case "icon-lorry":
      muiIconName = "LocalShipping";
      break;
    case "icon-package":
      muiIconName = "Inventory2";
      break;
    case "icon-chart-line":
      muiIconName = "Insights";
      break;
    case "icon-bricks":
      muiIconName = "Settings";
      break;
    case "icon-wrench-orange":
      muiIconName = "Tune";
      break;
    case "icon-house":
      muiIconName = "House";
      break;
    case "icon-chart-organisation":
      muiIconName = "Addchart";
      break;
    case "empty-icon":
      muiIconName = "";
      break;
    default:
      muiIconName = "";
      break;
  }
  if (muiIconName) muiIconName += "Icon";
  return muiIconName;
};
