import { useMemo } from "react";
import { Title } from "react-admin";
import { Box, styled } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useLocaleState } from "react-admin";
import { useEntityAccess } from "contexts/EntityAccessContextContainer.js";

const RaPageRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
}));

const RaMain = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  position: "relative",
}));

const RaContentRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  overflowX: "auto",
}));

const RaContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  flex: 1,
  width: "100%",
  marginBottom: theme.spacing(4),
}));

export const Page = ({ title, fullHeight, children }) => {
  const [locale] = useLocaleState();
  const { pathname } = useLocation();
  const { menuItems, findMenuItem, getNameAndWindowTitle } = useEntityAccess();
  const menuTitle = useMemo(() => {
    const menuItem = findMenuItem(menuItems, pathname);
    const { windowTitle } = getNameAndWindowTitle(menuItem, locale);
    document.title = windowTitle || title || "Intranet";
    return windowTitle || title;
    // eslint-disable-next-line
  }, [pathname, locale, title, menuItems]);

  return (
    <RaPageRoot id="RaPageRoot">
      <Title title={menuTitle} />
      <RaMain id="RaMain">
        {fullHeight ? (
          children
        ) : (
          <RaContentRoot id="RaContentRoot">
            <RaContent id="RaContent">{children}</RaContent>
          </RaContentRoot>
        )}
      </RaMain>
    </RaPageRoot>
  );
};
