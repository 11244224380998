import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { setCurrentUser } from 'providers/currentUser';
import { printError } from 'utils/errors';

export const isAuthenticated = () => {
    const token = localStorage.getItem("token");

    if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp && typeof decodedToken.exp === "number") {
            if (Date.now() < decodedToken.exp * 1000) {
                return true;
            }
        }
    }

    return false;
};

const authProvider = {
    login: ({ username, password, apiUrl }) => {
        let data = new FormData();
        data.append('userName', username);
        data.append('password', password);
        return axios.post(`${apiUrl.startsWith("http") ? "" : "https://"}${apiUrl}/BFpublic/Auth/token`, data, {
            headers: {
                'Content-Type': 'multipart/form-data', 
                'Accept': 'application/json', 
            },
        })
            .then(({ data }) => {
                const { token, user } = data.data;
                setCurrentUser(user);
                localStorage.setItem("token", token);
                return Promise.resolve();
            })
            .catch((err) => {
                printError(err);
                throw new Error(err.message);
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem("token");
        setCurrentUser({});
        return Promise.resolve("/login");
    },
    // called when the API returns an error
    checkError: (error) => {
        const status = error?.status || error?.response?.status;
        if (status === 401 || status === 403) {
          console.log("authProvider:checkError:", { status });
          localStorage.removeItem("token");
          return Promise.reject({ redirectTo: "/login" });
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        const loggedIn = isAuthenticated();
        if (!loggedIn) {
          console.log("authProvider:checkAuth:", { loggedIn });
        }
        return loggedIn
          ? Promise.resolve()
          : Promise.reject({ redirectTo: "/login" });
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        return Promise.resolve();
    },
};

export default authProvider;
