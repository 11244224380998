import React from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    return (
        <ResourceGrid
            customResource='coupons-reduce-types'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.coupons_reduce_types.fields.id'), maxWidth: 20, hide: true },
                { field: 'reduceCode', headerName: translate('resources.coupons_reduce_types.fields.reduceCode'), minWidth: 160, editable: true },
                { field: 'couponsReduceTypesDescription', headerName: translate('resources.coupons_reduce_types.fields.couponsReduceTypesDescription'), flex: 1, editable: true },
            ]}
        />
    )
}

export const CouponsReduceTypesList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.coupons_reduce_types.name')} />
            <Grid />
        </>
    )
}
