export const getPriceText = (num, extendStr = "") => {
  if (typeof num === "number") {
    if (!extendStr) {
      return num.toFixed(2).toLocaleString() + " €";
    } else {
      return num.toFixed(2).toLocaleString() + " € (" + extendStr + ")";
    }
  }
  return num;
};
