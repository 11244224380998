import React, {useState} from 'react';
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer, ReferenceLine, Label,
} from 'recharts';
import {Tooltip as MUTooltip} from '@mui/material';
import {COLOR_LIST_WITHOUT_GREY} from './ColorList';

const currencyFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
});

const dataKeyMapping = {
    "EBAY": "eBay",
    "OTTO": "Otto",
    "OTTO_MARKET": "Otto Market",
    "AMAZON": "Amazon",
    "HITMEISTER": "Hitmeister",
    "PLUS": "Plus",
    "KAUFLAND": "Kaufland",
};

const ToolTipContentDescription = ({pld}) => {
    const dataKey = pld.dataKey.replace(".sales", "");
    const formattedDataKey = dataKeyMapping[dataKey] ?? dataKey;
    const additionalInformation = pld.payload[dataKey];
    let additionalContentInformationPrefix = "";
    let additionalContentInformationSuffix = "";

    if (additionalInformation.forecastSales) {
        additionalContentInformationPrefix += `Forecast `;
        additionalContentInformationSuffix += ` (${additionalInformation.forecastTrend > 0 ? "+":""}${Math.round(additionalInformation.forecastTrend)}%)`;
    }

    return (
        <span style={{marginLeft: 14}}>{`${additionalContentInformationPrefix}${formattedDataKey}${additionalContentInformationSuffix} : ${currencyFormatter.format(pld.value)}`}</span>
    )
}

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{background: 'rgba(255, 255, 255, .5)'}}>
                <div style={{
                    background: '#FF9900',
                    color: 'white',
                    'font-weight': '600',
                    padding: 4
                }}>Datum: {label}</div>
                <div style={{padding: 4}}>
                    {payload.map((pld) => (
                        <p style={{fontSize: '14px'}}>
                            <div>
                                <span style={{
                                    color: pld.color,
                                    fontSize: 48,
                                    lineHeight: 0,
                                    position: 'absolute',
                                    marginTop: 5
                                }}>•</span>
                                <ToolTipContentDescription pld={pld}/>
                            </div>
                        </p>
                    ))}
                </div>
            </div>
        );
    }

    return null;
};

const MyLineChart = ({
                         data = [],
                         forecastDate,
                         forecastBranch,
                         xAxisDataKey,
                         yAxisDataKeys = [],
                     }) => {

    const [lineProps, setLineProps] = useState(
        yAxisDataKeys.reduce(
            (a, {hidden}) => {
                a[hidden] = false;
                return a;
            }
        )
    );

    const setVisibility = (e) => {
        setLineProps({
            ...lineProps,
            [e.dataKey]: !lineProps[e.dataKey]
        });
    };

    const DataFormater = (number) => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000000).toString() + 'M';
        } else if (number === 0) {
            return 0;
        } else if (number < 1000) {
            return (number / 1000).toString() + 'K';
        } else if (number < 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number < 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else {
            return number.toString();
        }
    }

    const renderContent = ({viewBox: {x, y}}) => {
        const d = 66;
        const r = d / 4;

        const transform = `translate(${x - r - 16} ${y - d + 10})`;

        return (
            <>
                <g
                    transform={transform}
                    style={{cursor: "pointer"}}
                    onClick={e => window.open(process.env.REACT_APP_AI_FORECAST_BRANCH_HELP_URL, '_blank').focus()}
                >
                    <MUTooltip title={`Branchen Forecast (${forecastBranch}) - zur Hilfeseite`}>
                        <svg style={{fill: '#f90'}} id="Layer_1" data-name="Layer 1" viewBox="0 0 165.97 137.39"
                             width={d} height={d}>
                            <g>
                                <rect x="62.7" y="80.17" width="9.82" height="9.82" rx="1" ry="1"
                                      transform="translate(-40.36 72.73) rotate(-45)"/>
                                <rect x="62.7" y="63.42" width="9.82" height="9.82" rx="1" ry="1"
                                      transform="translate(-28.51 67.82) rotate(-45)"/>
                                <rect x="63.39" y="47.36" width="8.46" height="8.46" rx=".86" ry=".86"
                                      transform="translate(-16.67 62.91) rotate(-45)"/>
                                <rect x="51.87" y="73.58" width="5.79" height="5.79" rx="1" ry="1"
                                      transform="translate(-38.04 61.11) rotate(-44.99)"/>
                                <rect x="51.87" y="56.83" width="5.79" height="5.79" rx="1" ry="1"
                                      transform="translate(-26.19 56.22) rotate(-45)"/>
                                <rect x="51.87" y="40.09" width="5.79" height="5.79" rx="1" ry="1"
                                      transform="translate(-14.35 51.31) rotate(-45)"/>
                                <rect x="42.17" y="68.75" width="4.08" height="4.08" rx="1" ry="1"
                                      transform="translate(-37.1 51.99) rotate(-45)"/>
                                <rect x="42.17" y="52" width="4.08" height="4.08" rx="1" ry="1"
                                      transform="translate(-25.26 47.09) rotate(-45)"/>
                                <rect x="42.17" y="35.25" width="4.08" height="4.08" rx="1" ry="1"
                                      transform="translate(-13.42 42.18) rotate(-45)"/>
                            </g>
                            <g>
                                <rect x="93.45" y="80.17" width="9.82" height="9.82" rx="1" ry="1"
                                      transform="translate(-31.35 94.47) rotate(-45)"/>
                                <rect x="93.45" y="63.42" width="9.82" height="9.82" rx="1" ry="1"
                                      transform="translate(-19.51 89.57) rotate(-45)"/>
                                <rect x="94.13" y="47.36" width="8.46" height="8.46" rx=".86" ry=".86"
                                      transform="translate(-7.67 84.66) rotate(-45)"/>
                                <rect x="108.32" y="73.58" width="5.79" height="5.79" rx="1" ry="1"
                                      transform="translate(-21.5 101.05) rotate(-45.01)"/>
                                <rect x="108.32" y="56.83" width="5.79" height="5.79" rx="1" ry="1"
                                      transform="translate(-9.66 96.13) rotate(-45)"/>
                                <rect x="108.32" y="40.09" width="5.79" height="5.79" rx="1" ry="1"
                                      transform="translate(2.18 91.23) rotate(-45)"/>
                                <rect x="119.72" y="68.75" width="4.08" height="4.08" rx="1" ry="1"
                                      transform="translate(-14.39 106.84) rotate(-45)"/>
                                <rect x="119.72" y="52" width="4.08" height="4.08" rx="1" ry="1"
                                      transform="translate(-2.55 101.92) rotate(-45)"/>
                                <rect x="119.72" y="35.25" width="4.08" height="4.08" rx="1" ry="1"
                                      transform="translate(9.29 97.02) rotate(-45)"/>
                            </g>
                            <rect x="78.89" y="69.18" width="9.38" height="9.38" rx="1.35" ry="1.35"
                                  transform="translate(-27.75 80.76) rotate(-45.01)"/>
                            <rect x="76.98" y="88.5" width="13.22" height="13.22" rx="1.35" ry="1.35"
                                  transform="translate(-42.78 86.99) rotate(-45.02)"/>
                            <rect x="79.3" y="53.25" width="8.56" height="8.56" rx="1.35" ry="1.35"
                                  transform="translate(-16.2 75.97) rotate(-45.01)"/>
                        </svg>
                    </MUTooltip>
                </g>
            </>
        );
    };

    return (
        <ResponsiveContainer width={window.Cypress ? 300 : "100%"} height={window.Cypress ? 300 : "100%"}>
            <ComposedChart
                data={data}
                margin={{left: 15, top: 50}}
            >
                <CartesianGrid strokeDasharray="5 5"/>
                <XAxis dataKey={xAxisDataKey} padding={{left: 0, right: 0}}/>
                <YAxis unit={"€"} tickFormatter={DataFormater}/>
                <Tooltip content={<CustomTooltip/>}/>

                <ReferenceLine isFront x={forecastDate} stroke="red" strokeDasharray="3 3"
                               label={<Label position="top" content={renderContent}/>}
                />
                <Legend onClick={setVisibility}/>

                {yAxisDataKeys.map((dataKey, index) => (
                    <Line
                        key={dataKey.index}
                        label={dataKey.label}
                        dataKey={dataKey.index + ".sales"}
                        name={dataKey.index}
                        type="monotone"
                        unit={"€"}
                        dot={false}
                        activeDot={{r: 4}}
                        stroke={COLOR_LIST_WITHOUT_GREY[index % COLOR_LIST_WITHOUT_GREY.length]}
                        hide={lineProps[dataKey.index] === true}
                    >
                    </Line>
                ))}
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default MyLineChart;
