import React from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import { validationInt } from 'components/MuiGrid/validations';
import AssociationEditModal from 'components/ResourceGrid/AssociationEditModal';
import NewAssociationFields from 'components/ResourceGrid/NewAssociationFields';
import { useApi } from 'contexts/useApi';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    const { languagesList } = useRelatedDataContext();
    const { getValueBasedOnLanguages } = useApi();

    const languageFormatter = ({ value }) => {
        if (!value) return '';
        return (languagesList.find(l => l.value === value) || {}).label || value;
    }

    return (
        <ResourceGrid
            customResource='products-conditions'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            extraParams={{
                associations: {
                    productsConditionsDescription: {},
                }
            }}
            columns={[
                { field: 'id', headerName: translate('resources.products_conditions.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'productsConditionsCode', headerName: translate('resources.products_conditions.fields.productsConditionsCode'), minWidth: 160, editable: true },
                {
                    field: 'productsConditionsDescription',
                    headerName: translate('resources.products_conditions_description.fields.productsConditionsName'),
                    minWidth: 140,
                    flex: 1,
                    sortField: 'productsConditionsDescription.productsConditionsName',
                    editable: true,
                    valueFormatter: ({ value }) => getValueBasedOnLanguages(Object.values(value || {}), 'productsConditionsName'),
                    renderEditCell: (params) => (
                        <AssociationEditModal
                            {...params}
                            associationColumns={[
                                { field: 'id', headerName: translate('resources.products_conditions_description.fields.id'), hide: true },
                                { field: 'languagesId', headerName: translate('resources.products_conditions_description.fields.languagesId'), minWidth: 140, valueFormatter: languageFormatter, type: 'singleSelect', valueOptions: languagesList, editable: true },
                                { field: 'productsConditionsName', headerName: translate('resources.products_conditions_description.fields.productsConditionsName'), minWidth: 200, flex: 1, editable: true },
                            ]}
                            associationResource='products-conditions-description'
                            parentIdField='productsConditionsId'
                            modalTitle={translate("resources.products_conditions_description.name")}
                        />
                    ),
                    renderNewCell: (field, required) => (
                        <NewAssociationFields
                            field={field}
                            required={required}
                            associationFields={[
                                { configurationKey: 'languagesId', label: translate('resources.products_conditions_description.fields.languagesId'), type: 'singleSelect', valueFromRelated: 'languagesList' },
                                { configurationKey: 'productsConditionsName', label: translate('resources.products_conditions_description.fields.productsConditionsName') },
                            ]}
                        />
                    ),
                    paramsNewCell: { resource: 'products-conditions-description', parentIdField: 'productsConditionsId' },
                },
                { field: 'sortOrder', headerName: translate('resources.shipping_methods.fields.sortOrder'), maxWidth: 60, type: 'number', preProcessEditCellProps: validationInt, editable: true },
            ]}
        />
    )
}

export const ProductsConditionsList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.products_conditions.name')} />
            <Grid />
        </>
    )
}
