import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Fade, IconButton, Tooltip } from '@mui/material';
import { useTranslate } from 'react-admin';
import Reactour, { ReactourStep } from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useTourContext } from 'contexts/TourContext';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';

const Tour = () => {
    const translate = useTranslate();

    const [openTooltip, setOpenTooltip] = useState(true);
    useEffect(() => {
        setTimeout(() => setOpenTooltip(prev => false), 4000);
    }, []);

    const { buttonSwitchLangId, menuSwitchLangId, openSwitchLang, setOpenSwitchLang, buttonUserId, menuUserId, openUserMenu, setOpenUserMenu } = useTourContext();
    const [isTourOpen, setIsTourOpen] = useState(false);
    const tourSteps = useMemo(() => {
        const _tourSteps: ReactourStep[] = [
            {
                content: <h2>{translate('pos.tour.steps.switchLang')}</h2>,
                selector: `#${buttonSwitchLangId}`,
                position: 'bottom',
            },
            {
                content: <h2>{translate('pos.tour.steps.switchLangHere')}</h2>,
                selector: `#${menuSwitchLangId}`,
                position: 'bottom',
                stepInteraction: false,
                action: (node) => setInteractionOnly(prev => false),
            },
            {
                content: <h2>{translate('pos.tour.steps.sidebarMenu')}</h2>,
                selector: '.RaAppBar-menuButton',
                position: 'right',
                action: (node) => setOpenSwitchLang(prev => false),
            },
            {
                content: <h2>{translate('pos.tour.steps.userMenu')}</h2>,
                selector: `#${buttonUserId}`,
                position: 'bottom',
                action: (node) => setInteractionOnly(prev => true),
            },
            {
                content: <h2>{translate('pos.tour.steps.userMenuHere')}</h2>,
                selector: `#${menuUserId}`,
                position: 'bottom',
                stepInteraction: false,
                action: (node) => setInteractionOnly(prev => false),
            },
            {
                content: <h2>{translate('pos.tour.steps.last')}</h2>,
                action: (node) => setOpenUserMenu(prev => false),
            },
        ];
        return _tourSteps;
    }, [translate, buttonSwitchLangId, menuSwitchLangId, setOpenSwitchLang, buttonUserId, menuUserId, setOpenUserMenu]);
    const disableBody = (target) => disableBodyScroll(target);
    const enableBody = (target) => enableBodyScroll(target);

    const [directStep, setDirectStep] = useState(0);
    const [interactionOnly, setInteractionOnly] = useState(true);
    useEffect(() => {
        if (isTourOpen && directStep === 0 && openSwitchLang) setDirectStep(1);
        // eslint-disable-next-line
    }, [openSwitchLang]);
    useEffect(() => {
        if (isTourOpen && directStep === 3 && openUserMenu) setDirectStep(4);
        // eslint-disable-next-line
    }, [openUserMenu]);

    const onCloseTour = () => {
        setInteractionOnly(true);
        setDirectStep(0);
        setIsTourOpen(false);
    }

    return (
        <Box>
            <Tooltip
                open={openTooltip}
                title={<Box sx={{ padding: 1, fontSize: 16 }}>{translate('pos.tour.tooltip')}</Box>}
                placement="bottom"
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 2000 }}
                enterDelay={500}
                disableFocusListener
                disableHoverListener
                disableInteractive
                disableTouchListener
            >
                <IconButton onClick={() => setIsTourOpen(true)}>
                    <HelpOutlineIcon color="secondary" />
                </IconButton>
            </Tooltip>
            <Reactour
                steps={tourSteps}
                startAt={0}
                isOpen={isTourOpen}
                onRequestClose={onCloseTour}
                nextStep={() => setDirectStep(prev => prev + 1)}
                prevStep={() => setDirectStep(prev => prev - 1)}
                nextButton={<Button variant='outlined'>{`${translate('pos.tour.next')} >`}</Button>}
                prevButton={<Button variant='outlined'>{`< ${translate('pos.tour.back')}`}</Button>}
                lastStepNextButton={<Button variant='contained'>{translate('pos.tour.done')}</Button>}
                rounded={6}
                accentColor='#f00'
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
                goToStep={directStep}
                closeWithMask={false}
                disableFocusLock
                disableKeyboardNavigation
                disableDotsNavigation
                showButtons={!interactionOnly}
                showNavigation={!interactionOnly}
                showCloseButton={!interactionOnly}
            />
        </Box>
    )
}

export default Tour;
