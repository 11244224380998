export const cookieDomain = process.env.REACT_APP_BB2_SUBDOMAIN;

export const readCookie = (name = "accessToken") => {
  const part = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));
  if (!part) return "";
  const myCookie = part.split("=")[1];
  // console.log({ myCookie });
  return myCookie;
};
