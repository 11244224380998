import { useDataProvider, useLocaleState, useNotify, useTranslate } from 'react-admin';
import { Params } from 'providers/data/FilterUtils';
import { onError } from 'utils/errors';

const LIMIT = 300;
const MAIN_LANGUAGES_ID = 1;
const MAIN_LANGUAGES_CODE = 'de';
const SECONDARY_LANGUAGES_ID = 2;
const SECONDARY_LANGUAGES_CODE = 'en';
interface ListBaseLang {
    languagesId: number;
    languagesCode?: string;
}

interface GetListResult {
    data: any[];
    total?: number;
    error?: any;
}

export const useApi = () => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const notify = useNotify();
    const [locale = 'de'] = useLocaleState();

    const getValueBasedOnLanguages = (listBasedOnLanguages: ListBaseLang[], fieldName: string, byLanguagesCode = false) => {
        let mainRecord;
        let secondaryRecord;
        if (byLanguagesCode) {
            mainRecord = listBasedOnLanguages.find(d => d.languagesCode === MAIN_LANGUAGES_CODE);
            secondaryRecord = listBasedOnLanguages.find(d => d.languagesCode === SECONDARY_LANGUAGES_CODE);
        } else {
            const mainLangId = locale === MAIN_LANGUAGES_CODE ? MAIN_LANGUAGES_ID : SECONDARY_LANGUAGES_ID;
            const secondLangId = locale === MAIN_LANGUAGES_CODE ? SECONDARY_LANGUAGES_ID : MAIN_LANGUAGES_ID;
            mainRecord = listBasedOnLanguages.find(d => d.languagesId === mainLangId);
            secondaryRecord = listBasedOnLanguages.find(d => d.languagesId === secondLangId);
        }
        return (mainRecord || secondaryRecord || listBasedOnLanguages[0] || {})[fieldName] || '';
    }

    const getList = async (resource: string, queryParams: Params): Promise<GetListResult> => {
        try {
            const result = await dataProvider.getList(resource, queryParams);
            if (result) {
                const { data, total } = result;
                return { data, total };
            } else {
                return {};
            }
        } catch (error) {
            onError(error, translate, notify);
            return { error, data: [], total: 0 };
        }
    }

    /**
     * @param {any[]} wholeArray will be changed after run
     * @param {string} resource 
     * @param {Omit<Params, 'limit', 'page'>} paramsWithoutPagination 
     * @param {number} page 
     * @param {number} perPage 
     * @returns {Promise<void>}
     */
    const recursiveGetList = async (wholeArray, resource, paramsWithoutPagination: Params = {}, page = 1, perPage = LIMIT) => {
        try {
            const { data, total } = await getList(resource, {
                ...paramsWithoutPagination,
                limit: perPage,
                page,
            });
            if (!data?.length) return;
            wholeArray.push(...data);
            if (wholeArray.length < total) {
                await recursiveGetList(wholeArray, resource, paramsWithoutPagination, page + 1, perPage);
            }
        } catch (err) {
            onError(err, translate, notify);
        }
    }

    const createRecord = async (resource, newRecord) => {
        try {
            const { data } = await dataProvider.create(resource, { data: newRecord });
            return { data };
        } catch (error) {
            onError(error, translate, notify);
            return { error };
        }
    }

    const updateRecord = async (resource, updatedRecord) => {
        try {
            const { data } = await dataProvider.update(resource, { data: updatedRecord });
            return { data };
        } catch (error) {
            onError(error, translate, notify);
            return { error };
        }
    }

    const deleteRecord = async (resource, id) => {
        try {
            await dataProvider.delete(resource, { id });
            return {};
        } catch (error) {
            onError(error, translate, notify);
            return { error };
        }
    }

    return {
        getList,
        recursiveGetList,
        getValueBasedOnLanguages,
        createRecord,
        updateRecord,
        deleteRecord,
    }
}
