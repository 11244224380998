import React from "react";
import { useBB2MandantContext } from "./BB2MandantContext";
import { Box, Card, CardContent, Grid } from "@mui/material";
import MuiSelect from "components/MuiSelect";

const MandantPicker = ({ sx }) => {
  const { mandants, mandant, setMandant } = useBB2MandantContext();

  if ((mandants || []).length < 2) return null;

  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item md={4} xs={12} sx={sx}>
        <Box>
          <Card>
            <CardContent>
              <MuiSelect
                label={"Mandant"}
                options={mandants || []}
                value={mandant?.value}
                onChange={(e) => {
                  const m = mandants.find(({ value }) => value === e.target.value);
                  setMandant(m);
                }}
                sx={{ backgroundColor: "white" }}
              />
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MandantPicker;
