import React from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    return (
        <ResourceGrid
            customResource='media-categories'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.media_categories.fields.id'), maxWidth: 20, hide: true },
                { field: 'mediaCategoriesCode', headerName: translate('resources.media_categories.fields.mediaCategoriesCode'), minWidth: 100, editable: true },
                { field: 'mediaCategoriesName', headerName: translate('resources.media_categories.fields.mediaCategoriesName'), minWidth: 160, flex: 1, editable: true },
            ]}
        />
    )
}

export const MediaCategoriesList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.media_categories.name')} />
            <Grid />
        </>
    )
}
