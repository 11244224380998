import React, { useEffect, useState } from 'react';
import { required, TextInput, useLocaleState, useLogin, useNotify, useTranslate } from 'react-admin';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import LoginLayout from 'layout2/LoginLayout';
import { useEntityAccess } from 'contexts/EntityAccessContextContainer.js';

interface FormValues {
    username?: string;
    password?: string;
}

const Login = () => {
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const login = useLogin();
    const [isSaveLogin, setIsSaveLogin] = useState(false);
    const { bb2Config: { name, loginLogo, error } } = useEntityAccess();

    const [locale = 'de', setLocale] = useLocaleState();

    const { bb2Config: { apiUrl } } = useEntityAccess();
    useEffect(() => {
        if (apiUrl) return;
        setLoading(true);
    }, [apiUrl]);

    const handleSubmit = (auth: FormValues) => {
        setLocale(locale);
        setLoading(true);
        login(
            { ...auth, apiUrl },
            '/check-permissions'
        ).catch((error) => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
                {
                    type: 'warning',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    },
                }
            );
        });
    };

    if (error) {
        return (
            <LoginLayout>
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                    {loginLogo ? (
                        <img src={loginLogo} alt="logo2" style={{ maxWidth: 335, maxHeight: 70 }} />
                    ) : (
                        name
                    )}
                </Box>
                <Box sx={{ padding: '0 1em 1em 1em' }}>
                    <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 700 }}>
                        {translate("pos.client_not_found")}
                    </Typography>
                </Box>
                <Box sx={{ padding: '1em 1em 1em 1em' }}>
                    <Button variant="contained" fullWidth onClick={() => window.location.href = "https://login.brickfox.io"}>
                        {translate("pos.go_to_login")}
                    </Button>
                </Box>
            </LoginLayout>
        );
    }

    return (
        <LoginLayout
            loading={loading}
            formId="login-form"
            buttonTitle={translate('ra.auth.sign_in')}
            handleSubmit={handleSubmit}
            linkTitle={translate('pos.forgot_your_password')}
            linkTo="/forgot-password"
        >
            <Box sx={{ textAlign: 'center', mb: 2 }}>
                {loginLogo ? (
                    <img src={loginLogo} alt="logo2" style={{ maxWidth: 335, maxHeight: 70 }} />
                ) : (
                    name
                )}
            </Box>
            <Box sx={{ padding: '0 1em 1em 1em' }}>
                <Box>
                    <TextInput
                        autoFocus
                        source="username"
                        label={translate('ra.auth.username')}
                        disabled={loading}
                        validate={required()}
                        fullWidth
                        helperText={false}
                        variant="outlined"
                    />
                </Box>
                <Box sx={{ paddingTop: 1 }}>
                    <TextInput
                        source="password"
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={loading}
                        validate={required()}
                        fullWidth
                        helperText={false}
                        variant="outlined"
                    />
                </Box>
                {false && <Box sx={{ paddingTop: 2 }}>
                    <FormControlLabel
                        label={translate('pos.noLayout.saveLogin')}
                        control={<Checkbox checked={isSaveLogin} onChange={e => setIsSaveLogin(e.target.checked)} />}
                        disabled={!name}
                    />
                </Box>}
            </Box>
        </LoginLayout>
    );
}

export default Login;
