import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Title, useLocaleState, useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useBB2MandantContext } from "../BB2MandantContext";
import { Box, Grid } from "@mui/material";
import StatisticCard from "components/StatisticCard";
import { Receipt, ReceiptOutlined } from "@mui/icons-material";
import WorkflowUsage from "./WorkflowUsage";
import TotalCallsPerMonth from "./TotalCallsPerMonth";
import BillingPlan from "./BillingPlan";
import moment from "moment";
import { bb2api } from "../bb2-api";
import { getPriceText } from "./getPriceText";
import MandantPicker from "../MandantPicker";

const BB2Billing = () => {
  // Check authentication
  const navigate = useNavigate();
  const { isAuthed, mandant, getMandantTokens } = useBB2MandantContext();
  useEffect(() => {
    if (isAuthed) return;
    navigate("/bb2/login", { state: { target: "billing" } });
  });

  const translate = useTranslate();

  /**
   * Calc Billing Plans
   */
  const billingPlans = useMemo(() => {
    if (!mandant) return [];
    const rows = mandant.dashboardSettings.find(({ key }) => key === "billing-plan").value;
    return rows.map(({ range: { from, to }, costs }, i) => ({
      id: i,
      from,
      to,
      range: `${from} - ${to}`,
      costs,
    }));
  }, [mandant]);
  const calcUsageCosts = useCallback((usage = 0) => {
    const plan = billingPlans.find(({ from, to }) => (from <= usage && usage <= to));
    if (!plan) return 0;
    return plan.costs;
  }, [billingPlans]);

  /**
   * Workflow Usage
   */
  const [locale = "de"] = useLocaleState();
  const [workflowOptions, setWorkflowOptions] = useState([]);
  const [workflowOpt, setWorkflowOpt] = useState();
  useEffect(() => {
    // Calc workflowOptions
    moment.locale(locale);
    const _workflowOptions = [];
    const getOneOpt = (mom) => {
      const from = mom.clone().startOf("month").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
      const to = mom.clone().add(1, "month").startOf("month").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
      return {
        value: `${from}_${to}`,
        label: mom.clone().format("MMM yyyy"),
      };
    };
    const now = moment();
    for (let i = 0; i < 12; i++) {
      const opt = getOneOpt(now.clone().subtract(i, "month"));
      _workflowOptions.push(opt);
    }
    setWorkflowOptions(_workflowOptions);
    setWorkflowOpt(_workflowOptions[0].value);
  }, [locale]);
  const { data: workflowUsageData = {}, isUsageLoading } = useQuery(["get-workflow-usage", mandant, workflowOptions], async () => {
    if (!workflowOptions.length) return {};
    const promises = workflowOptions.map(({ value }) => {
      const [from, to] = value.split("_");
      return bb2api.getUsageWorkflow(from, to, getMandantTokens().billingToken);
    });
    const results = await Promise.all(promises);
    const usageDatas = {};
    workflowOptions.forEach(({ value }, i) => {
      const { data } = results[i];
      const _data = [];
      data.forEach(({ name, data }, i) => {
        data.forEach(({ customerid, name, total_usage_per_workflow, workflow_name }, j) => {
          _data.push({
            id: `${i}-${j}`,
            workflow_name,
            total_usage_per_workflow: Number(total_usage_per_workflow),
          });
        });
      });
      usageDatas[value] = _data;
    });
    return usageDatas;
  });

  const getMonthlyUsage = useCallback((index = 0) => {
    const key = Object.keys(workflowUsageData)[index];
    if (!key) return 0;
    const usage = workflowUsageData[key].reduce((prev, curr) => (prev + curr.total_usage_per_workflow), 0);
    return usage;
  }, [workflowUsageData]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <MandantPicker sx={{ mb: 2 }} />
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Title title={"bb2.pages.billing"} />
        <Grid item md={3} xs={12}>
          <StatisticCard
            title="bb2.fields.invoice"
            number={calcUsageCosts(getMonthlyUsage(1))}
            renderNumber={getPriceText}
            icon={<Receipt fontSize="large" sx={{ color: "#fff" }} />}
            footer={{
              desc: "bb2.fields.last_month",
            }}
            loading={isUsageLoading}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <StatisticCard
            title="bb2.fields.invoice_month"
            number={calcUsageCosts(getMonthlyUsage(0))}
            renderNumber={(num) => getPriceText(num, translate("bb2.fields.estimated"))}
            icon={<ReceiptOutlined fontSize="large" sx={{ color: "#fff" }} />}
            footer={{
              desc: "bb2.fields.estimation_this_month",
            }}
            loading={isUsageLoading}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item md={6} xs={12}>
          <WorkflowUsage
            workflowOptions={workflowOptions}
            workflowOpt={workflowOpt}
            setWorkflowOpt={setWorkflowOpt}
            isLoading={isUsageLoading}
            workflowUsageData={workflowUsageData}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TotalCallsPerMonth
            workflowOptions={workflowOptions}
            calcUsageCosts={calcUsageCosts}
            getMonthlyUsage={getMonthlyUsage}
            isLoading={isUsageLoading}
            workflowUsageData={workflowUsageData}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <BillingPlan billingPlans={billingPlans} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BB2Billing;
