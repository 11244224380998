import { GridPreProcessEditCellProps } from '@mui/x-data-grid-pro';

export const validationOneChar = (params: GridPreProcessEditCellProps) => {
    const { props } = params;
    const { value } = props;
    const hasError = (value || '').length !== 1;
    return {
        ...props,
        error: hasError,
    };
}

export const validationInt = (params: GridPreProcessEditCellProps) => {
    const { props } = params;
    const { value } = props;
    const hasError = value % 1 !== 0;
    return {
        ...props,
        error: hasError,
    };
}
