import MuiGrid, { Column } from 'components/MuiGrid';
import React, { useEffect, useState } from 'react';
import { useResourceContext } from 'react-admin';
import { GridCallbackDetails, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { buildFilters, Filter, Params } from 'providers/data/FilterUtils';
import { Sort } from 'providers/data/SortUtils';
import { useApi } from 'contexts/useApi';

const initialPerPage = parseInt(window.Cypress ? window.Cypress.env('REACT_APP_API_DEFAULT_PER_PAGE') : process.env.REACT_APP_API_DEFAULT_PER_PAGE, 10);

interface Props {
    loading?: boolean;
    customResource?: string;
    extraParams?: Params;
    extraFilters?: Filter[];
    columns: Column[];
    keepFieldsOnUpdate?: string[];
    readonly: boolean;
    gotoDetailOnCellClick: boolean;
    editGridConf: boolean;
}

const ResourceGrid = ({
    loading = false,
    extraFilters,
    extraParams = {},
    customResource,
    columns = [],
    keepFieldsOnUpdate,
    readonly = false,
    gotoDetailOnCellClick = false,
    editGridConf = false,
    dataOnSaveGridConf,
}: Props) => {
    let resource = useResourceContext();
    if (customResource) resource = customResource;
    const { getList } = useApi();
    const [isLoading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(initialPerPage);
    const [filters, setFilters] = useState([]);
    const [sort, setSort] = useState({});

    const fetchList = async (page, perPage, builtFilters = undefined, builtSort = undefined, treatAsFirst = false) => {
        if (editGridConf) {
            return;
        }
        if (!treatAsFirst) {
            let pageTotal = page * perPage;
            if (rowCount && pageTotal > rowCount) pageTotal = rowCount;
            if (pageTotal <= rows.length) return;
        }

        setLoading(true);
        let prevRows = rows;
        if (treatAsFirst) {
            setRows([]);
            prevRows = [];
        }
        const { data, total } = await getList(resource, {
            ...extraParams,
            limit: perPage,
            page,
            filter: [...(builtFilters || filters), ...(extraFilters || [])],
            sort: builtSort || sort,
        });
        setRowCount(total);
        if (data.length) {
            let startIndex = data.length;
            for (let i = 0; i < data.length; i++) {
                const index = prevRows.findIndex(r => r.id === data[i].id);
                if (index < 0) {
                    startIndex = i;
                    break;
                }
            }
            const newRows = data.slice(startIndex);
            setRows(prevRows.concat(newRows));
        }
        setLoading(false);
    }

    const onPageSizeChange = (_perPage) => {
        setPerPage(_perPage);
        fetchList(page, _perPage);
    }

    const onPageChange = (_page/*zero-based*/) => {
        setPage(_page + 1);
        fetchList(_page + 1, perPage);
    }

    const onFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
        const newFilters: Filter[] = buildFilters(columns, model);
        if (filters.length === 0 && newFilters.length === 0) return;
        setFilters(newFilters);
        setPage(1);
        fetchList(1, perPage, newFilters, undefined, true);
    }

    const onSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
        const newSort: Sort = {};
        if (model.length > 0) {
            const { field, sort: direction } = model[0];
            const column = columns.find(c => c.field === field);
            newSort.field = column?.sortField || field;
            newSort.direction = direction;
        }
        setSort(newSort);
        setPage(1);
        fetchList(1, perPage, undefined, newSort, true);
    }

    const cbAfterSubmitCreateModal = () => {
        setPage(1);
        fetchList(1, perPage, undefined, undefined, true);
    }

    useEffect(() => {
        fetchList(1, perPage, undefined, undefined, true);
        /* eslint-disable-next-line */
    }, [extraFilters]);

    return (
        <MuiGrid
            isLoading={isLoading || loading}
            setRows={setRows}
            rows={rows}
            columns={columns}
            page={page}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onFilterModelChange={onFilterModelChange}
            onSortModelChange={onSortModelChange}
            resource={resource}
            keepFieldsOnUpdate={keepFieldsOnUpdate}
            cbAfterSubmitCreateModal={cbAfterSubmitCreateModal}
            readonly={readonly || editGridConf}
            gotoDetailOnCellClick={gotoDetailOnCellClick}
            dataOnSaveGridConf={dataOnSaveGridConf}
        />
    )
}

export default ResourceGrid;
