import React from 'react';
import MuiGrid from 'components/MuiGrid';
import { validationInt } from 'components/MuiGrid/validations';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import { Title, useTranslate } from 'react-admin';
import AssociationEditModal from 'components/ResourceGrid/AssociationEditModal';
import NewAssociationFields from 'components/ResourceGrid/NewAssociationFields';
import { useApi } from 'contexts/useApi';
import { useSort } from 'providers/data/SortUtils';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const { unitsList, mutateUnit, setUnitsList, fetchUnitsList, languagesList } = useRelatedDataContext();
    const translate = useTranslate();
    const { getValueBasedOnLanguages } = useApi();
    const { sortComparatorBasedOnLanguages } = useSort();

    const languageFormatter = ({ value }) => {
        if (!value) return '';
        return (languagesList.find(l => l.value === value) || {}).label || value;
    }

    return (
        <MuiGrid
            isLoading={!unitsList.length}
            rows={editGridConf ? [] : unitsList}
            readonly={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.units.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'measure', headerName: translate('resources.units.fields.measure'), maxWidth: 90, editable: true },
                { field: 'baseUnitsFactor', headerName: translate('resources.units.fields.baseUnitsFactor'), minWidth: 130, type: 'number', editable: true },
                { field: 'sortOrder', headerName: translate('resources.units.fields.sortOrder'), maxWidth: 80, type: 'number', editable: true, preProcessEditCellProps: validationInt },
                { field: 'baseUnitsId', headerName: translate('resources.units.fields.baseUnitsId'), minWidth: 110, type: 'number', editable: true, preProcessEditCellProps: validationInt },
                {
                    field: 'unitsDescription',
                    headerName: translate('resources.units.fields.name'),
                    minWidth: 160,
                    flex: 1,
                    sortComparator: (v1, v2) => sortComparatorBasedOnLanguages(v1, v2, 'unitsName'),
                    editable: true,
                    valueFormatter: ({ value }) => getValueBasedOnLanguages(Object.values(value || {}), 'unitsName'),
                    renderEditCell: (params) => (
                        <AssociationEditModal
                            {...params}
                            associationColumns={[
                                { field: 'id', headerName: translate('resources.units_description.fields.id'), hide: true },
                                { field: 'languagesId', headerName: translate('resources.units_description.fields.language'), minWidth: 140, sortable: false, valueFormatter: languageFormatter, type: 'singleSelect', valueOptions: languagesList, editable: true },
                                { field: 'unitsName', headerName: translate('resources.units_description.fields.unitsName'), minWidth: 200, flex: 1, editable: true },
                            ]}
                            associationResource='units-description'
                            parentIdField='unitsId'
                            parentMutate={mutateUnit}
                            modalTitle={translate("resources.units_description.name")}
                        />
                    ),
                    renderNewCell: (field, required) => (
                        <NewAssociationFields
                            field={field}
                            required={required}
                            associationFields={[
                                { configurationKey: 'languagesId', label: translate('resources.units_description.fields.language'), type: 'singleSelect', valueFromRelated: 'languagesList' },
                                { configurationKey: 'unitsName', label: translate('resources.units_description.fields.unitsName') },
                            ]}
                        />
                    ),
                    paramsNewCell: { resource: 'units-description', parentIdField: 'unitsId' },
                },
            ]}
            setRows={setUnitsList}
            keepFieldsOnUpdate={['unitsDescription']}
            mutateOnUpdate={mutateUnit}
            resource='units'
            cbAfterSubmitCreateModal={() => {
                setUnitsList([]);
                fetchUnitsList();
            }}
        />
    )
}

export const UnitsList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.units.name')} />
            <Grid />
        </>
    )
}
