import React from 'react';
import { Box } from '@mui/material';
import { Title, useTranslate } from 'react-admin';
import SelectTheme from 'theme/SelectTheme';
import ProfileForm from './ProfileForm';
import ChangePasswordForm from './ChangePasswordForm';
import { commonStyles } from 'theme/theme2';

const Profile = () => {
    const translate = useTranslate();

    return (
        <>
            <Title title={translate('pos.profile')} />
            <Box sx={{ ...commonStyles.content_root }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box sx={{ p: 2 }}>
                        <ProfileForm />
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <ChangePasswordForm />
                    </Box>
                </Box>
                <Box sx={{ p: 2 }}>
                    <SelectTheme />
                </Box>
            </Box>
        </>
    )
}

export default Profile;
