import React, { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import QRCode from "qrcode.react";
import MuiModal from "components/MuiModal";
import OTPInput from "react-otp-input";
import { useNotify, useTranslate } from "react-admin";
import CircularProgressOverlay from "components/CircularProgressOverlay";
import MuiTextField from "components/MuiTextField";
import { getCurrentUser } from "providers/currentUser";
import {
  awsSendMFACode,
  awsSetUserMfa,
  awsVerifySoftwareToken,
} from "utils/awsCognito";

const TotpQRCodeInput = ({
  open = false,
  onClose,
  // * register mfa
  secretCode = undefined,
  onVerificationSuccess = () => {},
  // * send mfa code
  deviceName,
  onSendCodeSuccess = (accessToken, idToken, refreshToken, usr) => {},
}) => {
  const t = useTranslate();
  const notify = useNotify();
  const user = getCurrentUser();
  const qrcodeUrl = useMemo(() => {
    if (!secretCode) return null;
    const emailEncoded = encodeURIComponent(user.username);
    const secretEncoded = encodeURIComponent(secretCode);
    const app = "Brickfox Tenant Hub";
    return `otpauth://totp/${app}:${emailEncoded}?secret=${secretEncoded}&issuer=${app}`;
    // eslint-disable-next-line
  }, [secretCode]);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const handleChange = async (code) => {
    setOtp(code);
    if (code.length !== 6) return;
    setLoading(true);
    if (secretCode) {
      awsVerifySoftwareToken(
        code,
        user.username,
        () => {
          awsSetUserMfa(
            true,
            false,
            true,
            () => {
              setLoading(false);
              onVerificationSuccess();
            },
            (err) => {
              setLoading(false);
              notify(err.message, { type: "error" });
            }
          );
        },
        (err) => {
          setLoading(false);
          notify(err.message, { type: "error" });
        }
      );
    } else {
      awsSendMFACode(
        code,
        (accessToken, idToken, refreshToken, usr) => {
          setLoading(false);
          onSendCodeSuccess(accessToken, idToken, refreshToken, usr);
        },
        (err) => {
          setLoading(false);
          notify(err.message, { type: "error" });
        }
      );
    }
  };

  return (
    <MuiModal
      title={t("pos.two_factor_auth.title")}
      open={open}
      onClose={onClose}
      onCloseOutsideClick={() => {}}
      dialogContents={
        <>
          <CircularProgressOverlay active={loading} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              mb: 3,
            }}
          >
            {qrcodeUrl ? (
              <QRCode value={qrcodeUrl} size={250} />
            ) : (
              <Typography>{deviceName}</Typography>
            )}
          </Box>
          <OTPInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            shouldAutoFocus
            renderSeparator={<span>-</span>}
            renderInput={(props, i) => <MuiTextField {...props} />}
            inputType="text"
            inputStyle={{ width: 38, height: 39 }}
            containerStyle={{ padding: 24 }}
          />
        </>
      }
    />
  );
};

export default TotpQRCodeInput;
