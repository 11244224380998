import React from 'react';
import { useSidebarState, useTranslate } from 'react-admin';
import { Collapse, List, ListItemIcon, MenuItem, Tooltip, Typography } from '@mui/material';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useResponsiveContext } from 'contexts/ResponsiveContextContainer';

const useStyles = makeStyles((theme) => ({
        sidebar_submenu_root: {
            color: `${theme.sidebarTextColor} !important`,
            "& svg": {
                color: `${theme.sidebarTextColor} !important`,
            },
            "&:hover": {
                color: theme.sidebarHoverTextColor,
                backgroundColor: theme.sidebarHoverBg,
            },
        },
        sidebar_submenu_selected: {
            backgroundColor: `${theme.sidebarExpandedBg} !important`,
            "&:hover": {
                color: theme.sidebarHoverTextColor,
                backgroundColor: `${theme.sidebarHoverBg} !important`,
            },
        },
    }
))

interface Props {
    name: string;
    isOpen: boolean;
    handleToggle: () => void;
    icon: ReactElement;
    dense: boolean;
    children: ReactNode;
}

const SubMenu = ({
    name = 'sub-menu',
    noNeedTranslation,
    isOpen = false,
    handleToggle = () => { },
    icon,
    dense = false,
    children,
}: Props) => {
    const translate = useTranslate();
    const [sidebarIsOpen] = useSidebarState();
    const { primaryColor } = useResponsiveContext();
    const classes = useStyles({ primaryColor });

    const header = (
        <MenuItem dense={dense} selected={isOpen} onClick={handleToggle} classes={{ root: classes.sidebar_submenu_root, selected: classes.sidebar_submenu_selected }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
                {icon}
            </ListItemIcon>
            <Typography variant="inherit">
                {noNeedTranslation ? name : translate(name)}
            </Typography>
            <ListItemIcon sx={{ minWidth: 5, marginLeft: 'auto' }}>
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
        </MenuItem>
    );
    
    return (
        <div>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    sx={(theme) => ({
                        '& a': {
                            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                            color: theme.sidebarTextColor,
                            paddingLeft: sidebarIsOpen ? 4 : 2,
                        },
                    })}
                >
                    {children}
                </List>
            </Collapse>
        </div>
    )
}

export default SubMenu;
