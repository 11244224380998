import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "react-admin";
import { useEntityAccess } from "contexts/EntityAccessContextContainer.js";

const EntityAccessFields = () => {
  const navigate = useNavigate();
  const [fetched, setFetched] = useState(false);
  const { fetchAndSetMenuItems } = useEntityAccess();

  const fetchAll = async () => {
    // Fetch others
    await fetchAndSetMenuItems();
    setFetched(true);
  };

  useEffect(() => {
    fetchAll();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!fetched) return;
    navigate("/");
    // eslint-disable-next-line
  }, [fetched]);

  return <Loading />;
};

export default EntityAccessFields;
