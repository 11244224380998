import React from 'react';
import { Typography } from '@mui/material';
import { AppBar, useSidebarState } from 'react-admin';
import SwitchLang from './switch-lang';
import { CLOSED_DRAWER_WIDTH, DRAWER_WIDTH } from "theme/theme2";
import Tour from 'components/Tour';
import CustomUserMenu from 'layout2/NavDrawer/CustomUserMenu';
import { useResponsiveContext } from 'contexts/ResponsiveContextContainer';

const CustomAppBar = (props) => {
    const { isPhone } = useResponsiveContext();
    const [sidebarIsOpen] = useSidebarState();
    const drawerWidth = sidebarIsOpen ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH;
    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<CustomUserMenu />}
            sx={(theme) => ({
                width: isPhone ? "100%" : `calc(100% - ${drawerWidth}px)`,
                padding: "0 !important",
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.background.default,
                // transform: "none !important",
                // visibility: "visible !important",
                // boxShadow: `0px 2px 1px -1px ${OUR_COLORS[selectedThemeKey][800]}, 0px 1px 1px 0px ${OUR_COLORS[selectedThemeKey][600]}, 0px 1px 3px 0px ${OUR_COLORS[selectedThemeKey][400]}`,
                transition: (theme) =>
                    theme.transitions.create("width", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            })}
        >
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    marginRight: 'auto',
                    textAlign: 'center',
                }}
                id="react-admin-title"
            />
            <Tour />
            <SwitchLang />
        </AppBar>
    )
}

export default CustomAppBar;
