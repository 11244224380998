export const LANG_OPTIONS = {
    de: {
        shortName: 'DE',
        longName: 'Deutsch',
        value: 'de',
        label: 'Deutsch',
        translatable: "general.lang.de",
        flag: "DE", // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    },
    en: {
        shortName: 'EN',
        longName: 'English',
        value: 'en',
        label: 'English',
        translatable: "general.lang.en",
        flag: "GB",
    },
}
