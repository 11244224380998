import React, { createContext, useContext, useState } from "react";
import { useNotify } from "react-admin";
import { useQuery } from "react-query";
import { bb2api } from "./bb2-api";
import { isAuthenticated } from "./bb2-auth";

const BB2MandantContext = createContext({
  isAuthed: false,
  mandants: null,
  mandant: null,
  setMandant: (mandant) => {},
  getMandantTokens: () => ({ billingToken: "", reportingToken: "" }),
  isMandantLoading: false,
});

export const useBB2MandantContext = () => useContext(BB2MandantContext);

export const BB2MandantContextContainer = ({ children }) => {
  const notify = useNotify();
  const isAuthed = isAuthenticated();
  const [mandant, setMandant] = useState();
  const { data: mandants, isLoading: isMandantLoading } = useQuery(["bb2-get-mandant"], async () => {
    const { data, error } = await bb2api.getMandant();
    if (error) {
      notify(error.message, { type: 'error' });
      return null;
    }
    setMandant(data[0]);
    return data;
  }, {
    enabled: !mandant && isAuthed,
  });

  const getMandantTokens = () => {
    if (!mandant) return {};
    const billingToken = mandant.dashboardSettings.find((a) => a.key === "engine-billing-service-token").value;
    const reportingToken = mandant.dashboardSettings.find((a) => a.key === "reporting-service-token").value;
    return { billingToken, reportingToken };
  }

  return (
    <BB2MandantContext.Provider
      value={{
        isAuthed,
        mandants,
        mandant,
        setMandant,
        getMandantTokens,
        isMandantLoading,
      }}
    >
      {children}
    </BB2MandantContext.Provider>
  );
};
