import React from 'react';
import { Title, useTranslate } from 'react-admin';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import ResourceGrid from 'components/ResourceGrid';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    const { countriesList, currenciesList } = useRelatedDataContext();

    const countryFormatter = ({ value }) => {
        if (!value) return '';
        return (countriesList.find(c => c.value === value) || {}).label || value;
    }
    const currencyFormatter = ({ value }) => {
        if (!value) return '';
        return (currenciesList.find(c => c.value === value) || {}).label || value;
    }

    return (
        <ResourceGrid
            loading={!countriesList.length || !currenciesList.length}
            customResource='suppliers'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.suppliers.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'externSuppliersId', headerName: translate('resources.suppliers.fields.externalId'), minWidth: 120, editable: true, helpdeskLink: 'https://www.polyblog.io/blog/en/build-a-multi-language-blog-with-react/?gclid=Cj0KCQjwyMiTBhDKARIsAAJ-9Vt14FZPgpd9pkglDAfSILMMonTJCGUtfPeUoZETas8AES5JUv4QbWUaAkkCEALw_wcB' },
                { field: 'suppliersNumber', headerName: translate('resources.suppliers.fields.suppliersNumber'), type: 'number', maxWidth: 80, editable: true },
                { field: 'suppliersName', headerName: translate('resources.suppliers.fields.suppliersName'), minWidth: 120, flex: 1, editable: true },
                { field: 'suppliersCustomerNumber', headerName: translate('resources.suppliers.fields.suppliersCustomerNumber'), minWidth: 140, editable: true },
                { field: 'suppliersStreet', headerName: translate('resources.suppliers.fields.suppliersStreet'), maxWidth: 100, editable: true },
                { field: 'suppliersCity', headerName: translate('resources.suppliers.fields.suppliersCity'), maxWidth: 100, editable: true },
                { field: 'suppliersZip', headerName: translate('resources.suppliers.fields.suppliersZip'), maxWidth: 50, editable: true },
                { field: 'countriesId', headerName: translate('resources.suppliers.fields.countriesId'), minWidth: 200, valueFormatter: countryFormatter, type: 'singleSelect', valueOptions: countriesList, editable: true },
                { field: 'suppliersPhone', headerName: translate('resources.suppliers.fields.suppliersPhone'), maxWidth: 70, editable: true },
                { field: 'suppliersFax', headerName: translate('resources.suppliers.fields.suppliersFax'), maxWidth: 70, editable: true },
                { field: 'suppliersEmail', headerName: translate('resources.suppliers.fields.suppliersEmail'), maxWidth: 70, editable: true },
                { field: 'suppliersEmailOrders', headerName: translate('resources.suppliers.fields.suppliersEmailOrders'), maxWidth: 100, editable: true },
                { field: 'suppliersUrl', headerName: translate('resources.suppliers.fields.suppliersUrl'), maxWidth: 60, editable: true },
                { field: 'currenciesId', headerName: translate('resources.suppliers.fields.currenciesId'), minWidth: 100, valueFormatter: currencyFormatter, type: 'singleSelect', valueOptions: currenciesList, editable: true },
            ]}
        />
    )
}

export const SuppliersList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.suppliers.name')} />
            <Grid />
        </>
    )
}