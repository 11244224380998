export const json2object = (obj) => {
  if (typeof obj !== "object") return;
  const keys = Object.keys(obj);
  for (const key of keys) {
    const value = obj[key];
    if (typeof value === "string") {
      try {
        const o = JSON.parse(value);
        obj[key] = o;
      } catch (err) {
      }
      continue;
    }
    if (value === null || typeof value === "undefined") {
      continue;
    }
    if (typeof value === "object") {
      json2object(value);
      continue;
    }
  }
};
