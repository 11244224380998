import React, { useEffect, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Box, Button, FormControl, FormControlLabel, Switch } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import MuiModal from 'components/MuiModal';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import { Column } from '.';
import { useApi } from 'contexts/useApi';
import MuiTextField from 'components/MuiTextField';
import MuiSelect from 'components/MuiSelect';

interface Props {
    open: boolean;
    onClose: () => void;
    columns: Column[];
    resource: string;
}

const CreateModal = ({
    open = false,
    onClose = () => { },
    columns = [],
    resource,
    cbAfterSubmitCreateModal = () => { },
}: Props) => {
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const { createRecord } = useApi();
    const notify = useNotify();
    const { control, handleSubmit, reset } = useForm();

    const onSubmit = async (formData) => {
        setLoading(true);
        const newRow = { ...formData };
        const afterSubmits: ({ resource: string; idField: string; cellValue: any })[] = [];
        columns.forEach(col => {
            if (col.field !== 'id' && col.type === 'number') {
                newRow[col.field] = Number(newRow[col.field]);
                if (isNaN(newRow[col.field])) delete newRow[col.field];
            }
            if (col.paramsNewCell) {
                if (newRow[col.field]) {
                    afterSubmits.push({
                        resource: col.paramsNewCell.resource,
                        idField: col.paramsNewCell.parentIdField,
                        cellValue: newRow[col.field],
                    });
                }
                delete newRow[col.field];
            }
        });
        const { data, error } = await createRecord(resource, newRow);
        if (!error) {
            for (const a of afterSubmits) {
                const d = {
                    ...a.cellValue,
                    [a.idField]: data.id,
                };
                await createRecord(a.resource, d);
            }
            notify('messages.createSuccess', { type: 'success' });
            cbAfterSubmitCreateModal();
            onClose();
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!open) return;
        reset();
    }, [open, reset]);

    return (
        <>
            <MuiModal
                open={open}
                onClose={onClose}
                title={translate('pos.add_new')}
                formProps={{
                    onSubmit: handleSubmit(onSubmit)
                }}
                dialogContents={
                    <>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {columns.map(column => {
                                if (column.field === 'id') return null;
                                const elementId = "create-modal-input-" + column.field;
                                return (
                                    <Box key={elementId} sx={{ margin: 2, marginTop: 0 }}>
                                        <FormControl variant="standard" fullWidth>
                                            <Controller
                                                id={elementId}
                                                name={column.field}
                                                control={control}
                                                render={({ field }) => {
                                                    const required = column.required === undefined ? true : column.required;
                                                    if (column.renderNewCell) {
                                                        return column.renderNewCell(field, required);
                                                    } else if (column.type === 'boolean') {
                                                        return (
                                                            <FormControlLabel
                                                                control={<Switch {...field} />}
                                                                label={column.headerName}
                                                                labelPlacement="start"
                                                                sx={{ minWidth: `223px !important`, pl: 1, mt: 1, borderRadius: 1, border: theme => `1px solid ${theme.palette.grey[400]}`, marginLeft: 0 }}
                                                            />
                                                        )
                                                    } else if (column.type === 'singleSelect' && column.valueOptions) {
                                                        return (
                                                            <MuiSelect
                                                                {...field}
                                                                label={column.headerName}
                                                                options={column.valueOptions}
                                                                value={field.value || ''}
                                                                onChange={field.onChange}
                                                                required={required}
                                                            />
                                                        )
                                                    } else {
                                                        return (
                                                            <MuiTextField
                                                                {...field}
                                                                label={column.headerName}
                                                                value={field.value || ''}
                                                                onChange={field.onChange}
                                                                required={required}
                                                                type={column.type}
                                                            />
                                                        )
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                )
                            })}
                        </Box>
                        <CircularProgressOverlay active={loading} />
                    </>
                }
                dialogActions={
                    <>
                        <Button variant="outlined" onClick={onClose}>{translate('pos.cancel')}</Button>
                        <Button variant="contained" type="submit">{translate('pos.save')}</Button>
                    </>
                }
            />
        </>
    )
}

export default CreateModal;
