import React, { useMemo } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useTranslate } from "react-admin";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getPriceText } from "./getPriceText";

const TotalCallsPerMonth = ({
  workflowOptions,
  calcUsageCosts,
  getMonthlyUsage,
  isLoading,
  workflowUsageData,
}) => {
  const translate = useTranslate();

  const rows = useMemo(() => {
    if (Object.keys(workflowUsageData).length === 0) return [];
    return workflowOptions.map(({ value, label }, i) => {
      const total_usage = getMonthlyUsage(i);
      const estimated = i === 0 ? translate("bb2.fields.estimated") : "";
      return {
        id: value,
        month_name: label,
        total_usage,
        costs: getPriceText(calcUsageCosts(total_usage), estimated),
      };
    });
  }, [workflowOptions, workflowUsageData, calcUsageCosts, getMonthlyUsage, translate]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{translate("bb2.pages.billing")}</Typography>
          <Typography variant="caption">{translate("bb2.fields.total_api_calls_per_month")}</Typography>
        </Grid>
        <Box sx={{ width: "100%", height: 422, ml: 2, mt: 2 }}>
          <DataGridPro
            columns={[
              { field: "month_name", headerName: translate("bb2.fields.month_name"), minWidth: 150 },
              { field: "total_usage", headerName: translate("bb2.fields.total_usage"), minWidth: 150, align: "right", headerAlign: "right" },
              { field: "costs", headerName: translate("bb2.fields.costs"), flex: 1, align: "right", headerAlign: "right" },
            ]}
            rows={rows}
            disableSelectionOnClick
            loading={isLoading}
          />
        </Box>
      </Grid>
    </Paper>
  );
};

export default TotalCallsPerMonth;
