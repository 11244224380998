import React, { useState } from 'react';
import { useNotify, useTranslate, useUpdate } from 'react-admin';
import { useForm } from 'react-hook-form';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import useLocalStorageState from 'use-local-storage-state';
import { COLORS } from 'theme/theme2';
import { promisify } from 'utils/promisify';
import { getCurrentUser } from 'providers/currentUser';
import PasswordVisible from 'components/PasswordVisible';

const ChangePasswordForm = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const [updateApi] = useUpdate();
    const [selectedThemeKey] = useLocalStorageState("themeKey", { ssr: false, defaultValue: "GREEN" });
    const [isUpdating, setIsUpdating] = useState(false);
    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            newPassword: '',
            confirmPassword: '',
        }
    });

    const onSubmit = async ({ newPassword }) => {
        setIsUpdating(true);
        const currentUser = getCurrentUser();
        await promisify(updateApi, 'bf-users', {
            data: {
                id: currentUser.id,
                password: newPassword,
            },
        }, translate, notify);
        notify('messages.updateSuccess', { type: 'success' });
        setIsUpdating(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    padding: 2,
                    border: theme => `1px solid ${theme.palette.grey[400]}`,
                }}
            >
                <Typography color={COLORS[selectedThemeKey]}>{translate('pos.change_password')}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ padding: '1em 1em 1em 1em' }}>
                        <PasswordVisible
                            name="newPassword"
                            label={translate('pos.new_password')}
                            control={control}
                            errors={errors}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ padding: '1em 1em 1em 1em' }}>
                        <PasswordVisible
                            name="confirmPassword"
                            label={translate('pos.confirm_password')}
                            control={control}
                            errors={errors}
                            rules={{ validate: value => value === watch('newPassword') }}
                        />
                    </Box>
                </Box>
                <Box sx={{ padding: '1em 1em 1em 1em', width: 235 }}>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isUpdating}
                        fullWidth
                    >
                        {isUpdating && <CircularProgress size={25} thickness={2} />}
                        {translate('pos.change_password')}
                    </Button>
                </Box>
            </Box>
        </form>
    )
}

export default ChangePasswordForm;
