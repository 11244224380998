/**
 * @param {object[]} arr 
 * @param {string} objKey 
 * @param {'string' | 'number'} valueType 
 * @returns {object[]}
 */
export const sortObjectArray = (arr, objKey, valueType = 'string') => {
    if (!arr || !arr.length) return arr;
    arr.sort((a, b) => {
        const aValue = a[objKey];
        const bValue = b[objKey];
        if (valueType === 'number') {
            return bValue - aValue;
        }
        if (aValue.toLowerCase() > bValue.toLowerCase()) return 1;
        if (aValue.toLowerCase() < bValue.toLowerCase()) return -1;
        return 0;
    });
    return arr;
}
