import { onError } from "./errors";

export const promisify = (func, resource, params, translate, notify) => {
    return new Promise((resolve, reject) => {
        func(resource, params, {
            onSuccess: (data) => resolve(data),
            onError: (error) => {
                onError(error, translate, notify);
                reject(error);
            },
        });
    })
}
