import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Title, useNotify, useTranslate } from "react-admin";
import { Button, Card, CardContent, CardHeader, Grid, Paper, Typography } from "@mui/material";
import MuiSelect from "components/MuiSelect";
import MuiTimeline from "components/MuiTimeline";
import { bb2api } from "../bb2-api";
import { useBB2MandantContext } from "../BB2MandantContext";
import { theme_data } from "theme/theme2";
import MandantPicker from "../MandantPicker";
  
const BB2Workflow = () => {
  // Check authentication
  const navigate = useNavigate();
  const { isAuthed, mandant } = useBB2MandantContext();
  useEffect(() => {
    if (isAuthed) return;
    navigate('/bb2/login', { state: { target: "workflow" } });
    // eslint-disable-next-line
  }, [isAuthed]);

  const translate = useTranslate();
  const notify = useNotify();
  const [workflowKey, setWorkflowKey] = useState();
  const workflow = useMemo(() => {
    if (!mandant) return null;
    if (!workflowKey) return null;
    return mandant.workflows.find((w) => w.name === workflowKey);
  }, [mandant, workflowKey]);

  const [queueId, setQueueId] = useState();
  const handleStartWorkflow = async () => {
    const { data, error } = await bb2api.postWorkflowRun(workflow.workflow);
    if (error) {
      notify(error.message, { type: "error" });
      return;
    }
    setQueueId(data);
    setLastStatus();
  }
  const [lastStatus, setLastStatus] = useState();
  const { data: workflowStatus } = useQuery(["workflow-status", mandant, queueId, lastStatus], async () => {
    if (lastStatus) return lastStatus;
    if (!queueId) return null;
    const { data, error } = await bb2api.getWorkflowStatus(mandant.customerTechnicalName, queueId);
    if (error) {
      notify(error.message, { type: "error" });
      return null;
    }
    const status = {};
    (data?.entries || []).forEach(({ route, startDateTime, endDateTime, success, finished }) => {
      status[route] = { route, startDateTime, endDateTime, success, finished };
    });
    if (data?.finished) {
      setLastStatus(status);
    }
    return status;
  }, {
    refetchInterval: (queueId && !lastStatus) ? 1000 : undefined,
  });

  const calcColor = (route) => {
    if (!workflowStatus) return "primary";
    const { startDateTime, endDateTime, success, finished } = workflowStatus[route] || {};
    if (finished) {
      if (success) return "success";
      else return "error";
    }
    if (!startDateTime && !endDateTime) return "primary";
    if (startDateTime) return "info";
  }

  if (!mandant) return null;

  return (
    <>
      <MandantPicker sx={{ mb: 2 }} />
      <Paper sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Title title="bb2.pages.workflow" />
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Button variant="contained" disabled={!workflowKey || !!queueId} onClick={handleStartWorkflow}>
              {translate("bb2.fields.start_workflow")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <MuiSelect
              options={mandant?.workflows?.map(({ name }) => ({ value: name, label: name }))}
              value={workflowKey}
              onChange={(e) => {
                setWorkflowKey(e.target.value);
                setQueueId();
                setLastStatus();
              }}
            />
          </Grid>
          {workflow && (
            <>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography variant="h6">{workflow.workflow.customer}</Typography>
                <Typography>{workflow.workflow.name}</Typography>
                <Typography>{workflow.workflow.description}</Typography>
                {queueId && (<Typography sx={{fontSize: 14, marginTop: 2}}>Queue ID: {queueId}</Typography>)}
              </Grid>
              <Grid item xs={12}>
                <MuiTimeline
                  entries={workflow.workflow.entries.map(({ route, description, service }) => ({
                    left: route,
                    right: (
                      <Card sx={{ height: 128, alignItems: "flex-start" }}>
                        <CardHeader
                          title={<Typography sx={{ fontWeight: "bold" }}>{description}</Typography>}
                          subheader={<Typography sx={{ color: theme_data.formLabelColor, fontSize: 12 }}>{"Service: " + service}</Typography>}
                        />
                        {workflowStatus && (
                          <CardContent sx={{ padding: 0, paddingLeft: 2 }}>
                            {workflowStatus[route]?.startDateTime && (
                              <Typography sx={{ fontSize: 12 }}>{`Start: ${workflowStatus[route].startDateTime}`}</Typography>
                            )}
                            {workflowStatus[route]?.endDateTime && (
                              <Typography sx={{ fontSize: 12 }}>{`Ende: ${workflowStatus[route].endDateTime}`}</Typography>
                            )}
                          </CardContent>
                        )}
                      </Card>
                    ),
                    dotColor: calcColor(route),
                  }))}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default BB2Workflow;
