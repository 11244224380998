import React from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import { validationInt } from 'components/MuiGrid/validations';
import AssociationEditModal from 'components/ResourceGrid/AssociationEditModal';
import NewAssociationFields from 'components/ResourceGrid/NewAssociationFields';
import { useApi } from 'contexts/useApi';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    const { languagesList, ordersHoldStatusList } = useRelatedDataContext();
    const { getValueBasedOnLanguages } = useApi();

    const languageFormatter = ({ value }) => {
        if (!value) return '';
        return (languagesList.find(l => l.value === value) || {}).label || value;
    }

    const ordersHoldStatusFormatter = ({ value }) => {
        if (!value) return '';
        return (ordersHoldStatusList.find(c => c.value === value) || {}).label || value;
    }

    return (
        <ResourceGrid
            loading={!ordersHoldStatusList.length}
            customResource='shipping-methods'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            extraParams={{
                associations: {
                    shippingMethodsDescription: {},
                }
            }}
            columns={[
                { field: 'id', headerName: translate('resources.shipping_methods.fields.id'), maxWidth: 20, type: 'number', hide: true },
                {
                    field: 'shippingMethodsDescription',
                    headerName: translate('resources.shipping_methods_description.fields.name'),
                    minWidth: 140,
                    flex: 1,
                    sortField: 'shippingMethodsDescription.name',
                    editable: true,
                    valueFormatter: ({ value }) => getValueBasedOnLanguages(Object.values(value || {}), 'name'),
                    renderEditCell: (params) => (
                        <AssociationEditModal
                            {...params}
                            associationColumns={[
                                { field: 'id', headerName: translate('resources.shipping_methods_description.fields.id'), hide: true },
                                { field: 'languagesId', headerName: translate('resources.shipping_methods_description.fields.languagesId'), minWidth: 140, sortable: false, valueFormatter: languageFormatter, type: 'singleSelect', valueOptions: languagesList, editable: true },
                                { field: 'name', headerName: translate('resources.shipping_methods_description.fields.name'), minWidth: 200, flex: 1, editable: true },
                            ]}
                            associationResource='shipping-methods-description'
                            parentIdField='shippingMethodsId'
                            modalTitle={translate("resources.shipping_methods_description.name")}
                        />
                    ),
                    renderNewCell: (field, required) => (
                        <NewAssociationFields
                            field={field}
                            required={required}
                            associationFields={[
                                { configurationKey: 'languagesId', label: translate('resources.shipping_methods_description.fields.languagesId'), type: 'singleSelect', valueFromRelated: 'languagesList' },
                                { configurationKey: 'name', label: translate('resources.shipping_methods_description.fields.name') },
                            ]}
                        />
                    ),
                    paramsNewCell: { resource: 'shipping-methods-description', parentIdField: 'shippingMethodsId' },
                },
                { field: 'shippingMethodsCode', headerName: translate('resources.shipping_methods.fields.shippingMethodsCode'), minWidth: 160, editable: true },
                { field: 'ordersHoldStatusId', headerName: translate('resources.shipping_methods.fields.ordersHoldStatusId'), minWidth: 190, valueFormatter: ordersHoldStatusFormatter, type: 'singleSelect', valueOptions: ordersHoldStatusList, editable: true },
                { field: 'sortOrder', headerName: translate('resources.shipping_methods.fields.sortOrder'), maxWidth: 60, type: 'number', preProcessEditCellProps: validationInt, editable: true },
            ]}
            keepFieldsOnUpdate={['shippingMethodsDescription']}
        />
    )
}

export const ShippingMethodsList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.shipping_methods.name')} />
            <Grid />
        </>
    )
}
