import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import MuiTextField from "components/MuiTextField";
import { InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

interface Props {
  label?: string;
  placeholder?: string;
  debounce?: number;
  onChange: (q) => void;
  autoFocus?: boolean;
  iconPosition?: "right" | "left";
  sx?: {};
}

const MuiSearch = ({
  label,
  placeholder = "",
  debounce = 700,
  onChange = (q) => {},
  autoFocus = false,
  iconPosition = "right",
  sx = {}
}: Props) => {
  const [text, setText] = useState("");
  const [term] = useDebounce(text, debounce);

  useEffect(() => {
    onChange(term);
    // eslint-disable-next-line
  }, [term]);

  const handleKeyUp = (e) => {
    if (e.code === "Enter") {
      onChange(text);
    }
  };

  return (
    <MuiTextField
      label={label}
      placeholder={placeholder}
      autoFocus={autoFocus}
      fullWidth
      InputProps={{
        startAdornment: iconPosition === "left" && (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: iconPosition === "right" && (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={text}
      onChange={(e) => setText(e.target.value)}
      onKeyUp={handleKeyUp}
      inputProps={{
        "data-cy": "search",
      }}
      sx={sx}
    />
  );
};

export default MuiSearch;
