import React, { useMemo } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useTranslate } from "react-admin";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getPriceText } from "./getPriceText";

const BillingPlan = ({ billingPlans = [] }) => {
  const translate = useTranslate();

  const rows = useMemo(() => {
    return billingPlans.map((plan) => ({
      ...plan,
      costs: getPriceText(plan.costs),
    }));
  }, [billingPlans]);

  return (
    <Paper sx={{ mb: 2, p: 2 }}>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{translate("bb2.fields.billing_plan")}</Typography>
          <Typography variant="caption">{translate("bb2.fields.your_personal_billing_plan")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGridPro
            columns={[
              { field: "range", headerName: translate("bb2.fields.api_call_range"), flex: 1 },
              { field: "costs", headerName: translate("bb2.fields.price"), minWidth: 150, align: "right", headerAlign: "right" },
            ]}
            rows={rows}
            disableSelectionOnClick
            loading={false}
            autoHeight
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BillingPlan;
