import { Button, ButtonProps } from "react-admin";
import { Save } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { spacing } from "theme/theme2";

const useStyles = makeStyles(() => ({
  button: {
    "& .MuiSvgIcon-root, & .MuiIcon-root, & .MuiCircularProgress-root": {
      marginRight: spacing(1),
    },
    "& .MuiSvgIcon-root, & .MuiIcon-root": {
      fontSize: 18,
    },
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.75,
    textTransform: "uppercase",
    padding: "6px 16px",
  },
  startIcon: {
    marginRight: 0,
    marginLeft: "-4px",
  },
  noStartIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  endIcon: {
    marginRight: "-12px",
  },
  noEndIcon: {
    marginRight: 0,
  },
}));

const defaultIcon = <Save />;

interface Props {
  startIcon?: React.ReactNode | undefined | null;
  loading?: boolean;
  isTopbarButton?: boolean;
}

const RaButton = ({
  startIcon,
  endIcon,
  loading = false,
  isTopbarButton = false,
  variant = "contained",
  size = "medium",
  className = "",
  classes = {},
  disabled = false,
  sx = {},
  children,
  ...rest
}: ButtonProps & Props) => {
  const icon = typeof startIcon === "undefined" ? defaultIcon : startIcon;
  const myClasses = useStyles();
  let sxObj = sx;
  if (isTopbarButton) {
    sxObj = {
      paddingTop: "4px !important",
      paddingBottom: "4px !important",
      ...sx,
    };
  }
  return (
    <Button
      variant={variant}
      size={size}
      className={`${myClasses.button} ${className}`}
      classes={{
        startIcon: !!icon ? myClasses.startIcon : myClasses.noStartIcon,
        endIcon: !!endIcon ? myClasses.endIcon : myClasses.noEndIcon,
        ...classes,
      }}
      disabled={disabled || loading}
      endIcon={endIcon}
      sx={sxObj}
      {...rest}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : icon}
      {children}
    </Button>
  );
};

export default RaButton;
