import { GridFilterModel, GridFilterOperator } from '@mui/x-data-grid-pro';
import { Column, SingleSelectType } from 'components/MuiGrid';
import { Sort } from './SortUtils';

export type FilterType = 'equals' | 'unEquals' | 'likeAsterisk' | 'likeBeginning' | 'likeEnding' | 'like' | 'between' | 'greaterEqualsThan' | 'greaterThan' | 'lessEqualsThan' | 'lessThan' | 'in' | 'notIn' | 'inFilter' | 'multi';
export type ColumnType = 'string' | 'number' | 'singleSelect';

export interface Filter {
    field: string;
    type: FilterType;
    value: any | null;
    operator?: 'or' | 'and';    // when type is `multi`
    filters?: Filter[];         // when type is `multi`
}

export interface Params {
    limit: number;
    page: number;
    associations: {
        [resource_name]: {};
    };
    filter: Filter[];
    sort: Sort;
    "total-count-mode": 1 | 0;
}

export const buildOneFilter = (columns: Column[], columnField: string, operatorValue: GridFilterOperator, value: any) => {
    const column = columns.find(col => col.field === columnField);
    const columnType: ColumnType = column.type || 'string';
    const singleSelectType: SingleSelectType = column.singleSelectType;
    let type: FilterType = 'equals';
    switch (operatorValue) {
        /* number operators */
        case '=':
        case 'is':
        case 'equals':
            type = 'equals';
            break;
        case '!=':
        case 'isNot':
            type = 'unEquals';
            break;
        case '>':
            type = 'greaterThan';
            break;
        case '>=':
            type = 'greaterEqualsThan';
            break;
        case '<':
            type = 'lessThan';
            break;
        case '<=':
            type = 'lessEqualsThan';
            break;
        /* string operators */
        case 'contains':
            type = 'like';
            break;
        case 'startsWith':
            type = 'likeBeginning';
            break;
        case 'endsWith':
            type = 'likeEnding';
            break;
        /* common operators */
        case 'isEmpty':
            type = 'equals';
            value = null;
            break;
        case 'isNotEmpty':
            type = 'unEquals';
            value = null;
            break;
        case 'isAnyOf':
            type = 'in';
            break;
        default:
            break;
    }
    if (columnType === 'number' && value !== undefined) {
        value = Number(value);
    }
    if (columnType === 'singleSelect' && Array.isArray(value) && singleSelectType !== 'string') {
        value = value.map(v => Number(value));
    }
    if (value !== undefined) {
        const oneFilter: Filter = {
            field: columnField,
            type,
            value,
        }
        return oneFilter;
    }
    return null;
}

export const buildFilters = (columns: Column[], model: GridFilterModel) => {
    const { items, linkOperator } = model;
    const filters: Filter[] = [];
    items.forEach(({ columnField, operatorValue, value }) => {
        const f = buildOneFilter(columns, columnField, operatorValue, value);
        if (f) filters.push(f);
    });

    if (filters.length === 0) return [];
    if (linkOperator === 'or') {
        return [{
            type: 'multi',
            operator: 'or',
            filters,
        }];
    }
    return filters;
}
