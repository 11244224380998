import jwtDecode from "jwt-decode";
import { readCookie } from "utils/cookie";

export const isAuthenticated = () => {
  const token = readCookie();
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp && typeof decodedToken.exp === "number") {
      if (Date.now() < decodedToken.exp * 1000) {
        return true;
      }
    }
  }

  return false;
};
