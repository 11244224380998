import React, { useEffect } from 'react';
import { Layout, LayoutProps, useNotify, useRedirect, useTranslate } from 'react-admin';
import CustomAppBar from './AppBar';
import CustomMenu from './NavDrawer/Menu';
import { ReactQueryDevtools } from "react-query/devtools";
import { usePrompt } from 'contexts/usePrompt';
import { useDirtyContext } from 'contexts/DirtyContext';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import { useEntityAccess } from 'contexts/EntityAccessContextContainer.js';

const AppLayout = (props: LayoutProps) => {
    const translate = useTranslate();
    const redirect = useRedirect();
    const notify = useNotify();
    const { bb2Config: { error } } = useEntityAccess();
    const { configurationObj } = useRelatedDataContext();
    const { isDirty, setIsDirty } = useDirtyContext();
    const dirtyConfig = (configurationObj.dirtyCheckDisabled || {}).configurationValue;
    const dirtyCheckDisabled = (dirtyConfig === '1' || dirtyConfig === 'true');

    useEffect(() => {
        if (!error) return;
        localStorage.removeItem("token");
        localStorage.removeItem("bb2-token");
        redirect("/login");
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        setIsDirty(false);
        // eslint-disable-next-line
    }, [dirtyCheckDisabled]);

    usePrompt(translate('pos.prompts.dirty'), !dirtyCheckDisabled && isDirty);

    const { fetchAndSetMenuItems } = useEntityAccess();
    const fetchAll = async () => {
        const { error } = await fetchAndSetMenuItems();
        if (error && error.response.status === 401) {
            localStorage.removeItem("token");
            redirect("/login");
        } else {
            if (error){
                notify(error.message ?? '', { type: "error" });
            }
        }
    };
    useEffect(() => {
        fetchAll();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Layout
                {...props}
                appBar={CustomAppBar}
                menu={CustomMenu}
            />
            <ReactQueryDevtools initialIsOpen={false} />
        </>
    )
}

export default AppLayout;
