import moment from 'moment';
import { getCurrentUser } from 'providers/currentUser';
import simpleRestProvider from 'ra-data-simple-rest';
import { defaultDateTimeFormat } from 'utils/time-format';
import { httpClient } from '../auth/httpClient';
import { Params } from './FilterUtils';

const apiUrl = JSON.parse(localStorage.getItem("bb2Config"))?.apiUrl;
const BASE_URI = apiUrl ? apiUrl + "/api" : process.env.REACT_APP_API_URL;
const SEARCH = window.Cypress ? window.Cypress.env('REACT_APP_API_SEARCH_ENDPOINT') : process.env.REACT_APP_API_SEARCH_ENDPOINT;

const dataProvider = simpleRestProvider(BASE_URI, httpClient);

// const handleQuery = (params) => {
//     const query = { sort: params.sort };
//     if (params.sort) query.sort.direction = query.sort.order;

//     if(params.hasOwnProperty('filter')) {
//         if (Object.keys(params.filter).length > 0) {
//             const filter = prepareFilter(params.filter);

//             if(filter.length > 0) {
//                 query.filter = prepareFilter(params.filter);
//             }
//         }
//     }

//     if (params.pagination?.page) query.page = params.pagination.page;
//     if (params.pagination?.perPage) query.limit = params.pagination.perPage;

//     return query;
// };

// const prepareFilter = (filter) => {
//     const preparedFilter = [];

//     Object.keys(filter).forEach(function(key) {
//         if(filter[key] !== '') {
//             preparedFilter.push(
//                 {
//                     'type': 'likeAsterisk',
//                     'field': key,
//                     'value': '*' + filter[key] + '*'
//                 }
//             );
//         }
//     });

//     return preparedFilter;
// };

const bfDataProvider = {
    ...dataProvider,
    getList: (resource, params: Params) => {
        const query: Params = { ...params };

        // Remove react-admin's default options
        if (!query.sort?.direction) delete query.sort;
        if (!Array.isArray(query.filter)) delete query.filter;
        if (!query.filter?.length) delete query.filter;
        if (query.pagination) delete query.pagination;

        const url = `${BASE_URI}/${SEARCH}/${resource}`;
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(query),
        }).then(({ json }) => {
            return {
                data: json.data,
                total: parseInt(json.total, 10),
            }
        });
    },
    getOne: (resource, params: { id: number }) => {
        return httpClient(`${BASE_URI}/${resource}/${params.id}`).then(({ json }) => ({
            data: json.data,
        }))
    },
    // getMany: (resource, params: Params) => {
    //     const query = {
    //         filter: JSON.stringify({ ids: params.ids }),
    //     };
    //     const url = `${BASE_URI}/${SEARCH}/${resource}`;
    //     return httpClient(url, {
    //         method: 'POST',
    //         body: JSON.stringify(query),
    //     }).then(({ json }) => ({
    //         data: json.data,
    //         total: parseInt(json.total, 10),
    //     }));
    // },
    update: (resource, params: any) => {
        const now = moment(new Date()).utc().format(defaultDateTimeFormat);
        const userId = getCurrentUser().id;
        return httpClient(`${BASE_URI}/${resource}`, {
            method: 'PATCH',
            body: JSON.stringify({
                ...params.data,
                lastUpdate: now,
                lastUpdater: userId,
            }),
        }).then(({ json }) => ({
            data: {
                ...json.data,
                lastUpdate: now,
                lastUpdater: userId,
            },
        }));
    },
    create: (resource, params: any) => {
        const now = moment(new Date()).utc().format(defaultDateTimeFormat);
        const userId = getCurrentUser().id;
        return httpClient(`${BASE_URI}/${resource}`, {
            method: 'POST',
            body: JSON.stringify({
                ...params.data,
                id: undefined,
                dateInsert: now,
                lastUpdate: now,
                lastUpdater: userId,
            }),
        }).then(({ json }) => ({
            data: {
                ...params.data,
                id: json.data.id,
                dateInsert: now,
                lastUpdate: now,
                lastUpdater: userId,
            },
        }))
    },
    delete: (resource, params: { id: number }) => {
        return httpClient(`${BASE_URI}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({
            data: json,
        }))
    },
};

export default bfDataProvider;