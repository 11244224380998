export const staticMenuItems = [
  {
    id: "bb2",
    level: 0,
    menuUrl: null,
    parent: "",
    sortOrder: 0,
    icon: "FoundationIcon",
    descriptions: [
      {
        name: "brickfox Bridge 2",
        windowTitle: "brickfox Bridge 2",
        language: { code: "en" },
      },
      {
        name: "brickfox Bridge 2",
        windowTitle: "brickfox Bridge 2",
        language: { code: "de" },
      },
    ],
    children: [
      {
        id: "bb2.pages.overview",
        level: 1,
        parent: "bb2",
        sortOrder: 1,
        icon: "DashboardIcon",
        children: [],
        descriptions: [
          {
            name: "Overview",
            windowTitle: "Overview",
            language: { code: "en" },
          },
          {
            name: "Übersicht",
            windowTitle: "Übersicht",
            language: { code: "de" },
          },
        ],
        menuUrl: { icon: "DashboardCustomizeIcon", target: "_self", url: "/bb2/overview" },
      },
      {
        id: "bb2.pages.workflow",
        level: 1,
        parent: "bb2",
        sortOrder: 2,
        icon: "AccountTree",
        children: [],
        descriptions: [
          {
            name: "Workflow",
            windowTitle: "Workflow",
            language: { code: "en" },
          },
          {
            name: "Workflow",
            windowTitle: "Workflow",
            language: { code: "de" },
          },
        ],
        menuUrl: { icon: "AirIcon", target: "_self", url: "/bb2/workflow" },
      },
      {
        id: "bb2.pages.logs",
        level: 1,
        parent: "bb2",
        sortOrder: 3,
        icon: "SummarizeIcon",
        children: [],
        descriptions: [
          {
            name: "Logs",
            windowTitle: "Logs",
            language: { code: "en" },
          },
          {
            name: "Logs",
            windowTitle: "Logs",
            language: { code: "de" },
          },
        ],
        menuUrl: { icon: "HistoryIcon", target: "_self", url: "/bb2/logs" },
      },
      {
        id: "bb2.pages.billing",
        level: 1,
        parent: "bb2",
        sortOrder: 4,
        icon: "ReceiptIcon",
        children: [],
        descriptions: [
          {
            name: "Billing",
            windowTitle: "Billing",
            language: { code: "en" },
          },
          {
            name: "Rechnungen",
            windowTitle: "Rechnungen",
            language: { code: "de" },
          },
        ],
        menuUrl: { icon: "ReceiptLongIcon", target: "_self", url: "/bb2/billing" },
      },
    ],
  },
  {
    name: "resources.content_generation.name",
    icon: "Settings",
    url: "/content-generation",
    descriptions: [
      {
        name: "Content Generation",
        windowTitle: "Content Generation",
        language: { code: "en" },
      },
      {
        name: "Inhalt generierung",
        windowTitle: "Inhalt generierung",
        language: { code: "de" },
      },
    ],
  },
  // {
  //   id: "pims",
  //   name: "pos.pim.name",
  //   icon: "ShareIcon",
  //   ChildMenuComponent: PimMenu,
  //   children: [],
  // },
];
