import React from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

interface Props {
  entries: {
    left: string,
    right: React.ReactNode,
    dotColor: "inherit" | "grey" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
  }[];
}

const MuiTimeline = ({ entries = [] }: Props) => {
  return (
    <Timeline position="left">
      {entries.map(({ left, right, dotColor = "primary" }) => (
        <TimelineItem key={left}>
          <TimelineOppositeContent>{right}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={dotColor} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ maxWidth: 250, fontSize: 13, textTransform: "uppercase" }}>{left}</TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default MuiTimeline;
