import React, { useEffect, useState } from 'react';
import { Title, useTranslate } from 'react-admin';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import ResourceGrid from 'components/ResourceGrid';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    const { countriesList: orgCountriesList } = useRelatedDataContext();
    const [countriesList, setCountriesList] = useState([]);

    useEffect(() => {
        if (!orgCountriesList.length) return;
        const _countriesList = orgCountriesList.map(c => ({ ...c, value: c.label2 }));
        setCountriesList(_countriesList);
    }, [orgCountriesList]);

    return (
        <ResourceGrid
            loading={!orgCountriesList.length}
            customResource='stock-locations'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.stock_locations.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'externStockLocationsId', headerName: translate('resources.stock_locations.fields.externStockLocationsId'), maxWidth: 100, editable: true },
                { field: 'stockLocationsName', headerName: translate('resources.stock_locations.fields.stockLocationsName'), minWidth: 100, flex: 1, editable: true },
                { field: 'stockLocationsAddress', headerName: translate('resources.stock_locations.fields.stockLocationsAddress'), minWidth: 100, editable: true },
                { field: 'stockLocationsCity', headerName: translate('resources.stock_locations.fields.stockLocationsCity'), minWidth: 100, editable: true },
                { field: 'stockLocationsZip', headerName: translate('resources.stock_locations.fields.stockLocationsZip'), minWidth: 80, editable: true },
                { field: 'stockLocationsCountry', headerName: translate('resources.stock_locations.fields.stockLocationsCountry'), minWidth: 180, type: 'singleSelect', valueOptions: countriesList, editable: true },
            ]}
        />
    )
}

export const StockLocationsList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.stock_locations.name')} />
            <Grid />
        </>
    )
}
