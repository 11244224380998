const initialState = {
    "cognitoUser": null,
    "cognitoUserAttributes": null,
    "cognitoRequiredAttributes": null
};

const awsCognitoReducer = (
        state,
       action,
) => {
    if (typeof state === 'undefined') {
        state = initialState;
    }

    switch (action.type) {
        case 'SET_COGNITO_USER':
            return {
                ...state,
                "cognitoUser": action.payload
            }
        case 'SET_COGNITO_USER_ATTRIBUTES':
            return {
                ...state,
                "cognitoUserAttributes": action.payload
            }
        case 'SET_COGNITO_REQUIRED_ATTRIBUTES':
            return {
                ...state,
                "cognitoRequiredAttributes": action.payload
            }
        default:
            return state;
    }
};

export { awsCognitoReducer }