import React, { useMemo } from "react";
import { Page } from "layout2/Page";
import IFrame from "components/IFrame";
import { useLocaleState } from "react-admin";
import { useEntityAccess } from "contexts/EntityAccessContextContainer.js";

const IFramePage = ({ url, iframeUrl, id }) => {
  const [locale] = useLocaleState();
  const { menuItems, findMenuItem } = useEntityAccess();

  const title = useMemo(() => {
    const item = findMenuItem(menuItems, id ? null : url, id);
    if (!item) return "";
    const desc =
      item.descriptions.find((d) => d.language.code === locale) ||
      item.descriptions[0] ||
      {};
    return desc.name || "";
    // eslint-disable-next-line
  }, [menuItems, locale, url]);

  return (
    <Page title={title} fullHeight>
      <IFrame path={iframeUrl} />
    </Page>
  );
};

export default IFramePage;
