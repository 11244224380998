import React from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import AssociationEditModal from 'components/ResourceGrid/AssociationEditModal';
import NewAssociationFields from 'components/ResourceGrid/NewAssociationFields';
import { useApi } from 'contexts/useApi';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    const { languagesList, ordersHoldStatusList, paymentProvidersList } = useRelatedDataContext();
    const { getValueBasedOnLanguages } = useApi();

    const languageFormatter = ({ value }) => {
        if (!value) return '';
        return (languagesList.find(l => l.value === value) || {}).label || value;
    }
    const ordersHoldStatusFormatter = ({ value }) => {
        if (!value) return '';
        return (ordersHoldStatusList.find(c => c.value === value) || {}).label || value;
    }
    const paymentProviderFormatter = ({ value }) => {
        if (!value) return '';
        return (paymentProvidersList.find(c => c.value === value) || {}).label || value;
    }

    return (
        <ResourceGrid
            loading={!ordersHoldStatusList.length || !paymentProvidersList.length}
            customResource='payment-methods'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            extraParams={{
                associations: {
                    paymentMethodsDescription: {},
                }
            }}
            columns={[
                { field: 'id', headerName: translate('resources.payment_methods.fields.id'), maxWidth: 20, type: 'number', hide: true },
                {
                    field: 'paymentMethodsDescription',
                    headerName: translate('resources.payment_methods_description.fields.paymentMethodsName'),
                    minWidth: 140,
                    flex: 1,
                    sortField: 'paymentMethodsDescription.paymentMethodsName',
                    editable: true,
                    valueFormatter: ({ value }) => getValueBasedOnLanguages(Object.values(value || {}), 'paymentMethodsName'),
                    renderEditCell: (params) => (
                        <AssociationEditModal
                            {...params}
                            associationColumns={[
                                { field: 'id', headerName: translate('resources.payment_methods_description.fields.id'), hide: true },
                                { field: 'languagesId', headerName: translate('resources.payment_methods_description.fields.languagesId'), minWidth: 140, sortable: false, valueFormatter: languageFormatter, type: 'singleSelect', valueOptions: languagesList, editable: true },
                                { field: 'paymentMethodsName', headerName: translate('resources.payment_methods_description.fields.paymentMethodsName'), minWidth: 200, flex: 1, editable: true },
                            ]}
                            associationResource='payment-methods-description'
                            parentIdField='paymentMethodsId'
                            modalTitle={translate("resources.payment_methods_description.name")}
                        />
                    ),
                    renderNewCell: (field, required) => (
                        <NewAssociationFields
                            field={field}
                            required={required}
                            associationFields={[
                                { configurationKey: 'languagesId', label: translate('resources.payment_methods_description.fields.languagesId'), type: 'singleSelect', valueFromRelated: 'languagesList' },
                                { configurationKey: 'paymentMethodsName', label: translate('resources.payment_methods_description.fields.paymentMethodsName') },
                            ]}
                        />
                    ),
                    paramsNewCell: { resource: 'payment-methods-description', parentIdField: 'paymentMethodsId' },
                },
                { field: 'paymentMethodsCode', headerName: translate('resources.payment_methods.fields.paymentMethodsCode'), minWidth: 160, editable: true },
                { field: 'ordersHoldStatusId', headerName: translate('resources.payment_methods.fields.ordersHoldStatusId'), minWidth: 190, valueFormatter: ordersHoldStatusFormatter, type: 'singleSelect', valueOptions: ordersHoldStatusList, editable: true },
                { field: 'paymentProvidersId', headerName: translate('resources.payment_methods.fields.paymentProvidersId'), minWidth: 190, valueFormatter: paymentProviderFormatter, type: 'singleSelect', valueOptions: paymentProvidersList, editable: true },
                { field: 'paymentClass', headerName: translate('resources.payment_methods.fields.paymentClass'), minWidth: 160, editable: true },
            ]}
            keepFieldsOnUpdate={['paymentMethodsDescription']}
        />
    )
}

export const PaymentMethodsList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.payment_methods.name')} />
            <Grid />
        </>
    )
}
