import React, { ComponentType, createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUser } from "providers/currentUser";
import { useApi } from "./useApi";
import { GridColumnVisibilityModel, GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import { Grid as ProductsGrid } from 'pages/products-by-group';
import { Grid as SuppliersGrid } from 'pages/suppliers';
import { Grid as CountriesGrid } from 'pages/countries';
import { Grid as LanguagesGrid } from 'pages/languages';
import { Grid as CurrenciesGrid } from 'pages/currencies';
import { Grid as TaxRatesGrid } from 'pages/taxRates';
import { Grid as StockLocationsGrid } from 'pages/stockLocations';
import { Grid as CarriersGrid } from 'pages/carriers';
import { Grid as UnitsGrid } from 'pages/units';
import { Grid as CouponsReduceTypesGrid } from 'pages/coupons-reduce-types';
import { Grid as MediaCategoriesGrid } from 'pages/media-categories';
import { Grid as DeliveryTimesGrid } from 'pages/delivery-times';
import { Grid as ShippingMethodsGrid } from 'pages/shipping-methods';
import { Grid as PaymentMethodsGrid } from 'pages/payment-methods';
import { Grid as ProductsClassificationGrid } from 'pages/products-classification';
import { Grid as ProductsConditionsGrid } from 'pages/products-conditions';
import { Grid as BfUsersGrid } from 'pages/bf-users';
import { Grid as BfGroupsGrid } from 'pages/bf-groups';

export const resource = 'bf-users-views';

export interface BfViewModels {
    columnModel: GridColumnVisibilityModel;
    sortModel: GridSortModel;
    filterModel: GridFilterModel;
}

export interface BfUserView {
    id: number;
    bfUsersId: number;
    bfUsers: {
        firstName: string;
        lastName: string;
        userName: string;
        email: string;
    };
    bfView: string | BfViewModels;
    bfViewIdentifier: string;
    bfViewPageSize: number;
    lastUpdater: number;
    Component: ComponentType<any>;
}

export interface GridConfig {
    title: string;
    models: {
        columnModel: columnModel;
        sortModel: GridSortModel;
        filterModel: GridFilterModel;
    },
    pageSize: Number;
}

interface ResourceViews {
    [resource: string]: BfUserView;
}

const getEmptyConf = (resource, Component) => ({
    id: uuidv4(),
    bfUsers: {},
    bfViewIdentifier: resource,
    bfView: {},
    bfViewPageSize: 0,
    Component,
    label: resource,
    value: resource,
});

export const knownGridResources: ResourceViews = {
    countries: getEmptyConf('countries', CountriesGrid),
    languages: getEmptyConf('languages', LanguagesGrid),
    currencies: getEmptyConf('currencies', CurrenciesGrid),
    units: getEmptyConf('units', UnitsGrid),
    products: getEmptyConf('products', ProductsGrid),
    suppliers: getEmptyConf('suppliers', SuppliersGrid),
    "tax-rates": getEmptyConf('tax-rates', TaxRatesGrid),
    "stock-locations": getEmptyConf('stock-locations', StockLocationsGrid),
    carriers: getEmptyConf('carriers', CarriersGrid),
    "coupons-reduce-types": getEmptyConf('coupons-reduce-types', CouponsReduceTypesGrid),
    "media-categories": getEmptyConf('media-categories', MediaCategoriesGrid),
    "delivery-times": getEmptyConf('delivery-times', DeliveryTimesGrid),
    "shipping-methods": getEmptyConf('shipping-methods', ShippingMethodsGrid),
    "payment-methods": getEmptyConf('payment-methods', PaymentMethodsGrid),
    "products-classification": getEmptyConf('products-classification', ProductsClassificationGrid),
    "products-conditions": getEmptyConf('products-conditions', ProductsConditionsGrid),
    "bf-users": getEmptyConf('bf-users', BfUsersGrid),
    "bf-groups": getEmptyConf('bf-groups', BfGroupsGrid),
}

const UserViewsContext = createContext({
    userViews: [],
    fetching: false,
    getGridConfigs: (resource1: string, configId): GridConfig[] => ({ models: undefined, pageSize: undefined }),
    saveOneGridConf: (resource1: string, id: string, title: string = '', users: any[] = [], models: BfViewModels, pageSize: number) => ({ data: undefined, error: undefined }),
    deleteOne: (id: string) => { },
});

export const useUserView = () => useContext(UserViewsContext);

export const UserViewsContextContainer = ({ children }) => {
    const { recursiveGetList, updateRecord, createRecord } = useApi();
    const [userViews, setUserViews] = useState([]);
    const [fetching, setFetching] = useState(false);

    const fetchGridConf = async () => {
        // await deleteRecord(resource, 17);
        const { id } = getCurrentUser();
        if (!id) return;
        setFetching(true);
        const wholeArray: BfUserView[] = [];
        await recursiveGetList(wholeArray, resource, {
            // filter: [
            //     { field: 'bfUsersId', type: 'equals', value: id }
            // ],
            associations: {
                bfUsers: {},
            },
        });
        const newUserViews = wholeArray.map((one) => {
            const newOne = {
                ...one,
                bfView: JSON.parse(one.bfView),
            }
            return newOne;
        });
        setUserViews(newUserViews);
        setFetching(false);
    }
    useEffect(() => {
        fetchGridConf();
        // eslint-disable-next-line
    }, []);

    const getGridConfigs = (resource1: string, configId): GridConfig[] => {
        const currentUserId = getCurrentUser().id;
        if (configId) {
            const userView: BfUserView = userViews.find((one: BfUserView) => one.id === configId);
            return [{
                title: userView?.bfViewIdentifier?.split('.')[1] || 'NoTitle',
                models: userView?.bfView || {},
                pageSize: userView?.bfViewPageSize,
            }];
        } else {
            const configs: BfUserView[] = userViews.filter((one: BfUserView) => {
                return (one.bfUsersId === currentUserId && one.bfViewIdentifier.split('.')[0] === resource1);
            });
            return configs.map(userView => ({
                title: userView.bfViewIdentifier.split('.')[1] || 'NoTitle',
                models: userView.bfView,
                pageSize: userView.bfViewPageSize,
            }));
        }
    }

    const saveOneGridConf = async (resource1: string, id: string, title: string = '', users: any[] = [], models: BfViewModels, pageSize: number) => {
        const confUsers = users;
        if (confUsers.length === 0) {
            const user = getCurrentUser();
            confUsers.push(user);
        }
        const bfViewIdentifier = resource1 + '.' + title;
        const newUserViews = [...userViews];
        for (const user of confUsers) {
            let userView: BfUserView = {};
            let index = -1;
            if (id) index = newUserViews.findIndex((one: BfUserView) => one.id === id);
            if (index >= 0) userView = newUserViews[index];
            userView.bfUsersId = user.id;
            userView.bfViewIdentifier = bfViewIdentifier;
            userView.bfView = models;
            userView.bfViewPageSize = pageSize;
            const saveFunc = index >= 0 ? updateRecord : createRecord;
            const { data, error } = await saveFunc(resource, { ...userView, bfView: JSON.stringify(userView.bfView), bfUsers: undefined });
            if (!error) {
                userView.id = data.id;
                userView.bfUsers = user;
                if (index >= 0) {
                    newUserViews.splice(index, 1, userView);
                } else {
                    newUserViews.push(userView);
                }
                setUserViews(newUserViews);
            }
            return { error };
        }
        return {};
    }

    const deleteOne = (id) => {
        const _userViews = [...userViews];
        const index = _userViews.findIndex(c => c.id === id);
        if (index >= 0) {
            _userViews.splice(index, 1);
            setUserViews(_userViews);
        }
    }

    return (
        <UserViewsContext.Provider
            value={{
                userViews,
                fetching,
                getGridConfigs,
                saveOneGridConf,
                deleteOne,
            }}
        >
            {children}
        </UserViewsContext.Provider>
    )
}
