import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import { printError } from "./errors";
import { readCookie } from "./cookie";
import { awsCognitoSetRequiredAttributes } from "../redux/awsCognito/actions";
import {store} from "../redux/store";

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);
let cognitoUserSession;

const getCognitoUserFromCookie = () => {
  const accessToken = readCookie("accessToken");
  const idToken = readCookie("idToken");
  const refreshToken = readCookie("refreshToken");
  if (!accessToken || !idToken || !refreshToken) return null;
  const AccessToken = new CognitoAccessToken({ AccessToken: accessToken });
  const IdToken = new CognitoIdToken({ IdToken: idToken });
  const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
  const userSession = new CognitoUserSession({
    IdToken,
    AccessToken,
    RefreshToken,
  });
  const user = new CognitoUser({
    Username: AccessToken.decodePayload().username,
    Pool: userPool,
  });
  user.setSignInUserSession(userSession);
  return user;
};

const getCognitoUser = async () => {
  cognitoUserSession = userPool.getCurrentUser();
  if (!cognitoUserSession) cognitoUserSession = getCognitoUserFromCookie();
  if (!cognitoUserSession) return null;
  cognitoUserSession.getSession((err, session) => {
    if (err) {
      printError(err, "retrieving user session");
      Promise.reject(err);
    } else {
      if (session.isValid()) {
        console.log({ cognitoUserSession });
        Promise.resolve(true);
      } else {
        console.log("Session is not valid.");
        Promise.reject({ message: "Session is not valid." });
      }
    }
  });
};

getCognitoUser();

/**
 * Login and New password challenge
 */

const handleLoginSuccess = (
  cognitoUser,
  coUserSession ,
  onSuccess
) => {
  cognitoUser.getUserData((err, data) => {
    const userObj = {};
    if (err) {
      printError(err, "getUserData");
    } else {
      const {
        MFAOptions,
        PreferredMfaSetting,
        UserAttributes,
        UserMFASettingList,
        Username,
      } = data;
      console.log({
        MFAOptions,
        PreferredMfaSetting,
        UserAttributes,
        UserMFASettingList,
        Username,
      });
      userObj.UserMFASettingList = UserMFASettingList || [];
      for (let i = 0; i < UserAttributes.length; i++) {
        let { Name, Value } = UserAttributes[i];
        if (Name === "given_name") Name = "firstName";
        if (Name === "family_name") Name = "lastName";
        if (Name === "preferred_username") Name = "username";
        if (Name === "sub") userObj.id = Value;
        userObj[Name] = Value;
      }
    }
    const accessToken = coUserSession.getAccessToken().getJwtToken();
    const idToken = coUserSession.getIdToken().getJwtToken();
    const refreshToken = coUserSession.getRefreshToken().getToken();
    onSuccess(accessToken, idToken, refreshToken, userObj);
  });
};

export const getCurrentUser = () => {
  return cognitoUserSession;
};

export const awsLogin = (
  username,
  password,
  onSuccess = (accessToken, idToken, refreshToken, usr) => {},
  onError,
  onNeedNewPassword = (sessionId, userAttributes, requiredAttributes) => {},
  onNeedTotp = (deviceName) => {}
) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });
  cognitoUser.authenticateUser(authDetails, {
    onSuccess: (session) => {
      cognitoUserSession = cognitoUser;
      handleLoginSuccess(cognitoUser, session, onSuccess);
    },
    onFailure: (err) => {
      printError(err, "awsLogin");
      onError(err);
    },
    mfaRequired: (challengeName, params) => {
      cognitoUserSession = cognitoUser;
      console.log("mfaRequired", { challengeName, params });
      onNeedTotp();
    },
    totpRequired: (challengeName, params) => {
      cognitoUserSession = cognitoUser;
      console.log("totpRequired", { challengeName, params });
      onNeedTotp(params.FRIENDLY_DEVICE_NAME);
    },
    newPasswordRequired: (userAttributes, requiredAttributes) => {
      awsCognitoSetRequiredAttributes(requiredAttributes);
      cognitoUserSession = cognitoUser;
      delete userAttributes.email_verified;
      delete userAttributes.email;
      onNeedNewPassword(
        cognitoUser,
        userAttributes,
        requiredAttributes
      );
    },
  });
};

export const awsCompleteNewPasswordChallenge = (
    password,
    onPasswortChangeSuccess,
    onError
) => {

  const state = store.getState();
  const requiredAttributes = state.awsCognito.cognitoRequiredAttributes;

  cognitoUserSession.completeNewPasswordChallenge(
      password,
      requiredAttributes,
      {
        onSuccess: (session) => {
          handleLoginSuccess(cognitoUserSession, session, onPasswortChangeSuccess);
        },
        onFailure: (err) => {
          printError(err, "awsCompleteNewPasswordChallenge");
          onError(err);
        },
      }
  );

};

export const awsSignOut = () => {
  try {
    if (cognitoUserSession) {
      cognitoUserSession.signOut();
    }
  } catch (err) {
    printError(err, "awsSignOut");
  }
};

/**
 * Forgot/reset password
 */

export const awsChangePassword = (
  oldPassword,
  newPassword,
  onSuccess,
  onError
) => {
  cognitoUserSession.changePassword(oldPassword, newPassword, (err, result) => {
    if (err) {
      printError(err, "changePassword");
      onError(err);
    } else {
      onSuccess();
    }
  });
};

export const awsForgotPassword = (
  username,
  onSuccess = (username) => {},
  onError
) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  cognitoUser.forgotPassword({
    onSuccess: (data) => {
      console.log("CodeDeliveryData from forgotPassword: ", data);
      onSuccess();
    },
    onFailure: (err) => {
      printError(err, "forgotPassword");
      onError(err);
    },
    inputVerificationCode: ({ AttributeName, DeliveryMedium, Destination }) => {
      console.log("inputVerificationCode", {
        AttributeName,
        DeliveryMedium,
        Destination,
      });
      cognitoUserSession = cognitoUser;
      onSuccess(username);
    },
  });
};

export const awsVerifyAndResetPassword = (
  verificationCode,
  newPassword,
  onSuccess = () => {},
  onError
) => {
  cognitoUserSession.confirmPassword(verificationCode, newPassword, {
    onSuccess: () => {
      onSuccess();
    },
    onFailure: (err) => {
      printError(err, "awsVerifyAndResetPassword");
      onError(err);
    },
  });
};

/**
 * Update user attributes
 */
export const awsUpdateUser = (
  username,
  usr,
  onSuccess = (usr) => {},
  onError
) => {
  const attributeList = [];
  for (let i = 0; i < Object.keys(usr).length; i++) {
    const orgKey = Object.keys(usr)[i];
    let key = orgKey;
    if (key === "id") continue;
    if (key === "firstName") key = "given_name";
    if (key === "lastName") key = "family_name";
    if (key === "username") key = "preferred_username";
    const attribute = { Name: key, Value: usr[orgKey] };
    attributeList.push(new CognitoUserAttribute(attribute));
  }
  cognitoUserSession.updateAttributes(attributeList, (err, result) => {
    if (err) {
      printError(err, "updateAttributes");
      onError(err);
    } else {
      onSuccess(usr);
    }
  });
};

/**
 * MFA
 */

/**
 * awsAssociateSoftwareToken
 * @param {(secretCode: string) => void} associateSecretCode
 * @param {(err: any) => void} onFailure
 */
export const awsAssociateSoftwareToken = (associateSecretCode, onFailure) => {
  cognitoUserSession.associateSoftwareToken({
    associateSecretCode,
    onFailure,
  });
};

/**
 * awsVerifySoftwareToken
 * @param {string} totp
 * @param {string} deviceName
 * @param {() => void} onSuccess
 * @param {(err: Error) => void} onFailure
 */
export const awsVerifySoftwareToken = (
  totp,
  deviceName,
  onSuccess,
  onFailure
) => {
  cognitoUserSession.verifySoftwareToken(totp, deviceName, {
    onSuccess: (session) => {
      console.log({ session });
      onSuccess();
    },
    onFailure,
  });
};

/**
 * awsSetUserMfa
 * @param {boolean} enableOrDisable
 * @param {boolean} smsMFA
 * @param {boolean} totpMFA
 * @param {() => void} onSuccess
 * @param {(err: any) => void} onError
 */
export const awsSetUserMfa = (
  enableOrDisable = false,
  smsMFA = false,
  totpMFA = false,
  onSuccess,
  onError
) => {
  let smsConf = null;
  let totpConf = null;
  if (enableOrDisable && smsMFA) {
    smsConf = {
      PreferredMfa: false,
      Enabled: true,
    };
  }
  if (enableOrDisable && totpMFA) {
    totpConf = {
      PreferredMfa: true,
      Enabled: true,
    };
  }
  if (!enableOrDisable) {
    if (smsMFA) {
      smsConf = {
        PreferredMfa: false,
        Enabled: false,
      };
    } else {
      totpConf = {
        PreferredMfa: false,
        Enabled: false,
      };
    }
  }
  cognitoUserSession.setUserMfaPreference(smsConf, totpConf, (err, result) => {
    if (err) {
      printError(err, "setUserMfaPreference");
      onError(err);
    } else {
      console.log("setUserMfaPreference", { result });
      onSuccess();
    }
  });
};

/**
 * awsSendMFACode
 * @param {string} code
 * @param {(accessToken, idToken, refreshToken, usr) => void} onSuccess
 * @param {(err: any) => void} onError
 * @param {string} mfaType
 */
export const awsSendMFACode = (
  code,
  onSuccess,
  onError,
  mfaType = "SOFTWARE_TOKEN_MFA"
) => {
  cognitoUserSession.sendMFACode(
    code,
    {
      onSuccess: (session) =>
        handleLoginSuccess(cognitoUserSession, session, onSuccess),
      onFailure: (err) => {
        printError(err, "sendMFACode");
        onError(err);
      },
    },
    mfaType
  );
};

/**
 * Sign Up and Verify
 */

export const awsSignup = (email, username, password) => {
  const attributeList = [];
  attributeList.push(
    new CognitoUserAttribute({
      Name: "email",
      Value: email,
    })
  );
  userPool.signUp(username, password, attributeList, null, (err, data) => {
    if (err) {
      console.log(err);
      alert("Couldn't sign up");
    } else {
      console.log(data);
      alert("User Added Successfully");
    }
  });
};

export const awsVerifyRegistration = (username, otp) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  cognitoUser.confirmRegistration(otp, true, (err, data) => {
    if (err) {
      console.log(err);
      alert("Couldn't verify account");
    } else {
      console.log(data);
      alert("Account verified successfully");
      window.location.href = "/login";
    }
  });
};

export const awsResendConfirmationCode = (onSuccess, onError) => {
  cognitoUserSession.resendConfirmationCode((err, result) => {
    if (err) {
      printError(err, "resendConfirmationCode");
      onError(err);
    } else {
      onSuccess(result);
    }
  });
};
