import React from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    return (
        <ResourceGrid
            customResource='bf-users'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.bf_users.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'userName', headerName: translate('resources.bf_users.fields.userName'), minWidth: 140, editable: true },
                { field: 'firstName', headerName: translate('resources.bf_users.fields.firstName'), minWidth: 140, flex: 1, editable: true },
                { field: 'lastName', headerName: translate('resources.bf_users.fields.lastName'), minWidth: 140, flex: 1, editable: true },
                { field: 'gender', headerName: translate('resources.bf_users.fields.gender'), minWidth: 50, type: 'singleSelect', valueOptions: [{ value: 'male', label: translate('resources.bf_users.gender.male') }, { value: 'female', label: translate('resources.bf_users.gender.female') }], editable: true },
                { field: 'language', headerName: translate('resources.bf_users.fields.language'), minWidth: 100, editable: true },
                { field: 'email', headerName: translate('resources.bf_users.fields.email'), minWidth: 180, editable: true },
                { field: 'isEmployee', headerName: translate('resources.bf_users.fields.isEmployee'), maxWidth: 100, type: 'boolean', editable: true },
                { field: 'enableLogin', headerName: translate('resources.bf_users.fields.enableLogin'), maxWidth: 100, type: 'boolean', editable: true },
                { field: 'enableGetLogin', headerName: translate('resources.bf_users.fields.enableGetLogin'), minWidth: 120, type: 'boolean', editable: true },
                { field: 'initials', headerName: translate('resources.bf_users.fields.initials'), maxWidth: 100, editable: true },
                { field: 'unit', headerName: translate('resources.bf_users.fields.unit'), maxWidth: 100, editable: true },
                { field: 'active', headerName: translate('resources.bf_users.fields.active'), maxWidth: 70, type: 'boolean', editable: true },
            ]}
        />
    )
}

export const BfUsersList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.bf_users.name')} />
            <Grid />
        </>
    )
}
