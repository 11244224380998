import React from 'react';
import { Title, useTranslate } from 'react-admin';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import ResourceGrid from 'components/ResourceGrid';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    const { countriesList, taxCategoriesList } = useRelatedDataContext();

    const countryFormatter = ({ value }) => {
        if (!value) return '';
        return (countriesList.find(c => c.value === value) || {}).label || value;
    }
    const taxCategoryFormatter = ({ value }) => {
        if (!value) return '';
        return (taxCategoriesList.find(c => c.value === value) || {}).label || value;
    }

    return (
        <ResourceGrid
            loading={!countriesList.length || !taxCategoriesList.length}
            customResource='tax-rates'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.tax_rates.fields.id'), maxWidth: 20, type: 'number' },
                { field: 'countriesId', headerName: translate('resources.tax_rates.fields.countriesId'), minWidth: 200, valueFormatter: countryFormatter, type: 'singleSelect', valueOptions: countriesList, editable: true },
                { field: 'taxCategoriesId', headerName: translate('resources.tax_rates.fields.taxCategoriesId'), minWidth: 190, flex: 1, valueFormatter: taxCategoryFormatter, type: 'singleSelect', valueOptions: taxCategoriesList, editable: true },
                { field: 'taxRate', headerName: translate('resources.tax_rates.fields.taxRate'), maxWidth: 100, type: 'number', editable: true },
            ]}
        />
    )
}

export const TaxRatesList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.tax_rates.name')} />
            <Grid />
        </>
    )
}