import React from 'react';
import { Title, useTranslate } from 'react-admin';
import MuiGrid from 'components/MuiGrid';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import { validationInt, validationOneChar } from 'components/MuiGrid/validations';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const { currenciesList, mutateCurrency, setCurrenciesList, fetchCurrenciesList } = useRelatedDataContext();
    const translate = useTranslate();
    const SymbolPositionOptions = [
        { value: 'after', label: translate('resources.currencies.select.after') },
        { value: 'before', label: translate('resources.currencies.select.before') },
    ]

    return (
        <MuiGrid
            isLoading={!currenciesList.length}
            rows={editGridConf ? [] : currenciesList}
            readonly={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.currencies.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'exchangeRate', headerName: translate('resources.currencies.fields.exchangeRate'), minWidth: 120, type: 'number', editable: true },
                { field: 'defaultIsNet', headerName: translate('resources.currencies.fields.defaultIsNet'), minWidth: 160, type: 'boolean', editable: true },
                { field: 'currenciesName', headerName: translate('resources.currencies.fields.currenciesName'), minWidth: 80, flex: 1, editable: true },
                { field: 'currenciesCode', headerName: translate('resources.currencies.fields.currenciesCode'), maxWidth: 50, editable: true },
                { field: 'currenciesSymbol', headerName: translate('resources.currencies.fields.currenciesSymbol'), maxWidth: 60, editable: true },
                { field: 'htmlEntity', headerName: translate('resources.currencies.fields.htmlEntity'), minWidth: 120, editable: true },
                { field: 'decimalPoint', headerName: translate('resources.currencies.fields.decimalPoint'), minWidth: 120, editable: true, preProcessEditCellProps: validationOneChar },
                { field: 'thousandsPoint', headerName: translate('resources.currencies.fields.thousandsPoint'), minWidth: 120, editable: true, preProcessEditCellProps: validationOneChar },
                { field: 'decimalPlaces', headerName: translate('resources.currencies.fields.decimalPlaces'), minWidth: 120, type: 'number', editable: true, preProcessEditCellProps: validationInt },
                { field: 'symbolPosition', headerName: translate('resources.currencies.fields.symbolPosition'), minWidth: 120, type: 'singleSelect', singleSelectType: 'string', valueOptions: SymbolPositionOptions, editable: true },
                { field: 'currenciesIsoCode', headerName: translate('resources.currencies.fields.currenciesIsoCode'), maxWidth: 80, editable: true },
                { field: 'sort', headerName: translate('resources.currencies.fields.sort'), maxWidth: 40, type: 'number', editable: true, preProcessEditCellProps: validationInt },
            ]}
            setRows={setCurrenciesList}
            mutateOnUpdate={mutateCurrency}
            resource='currencies'
            cbAfterSubmitCreateModal={() => {
                setCurrenciesList([]);
                fetchCurrenciesList();
            }}
        />
    )
}

export const CurrenciesList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.currencies.name')} />
            <Grid />
        </>
    )
}