import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Title, useNotify, useTranslate } from 'react-admin';
import { Controller, useForm } from 'react-hook-form';
import MuiTextField from 'components/MuiTextField';
import PasswordVisible from 'components/PasswordVisible';
import { Button, CircularProgress, Grid, Paper } from '@mui/material';
import { setCurrentBB2User } from 'providers/currentUser';
import { awsLogin } from 'utils/awsCognito';
import { cookieDomain } from 'utils/cookie';
import TotpQRCodeInput from 'pages/profile/TotpQRCodeInput';
import { useCookies } from 'react-cookie';
import {BB2PassReset} from "./bb2-pass-reset";

const BB2Login = () => {
  const { state = {} } = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();
  const notify = useNotify();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [, setCookie] = useCookies();

  const [needNewPassword, setNeedNewPassword] = useState(false);

  const onSuccess = (accessToken, idToken, refreshToken, usr) => {
    setDeviceName("");
    setCookie("accessToken", accessToken, { domain: cookieDomain, path: "/" });
    setCookie("idToken", idToken, { domain: cookieDomain, path: "/" });
    setCookie("refreshToken", refreshToken, { domain: cookieDomain, path: "/" });
    setCurrentBB2User(usr);
    setLoading(false);
    const target = state.target || 'overview';
    navigate(`/bb2/${target}`);
  };
  const onError = (err) => {
    setLoading(false);
    notify(err.message, { type: 'error' });
  };
  const onNeedNewPassword = (cognitoUser, userAttributes, requiredAttributes) => {
    setNeedNewPassword(true);
    setLoading(false);
  };
  const onNeedTotp = (deviceName) => {
    setDeviceName(deviceName);
  };

  const onSubmit = ({ username, password }) => {
    setLoading(true);
    awsLogin(
      username,
      password,
      onSuccess,
      onError,
      onNeedNewPassword,
      onNeedTotp
    );
  };

  return (
      <>
      {needNewPassword ? <BB2PassReset></BB2PassReset>: (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Title title={'bb2.name'} />
              <Paper sx={{ padding: 3, maxWidth: 400, margin: 'auto' }}>
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    <Controller
                        name='username'
                        control={control}
                        render={({ field }) => (
                            <MuiTextField
                                {...field}
                                label={translate('pos.user_name')}
                                autoFocus
                                required
                                fullWidth
                            />
                        )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordVisible
                        name='password'
                        label={translate('pos.password')}
                        control={control}
                        errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                        variant='contained'
                        type='submit'
                        fullWidth
                        disabled={loading}
                    >
                      {loading && <CircularProgress size={25} thickness={2} />}
                      {translate('pos.submit')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <TotpQRCodeInput
                  open={!!deviceName}
                  onClose={() => {
                    setDeviceName("");
                    setLoading(false);
                  }}
                  deviceName={deviceName}
                  onSendCodeSuccess={onSuccess}
              />
            </form>
      )}
      </>
  );
};

export default BB2Login;
