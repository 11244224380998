import { Link as RaLink } from "react-admin";

const Link = ({ to = "/", style = {}, children }) => {
  return (
    <RaLink to={to} style={{ ...style }}>
      {children}
    </RaLink>
  );
};

export default Link;
