import React from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    return (
        <ResourceGrid
            customResource='carriers'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.carriers.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'carriersName', headerName: translate('resources.carriers.fields.carriersName'), minWidth: 180, editable: true },
                { field: 'carriersDescription', headerName: translate('resources.carriers.fields.carriersDescription'), minWidth: 200, editable: true },
                { field: 'trackingUrl', headerName: translate('resources.carriers.fields.trackingUrl'), minWidth: 200, flex: 1, editable: true },
            ]}
        />
    )
}

export const CarriersList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.carriers.name')} />
            <Grid />
        </>
    )
}
