import React from 'react';
import { Title, useTranslate } from 'react-admin';
import MuiGrid from 'components/MuiGrid';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import AssociationEditModal from 'components/ResourceGrid/AssociationEditModal';
import NewAssociationFields from 'components/ResourceGrid/NewAssociationFields';
import { useApi } from 'contexts/useApi';
import { useSort } from 'providers/data/SortUtils';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const { countriesList, mutateCountry, setCountriesList, fetchCountriesList, languagesList } = useRelatedDataContext();
    const translate = useTranslate();
    const { getValueBasedOnLanguages } = useApi();
    const { sortComparatorBasedOnLanguages } = useSort();

    const languageFormatter = ({ value }) => {
        if (!value) return '';
        return (languagesList.find(l => l.value === value) || {}).label || value;
    }

    return (
        <MuiGrid
            isLoading={!countriesList.length}
            rows={editGridConf ? [] : countriesList}
            readonly={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.countries.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'countriesIsoCode', headerName: translate('resources.countries.fields.iso'), minWidth: 100, editable: true },
                {
                    field: 'countriesDescription',
                    headerName: translate('resources.countries.fields.country'),
                    minWidth: 200,
                    flex: 1,
                    sortComparator: (v1, v2) => sortComparatorBasedOnLanguages(v1, v2, 'countriesName'),
                    editable: true,
                    valueFormatter: ({ value }) => getValueBasedOnLanguages(Object.values(value || {}), 'countriesName'),
                    renderEditCell: (params) => (
                        <AssociationEditModal
                            {...params}
                            associationColumns={[
                                { field: 'id', headerName: translate('resources.countries_description.fields.id'), hide: true },
                                { field: 'languagesId', headerName: translate('resources.countries_description.fields.language'), minWidth: 140, sortable: false, valueFormatter: languageFormatter, type: 'singleSelect', valueOptions: languagesList, editable: true },
                                { field: 'countriesName', headerName: translate('resources.countries_description.fields.countriesName'), minWidth: 200, flex: 1, editable: true },
                            ]}
                            associationResource='countries-description'
                            parentIdField='countriesId'
                            parentMutate={mutateCountry}
                            modalTitle={translate("resources.countries_description.name")}
                        />
                    ),
                    renderNewCell: (field, required) => (
                        <NewAssociationFields
                            field={field}
                            required={required}
                            associationFields={[
                                { configurationKey: 'languagesId', label: translate('resources.countries_description.fields.language'), type: 'singleSelect', valueOptions: languagesList },
                                { configurationKey: 'countriesName', label: translate('resources.countries_description.fields.countriesName') },
                            ]}
                        />
                    ),
                    paramsNewCell: { resource: 'countries-description', parentIdField: 'countriesId' },
                },
                {
                    field: 'countriesMatchings',
                    headerName: translate('resources.countries.fields.countries_matchings'),
                    minWidth: 170,
                    flex: 1,
                    sortComparator: (v1, v2) => sortComparatorBasedOnLanguages(v1, v2, 'countriesMatchingsName'),
                    editable: true,
                    valueFormatter: ({ value }) => getValueBasedOnLanguages(Object.values(value || {}), 'countriesMatchingsName'),
                    renderEditCell: (params) => (
                        <AssociationEditModal
                            {...params}
                            associationColumns={[
                                { field: 'id', headerName: translate('resources.countries_matchings.fields.id'), hide: true },
                                { field: 'countriesMatchingsName', headerName: translate('resources.countries_matchings.fields.countriesMatchingsName'), minWidth: 200, flex: 1, editable: true },
                            ]}
                            associationResource='countries-matchings'
                            parentIdField='countriesId'
                            parentMutate={mutateCountry}
                            modalTitle={translate("resources.countries_matchings.name")}
                        />
                    ),
                    required: false,
                    renderNewCell: (field, required) => (
                        <NewAssociationFields
                            field={field}
                            required={required}
                            associationFields={[
                                { configurationKey: 'countriesMatchingsName', label: translate('resources.countries_matchings.fields.countriesMatchingsName') },
                            ]}
                        />
                    ),
                    paramsNewCell: { resource: 'countries-matchings', parentIdField: 'countriesId' },
                    deleteConstraint: { resource: 'countries-matchings', parentIdField: 'countriesId' },
                },
            ]}
            setRows={setCountriesList}
            keepFieldsOnUpdate={['countriesDescription', 'countriesMatchings']}
            mutateOnUpdate={mutateCountry}
            resource='countries'
            cbAfterSubmitCreateModal={() => {
                setCountriesList([]);
                fetchCountriesList();
            }}
        />
    )
}

export const CountriesList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.countries.name')} />
            <Grid />
        </>
    )
}
