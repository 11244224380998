const awsCognitoSetCognitoUser = (cognitoUser) => {
    return {
        'type': 'SET_COGNITO_USER',
        'payload': cognitoUser
    };
}

const awsCognitoSetUserAttributes = (cognitoUserAttributes) => {
    return {
        'type': 'SET_COGNITO_USER_ATTRIBUTES',
        'payload': cognitoUserAttributes
    };
}

const awsCognitoSetRequiredAttributes = (cognitoRequiredAttributes) => {
    return {
        'type': 'SET_COGNITO_REQUIRED_ATTRIBUTES',
        'payload': cognitoRequiredAttributes
    };
}

export { awsCognitoSetCognitoUser, awsCognitoSetRequiredAttributes, awsCognitoSetUserAttributes }
