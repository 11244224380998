import React, { useMemo } from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import AssociationEditModal from 'components/ResourceGrid/AssociationEditModal';
import NewAssociationFields from 'components/ResourceGrid/NewAssociationFields';
import { useApi } from 'contexts/useApi';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    const { languagesList } = useRelatedDataContext();
    const { getValueBasedOnLanguages } = useApi();

    const languagesListByCode = useMemo(() => languagesList.map(l => ({
        id: l.id, value: l.code, label: l.label
    })), [languagesList]);

    const languageFormatter = ({ value }) => {
        if (!value) return '';
        return (languagesListByCode.find(l => l.value === value) || {}).label || value;
    }

    return (
        <ResourceGrid
            customResource='bf-groups'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            extraParams={{
                associations: {
                    bfGroupsLanguages: {},
                }
            }}
            columns={[
                { field: 'id', headerName: translate('resources.bf_groups.fields.id'), maxWidth: 20, type: 'number', hide: true },
                {
                    field: 'bfGroupsLanguages',
                    headerName: translate('resources.bf_groups.fields.bfGroupsLanguages'),
                    minWidth: 140,
                    flex: 1,
                    sortField: 'bfGroupsLanguages.groupsName',
                    editable: true,
                    valueFormatter: ({ value }) => getValueBasedOnLanguages(Object.values(value || {}), 'groupsName', true),
                    renderEditCell: (params) => (
                        <AssociationEditModal
                            {...params}
                            associationColumns={[
                                { field: 'id', headerName: translate('resources.bf_groups_languages.fields.id'), hide: true },
                                { field: 'languagesCode', headerName: translate('resources.bf_groups_languages.fields.languagesCode'), minWidth: 140, sortable: false, valueFormatter: languageFormatter, type: 'singleSelect', singleSelectType: 'string', valueOptions: languagesListByCode, editable: true },
                                { field: 'groupsName', headerName: translate('resources.bf_groups_languages.fields.groupsName'), minWidth: 140, flex: 1, editable: true },
                                { field: 'groupsDescription', headerName: translate('resources.bf_groups_languages.fields.groupsDescription'), minWidth: 140, flex: 1, editable: true },
                            ]}
                            associationResource='bf-groups-languages'
                            parentIdField='bfGroupsId'
                            modalTitle={translate("resources.bf_groups_languages.name")}
                        />
                    ),
                    renderNewCell: (field, required) => (
                        <NewAssociationFields
                            field={field}
                            required={required}
                            associationFields={[
                                { configurationKey: 'languagesCode', label: translate('resources.bf_groups_languages.fields.languagesCode'), type: 'singleSelect', valueOptions: languagesListByCode },
                                { configurationKey: 'groupsName', label: translate('resources.bf_groups_languages.fields.groupsName') },
                                { configurationKey: 'groupsDescription', label: translate('resources.bf_groups_languages.fields.groupsDescription') },
                            ]}
                        />
                    ),
                    paramsNewCell: { resource: 'bf-groups-languages', parentIdField: 'bfGroupsId' },
                },
                { field: 'bfGroupsVisible', headerName: translate('resources.bf_groups.fields.bfGroupsVisible'), minWidth: 70, type: 'boolean', editable: true },
            ]}
        />
    )
}

export const BfGroupsList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.bf_groups.name')} />
            <Grid />
        </>
    )
}
