import React from "react";
import { Box, Card, CardActions, Typography } from "@mui/material";
import { NOLAYOUT_FORM_WIDTH } from "theme/theme2";
import SwitchLang from "./AppBar/switch-lang";
import { useResponsiveContext } from "contexts/ResponsiveContextContainer";
import { Form } from "react-admin";
import Link from "components/Link";
import RaButton from "components/RaButton";

const LoginLayout = ({
  loading = false,
  title = "",
  formId = "",
  buttonTitle = "",
  handleSubmit,
  linkTitle = "",
  linkTo = "",
  children,
}) => {
  const { isPhone } = useResponsiveContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        // background: "url(/bf_login_background.jpg)",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        position: "relative",
      }}
    >
      <Box sx={{ position: "absolute", top: 32, right: 32 }}>
        <SwitchLang noLayout />
      </Box>
      <Card
        sx={{
          width: isPhone ? "calc(100vw - 32px)" : NOLAYOUT_FORM_WIDTH,
          marginTop: "6em",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <img src='/logo-orange-bf.jpg' alt="logo" width={400} />
        </Box>
        {title && (
          <Typography
            variant="h3"
            sx={{ textAlign: "center", marginBottom: "1em" }}
          >
            {title}
          </Typography>
        )}
        {formId ? (
          <Form id={formId} onSubmit={handleSubmit}>
            <Box sx={{ padding: "0 1em 0 1em" }}>{children}</Box>
          </Form>
        ) : (
          <>{children}</>
        )}
        {formId && (
          <CardActions sx={{ padding: `1em 1em 1em 1em` }}>
            <RaButton
              startIcon={null}
              type="submit"
              form={formId}
              disabled={loading}
              loading={loading}
              fullWidth
            >
              {buttonTitle}
            </RaButton>
          </CardActions>
        )}
        <Box sx={{ marginY: "1em", display: "flex", justifyContent: "center" }}>
          <Link to={linkTo}>{linkTitle}</Link>
        </Box>
      </Card>
    </Box>
  );
};

export default LoginLayout;
