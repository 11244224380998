import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import { Box, Button, Card, CardActions, CircularProgress, FormControl, FormHelperText, InputAdornment, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AccountCircle as AccountCircleIcon, Email as EmailIcon } from '@mui/icons-material';
import MuiColorButton from 'components/MuiColorButton';
import MuiModal from 'components/MuiModal';
import { grey } from '@mui/material/colors';
import { useApi } from 'contexts/useApi';
import MuiTextField from 'components/MuiTextField';
import PasswordVisible from 'components/PasswordVisible';
import { GRAY_BG } from 'theme/theme2';

const ForgotPassword = () => {
    const translate = useTranslate();
    const { getList } = useApi();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bfUsers, setBfUsers] = useState([]);
    const [openResetSuccess, setOpenResetSuccess] = useState(false);
    const [openResetFailure, setOpenResetFailure] = useState('');

    const handleGotoLogin = () => {
        setOpenResetSuccess(false);
        navigate('/login');
    }

    const { control, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            username: '',
            email: '',
            newPassword: '',
            confirmPassword: '',
        }
    });
    const onSubmit = async ({ username, email, newPassword, confirmPassword }) => {
        setLoading(true);
        if (bfUsers.length === 0) {
            // TODO
            const _bfUsers = await getList('bf-users', {
                filter: {
                    type: "equals",
                    field: "bfUsers.userName",
                    value: username,
                }
            });
            setBfUsers(_bfUsers);
        } else {
            // const bfUser = bfUsers.find(u => u.email === email);
            // TODO
            setOpenResetSuccess(true);
        }
        setLoading(false);
    }

    // console.log(bfUsers)

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    backgroundColor: GRAY_BG,
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    <Box sx={{ padding: '1em 1em 1em 1em' }}>
                        <FormControl variant="standard" fullWidth>
                            <Controller
                                name="username"
                                control={control}
                                render={({ field }) => (
                                    <MuiTextField
                                        {...field}
                                        label={translate('ra.auth.username')}
                                        autoFocus
                                        required
                                        readOnly={bfUsers.length > 0}
                                        startAdornment={
                                            <InputAdornment position="start"><AccountCircleIcon /></InputAdornment>
                                        }
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    {bfUsers.length > 0 && (
                        <>
                            <Box sx={{ padding: '0 1em 1em 1em' }}>
                                <FormControl variant="standard" fullWidth>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{ validate: value => !!(bfUsers.find(u => u.email === value)) }}
                                        render={({ field }) => (
                                            <MuiTextField
                                                {...field}
                                                label={translate('pos.email')}
                                                autoFocus
                                                required
                                                startAdornment={
                                                    <InputAdornment position="start"><EmailIcon /></InputAdornment>
                                                }
                                            />
                                        )}
                                    />
                                </FormControl>
                                {errors.email?.type === "validate" && <FormHelperText error>{translate('pos.email_mismatch')}</FormHelperText>}
                            </Box>
                            <Box sx={{ padding: '0 1em 1em 1em' }}>
                                <FormControl variant="standard" fullWidth>
                                    <PasswordVisible
                                        name="newPassword"
                                        label={translate('pos.new_password')}
                                        control={control}
                                        errors={errors}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ padding: '0 1em 1em 1em' }}>
                                <FormControl variant="standard" fullWidth>
                                    <PasswordVisible
                                        name="confirmPassword"
                                        label={translate('pos.confirm_password')}
                                        control={control}
                                        errors={errors}
                                        rules={{ validate: value => value === watch('newPassword') }}
                                    />
                                </FormControl>
                            </Box>
                        </>
                    )}
                    <CardActions sx={{ padding: '1em 1em 1em 1em' }}>
                        <MuiColorButton
                            colorset={grey}
                            variant="contained"
                            type="submit"
                            disabled={loading}
                            fullWidth
                        >
                            {loading && <CircularProgress size={25} thickness={2} />}
                            {bfUsers.length > 0 ? translate('pos.save') : translate('pos.submit')}
                        </MuiColorButton>
                    </CardActions>
                </Card>
            </Box>

            <MuiModal
                open={openResetSuccess}
                onClose={handleGotoLogin}
                title={<Typography sx={{ color: theme => theme.palette.success.main }}>{translate('pos.success')}</Typography>}
                dialogContents={
                    <Typography>{translate('pos.reset_password_success')}</Typography>
                }
                dialogActions={
                    <Button variant="contained" onClick={handleGotoLogin}>{translate('pos.ok')}</Button>
                }
            />
            <MuiModal
                open={openResetFailure}
                onClose={() => setOpenResetFailure(false)}
                title={translate('pos.failure')}
                dialogContents={
                    <Typography>{translate('pos.reset_password_failure')}</Typography>
                }
                dialogActions={
                    <Button variant="contained" onClick={() => setOpenResetFailure(false)}>{translate('pos.ok')}</Button>
                }
            />
        </form>
    )
}

export default ForgotPassword;
