import React from 'react';
import { Title, useTranslate } from 'react-admin';
import MuiGrid from 'components/MuiGrid';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const { languagesList, mutateLanguage, setLanguagesList, fetchLanguagesList } = useRelatedDataContext();
    const translate = useTranslate();
    
    return (
        <MuiGrid
            isLoading={!languagesList.length}
            rows={editGridConf ? [] : languagesList}
            readonly={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.languages.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'code', headerName: translate('resources.languages.fields.code'), maxWidth: 50, editable: true },
                { field: 'iso6392', headerName: translate('resources.languages.fields.iso6392'), minWidth: 100, editable: true },
                { field: 'name', headerName: translate('resources.languages.fields.name'), minWidth: 200, flex: 1, editable: true },
            ]}
            setRows={setLanguagesList}
            mutateOnUpdate={mutateLanguage}
            resource='languages'
            cbAfterSubmitCreateModal={() => {
                setLanguagesList([]);
                fetchLanguagesList();
            }}
        />
    )
}

export const LanguagesList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.languages.name')} />
            <Grid />
        </>
    )
}
