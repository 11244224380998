import axios from "axios";
import { readCookie } from "utils/cookie";
import { printError } from "utils/errors";
import { json2object } from "utils/json2object";

const bb2ConfigUrl = process.env.REACT_APP_BB2_CONFIG_URL;
const bb2BillingUrl = process.env.REACT_APP_BB2_BILLING_URL;
const bb2ReportingUrl = process.env.REACT_APP_BB2_REPORTING_URL;
const bb2WorkflowUrl = process.env.REACT_APP_BB2_WORKFLOW_URL;

const getHydraResult = (result) => {
  if (!result) {
    return { error: { message: "Fetch failed" } };
  }
  return {
    data: result["hydra:member"],
    total: result["hydra:totalItems"],
  };
};

export const bb2api = {
  getMandant: async () => {
    try {
      const token = readCookie();
      const { data: result } = await axios.get(bb2ConfigUrl + "/b-b2-mandants?showAll=false", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data, error } = getHydraResult(result);
      if (error) return { error };
      if (!data?.length) return { error: { message: "You don't have any mandant!" } };
      const objs = data.map((obj) => {
        json2object(obj);
        obj.value = obj["@id"];
        obj.label = obj.customerName;
        return obj;
      });
      return { data: objs };
    } catch (error) {
      printError(error, "bb2-get-mandant");
      return { error };
    }
  },
  getUsageWorkflow: async (from, to, token) => {
    try {
      const suburl = bb2BillingUrl.startsWith("http://localhost") ? "/usage-workflow" : `/usage/workflow?from=${from}&to=${to}`;
      const { data } = await axios.get(bb2BillingUrl + suburl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { data };
    } catch (error) {
      printError(error, "bb2-get-usage-workflow");
      return { error };
    }
  },
  getReporting: async (messageType, from, to, token) => {
    try {
      const suburl = bb2ReportingUrl.startsWith("http://localhost") ? "/message-reporting" : `/message/reporting?messageType=${messageType}&from=${from}&to=${to}`;
      const { data } = await axios.get(bb2ReportingUrl + suburl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { data };
    } catch (error) {
      printError(error, "bb2-get-message-reporting");
      return { error };
    }
  },
  getLogs: async (page = 1, perPage = 10, searchTerm = "", token) => {
    try {
      let suburl = bb2ReportingUrl.startsWith("http://localhost") ? `/logs-reporting-page-${page}` : `/message/reporting?page=${page}&perPage=${perPage}`;
      if (searchTerm) {
        suburl += `&searchTerm=${searchTerm}`;
      }
      const { data } = await axios.get(bb2ReportingUrl + suburl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { data };
    } catch (error) {
      printError(error, "bb2-get-logs");
      return { error };
    }
  },
  postWorkflowRun: async (technicalWorkflow) => {
    try {
      if (bb2WorkflowUrl.startsWith("http://localhost")) {
        return { data: "4beb9255-1c41-42ce-bd0e-2cb57e144cdf" };
      }
      const suburl = "/workflow/run";
      const { data } = await axios.post(bb2WorkflowUrl + suburl, technicalWorkflow);
      if (!data.success) {
        return { error: { message: "Failure" } };
      }
      return { data: data.queueId };
    } catch (error) {
      printError(error, "bb2-post-workflow-run");
      return { error };
    }
  },
  getWorkflowStatus: async (customerTechnicalName, queueId) => {
    try {
      const suburl = bb2WorkflowUrl.startsWith("http://localhost") ? "/workflow-status" : `/workflow/status/${customerTechnicalName}/${queueId}`;
      const { data } = await axios.get(bb2WorkflowUrl + suburl);
      return { data };
    } catch (error) {
      printError(error, "bb2-get-workflow-status");
      return { error };
    }
  },
};
