import React from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';
import { validationInt } from 'components/MuiGrid/validations';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    const {
        productsClassificationAvailabilityTypesList,
        productsClassificationDeliveryTimesTypesList,
    } = useRelatedDataContext();

    const pcatFormatter = ({ value }) => {
        if (!value) return '';
        return (productsClassificationAvailabilityTypesList.find(c => c.value === value) || {}).label || value;
    }
    const pcdttFormatter = ({ value }) => {
        if (!value) return '';
        return (productsClassificationDeliveryTimesTypesList.find(c => c.value === value) || {}).label || value;
    }

    return (
        <ResourceGrid
            loading={!productsClassificationAvailabilityTypesList.length || !productsClassificationDeliveryTimesTypesList.length}
            customResource='products-classification'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            columns={[
                { field: 'id', headerName: translate('resources.products_classification.fields.id'), maxWidth: 20, type: 'number', hide: true },
                { field: 'productsClassificationCode', headerName: translate('resources.products_classification.fields.productsClassificationCode'), maxWidth: 60, editable: true },
                { field: 'productsClassificationAvailabilityTypesId', headerName: translate('resources.products_classification.fields.productsClassificationAvailabilityTypesId'), minWidth: 300, flex: 1, valueFormatter: pcatFormatter, type: 'singleSelect', valueOptions: productsClassificationAvailabilityTypesList, editable: true },
                { field: 'productsClassificationDeliveryTimesTypesId', headerName: translate('resources.products_classification.fields.productsClassificationDeliveryTimesTypesId'), minWidth: 300, flex: 1, valueFormatter: pcdttFormatter, type: 'singleSelect', valueOptions: productsClassificationDeliveryTimesTypesList, editable: true },
                { field: 'sortOrder', headerName: translate('resources.products_classification.fields.sortOrder'), maxWidth: 60, type: 'number', preProcessEditCellProps: validationInt, editable: true },
            ]}
        />
    )
}

export const ProductsClassificationList = () => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.products_classification.name')} />
            <Grid />
        </>
    )
}
