import { useApi } from 'contexts/useApi';

export interface Sort {
    field: string;
    direction: 'asc' | 'desc';
}

export const useSort = () => {
    const { getValueBasedOnLanguages } = useApi();

    // static list pages (non-resource)
    const sortComparatorBasedOnLanguages = (v1: object, v2: object, fieldName: string, byLanguagesCode = false) => {
        let value1 = getValueBasedOnLanguages(Object.values(v1 || {}), fieldName, byLanguagesCode);
        let value2 = getValueBasedOnLanguages(Object.values(v2 || {}), fieldName, byLanguagesCode);
        if (typeof value1 === 'string') value1 = value1.toLowerCase();
        if (typeof value2 === 'string') value2 = value2.toLowerCase();
        if (value1 > value2) return 1;
        if (value1 < value2) return -1;
        return 0;
    }

    return {
        sortComparatorBasedOnLanguages,
    }
}
