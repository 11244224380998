import React, { useMemo } from 'react';
import { Title, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import ResourceGrid from 'components/ResourceGrid';
import { useApi } from 'contexts/useApi';
import AssociationEditModal from 'components/ResourceGrid/AssociationEditModal';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import NewAssociationFields from 'components/ResourceGrid/NewAssociationFields';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const { groupId } = useParams();
    const translate = useTranslate();
    const { getValueBasedOnLanguages } = useApi();
    const { languagesList, brandsList, productConditionsList } = useRelatedDataContext();

    const languageFormatter = ({ value }) => {
        if (!value) return '';
        return (languagesList.find(l => l.value === value) || {}).label || value;
    }
    const brandFormatter = ({ value }) => {
        if (!value) return '';
        return (brandsList.find(b => b.value === value) || {}).label || value;
    }
    const productConditionFormatter = ({ value }) => {
        if (!value) return '';
        return (productConditionsList.find(c => c.value === value) || {}).label || value;
    }

    const extraFilters = useMemo(() => ([
        { type: 'equals', field: 'groupsId', value: groupId },
    ]), [groupId]);

    return (
        <ResourceGrid
            loading={!languagesList.length || !brandsList.length || !productConditionsList.length}
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            readonly
            gotoDetailOnCellClick
            customResource='products'
            extraFilters={extraFilters}
            extraParams={{
                associations: {
                    productsDescription: {},
                }
            }}
            columns={[
                { field: 'id', headerName: translate('resources.products.fields.id'), maxWidth: 20, type: 'number', hide: true },
                {
                    field: 'productsDescription',
                    headerName: translate('resources.products_description.fields.productsName'),
                    minWidth: 140,
                    flex: 1,
                    sortField: 'productsDescription.productsName',
                    editable: true,
                    valueFormatter: ({ value }) => getValueBasedOnLanguages(Object.values(value || {}), 'productsName'),
                    renderEditCell: (params) => (
                        <AssociationEditModal
                            {...params}
                            associationColumns={[
                                { field: 'id', headerName: translate('resources.products_description.fields.id'), maxWidth: 20, type: 'number', hide: true },
                                { field: 'languagesId', headerName: translate('resources.products_description.fields.languagesId'), minWidth: 140, valueFormatter: languageFormatter, type: 'singleSelect', valueOptions: languagesList, editable: true },
                                { field: 'productsName', headerName: translate('resources.products_description.fields.productsName'), minWidth: 200, flex: 1, editable: true },
                            ]}
                            associationResource='products-description'
                            parentIdField='productsId'
                            modalTitle={translate("resources.products_description.name")}
                        />
                    ),
                    renderNewCell: (field, required) => (
                        <NewAssociationFields
                            field={field}
                            required={required}
                            associationFields={[
                                { configurationKey: 'languagesId', label: translate('resources.products_description.fields.languagesId'), type: 'singleSelect', valueFromRelated: 'languagesList' },
                                { configurationKey: 'productsName', label: translate('resources.products_description.fields.productsName') },
                            ]}
                        />
                    ),
                    paramsNewCell: { resource: 'products-description', parentIdField: 'productsId' },
                },
                { field: 'itemNumber', headerName: translate('resources.products.fields.itemNumber'), minWidth: 120, editable: true },
                { field: 'brandsId', headerName: translate('resources.products.fields.brandsId'), minWidth: 140, valueFormatter: brandFormatter, type: 'singleSelect', valueOptions: brandsList, editable: true },
                { field: 'productsStatus', headerName: translate('resources.products.fields.productsStatus'), maxWidth: 80, type: 'boolean', editable: true },
                { field: 'productsConditionsId', headerName: translate('resources.products.fields.productsConditionsId'), minWidth: 140, valueFormatter: productConditionFormatter, type: 'singleSelect', valueOptions: productConditionsList, editable: true },
                { field: 'minPrice', headerName: translate('resources.products.fields.minPrice'), maxWidth: 80, type: 'number', editable: true },
                { field: 'maxPrice', headerName: translate('resources.products.fields.maxPrice'), maxWidth: 80, type: 'number', editable: true },
            ]}
            keepFieldsOnUpdate={['productsDescription']}
        />
    )
}

export const ProductsByGroup = () => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.products.name')} />
            <Grid />
        </>
    )
}
