import React from 'react';
import { Title, useTranslate } from 'react-admin';
import ResourceGrid from 'components/ResourceGrid';
import { useRelatedDataContext } from 'contexts/RelatedDataContext';
import { validationInt } from 'components/MuiGrid/validations';
import AssociationEditModal from 'components/ResourceGrid/AssociationEditModal';
import NewAssociationFields from 'components/ResourceGrid/NewAssociationFields';
import { useApi } from 'contexts/useApi';

export const Grid = ({ editGridConf = false, dataOnSaveGridConf }) => {
    const translate = useTranslate();
    const { languagesList } = useRelatedDataContext();
    const { getValueBasedOnLanguages } = useApi();

    const languageFormatter = ({ value }) => {
        if (!value) return '';
        return (languagesList.find(l => l.value === value) || {}).label || value;
    }

    return (
        <ResourceGrid
            customResource='delivery-times'
            editGridConf={editGridConf}
            dataOnSaveGridConf={dataOnSaveGridConf}
            extraParams={{
                associations: {
                    deliveryTimesDescription: {},
                }
            }}
            columns={[
                { field: 'id', headerName: translate('resources.delivery_times.fields.id'), maxWidth: 20, type: 'number', hide: true },
                {
                    field: 'deliveryTimesDescription',
                    headerName: translate('resources.delivery_times.fields.deliveryTimesDescription'),
                    minWidth: 100,
                    flex: 1,
                    sortField: 'deliveryTimesDescription.deliveryTimesName',
                    editable: true,
                    valueFormatter: ({ value }) => getValueBasedOnLanguages(Object.values(value || {}), 'deliveryTimesName'),
                    renderEditCell: (params) => (
                        <AssociationEditModal
                            {...params}
                            associationColumns={[
                                { field: 'id', headerName: translate('resources.delivery_times.fields.id'), hide: true },
                                { field: 'languagesId', headerName: translate('resources.delivery_times.fields.languagesId'), minWidth: 140, sortable: false, valueFormatter: languageFormatter, type: 'singleSelect', valueOptions: languagesList, editable: true },
                                { field: 'deliveryTimesName', headerName: translate('resources.delivery_times.fields.deliveryTimesName'), minWidth: 200, flex: 1, editable: true },
                            ]}
                            associationResource='delivery-times-description'
                            parentIdField='deliveryTimesId'
                            modalTitle={translate("resources.delivery_times.name")}
                        />
                    ),
                    renderNewCell: (field, required) => (
                        <NewAssociationFields
                            field={field}
                            required={required}
                            associationFields={[
                                { configurationKey: 'languagesId', label: translate('resources.delivery_times.fields.languagesId'), type: 'singleSelect', valueFromRelated: 'languagesList' },
                                { configurationKey: 'deliveryTimesName', label: translate('resources.delivery_times.fields.deliveryTimesName') },
                            ]}
                        />
                    ),
                    paramsNewCell: { resource: 'delivery-times-description', parentIdField: 'deliveryTimesId' },
                },
                { field: 'deliveryTimesCode', headerName: translate('resources.delivery_times.fields.deliveryTimesCode'), minWidth: 100, editable: true },
                { field: 'sortOrder', headerName: translate('resources.delivery_times.fields.sortOrder'), maxWidth: 60, type: 'number', preProcessEditCellProps: validationInt, editable: true },
            ]}
            keepFieldsOnUpdate={['deliveryTimesDescription']}
        />
    )
}

export const DeliveryTimesList = (props) => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate('resources.delivery_times.name')} />
            <Grid />
        </>
    )
}
