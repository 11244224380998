import React, { createContext, useContext, useEffect } from 'react';
import { useLocaleState } from 'react-admin';
import axios from 'axios';
import { diff } from "just-diff";
import useLocalStorageState from 'use-local-storage-state';
import { getSubdomain } from 'utils/getSubdomain';
import { printError } from 'utils/errors';
import { toMuiIcon } from 'utils/icons';

const EntityAccessContext = createContext({
  bb2Config: {
    name: '',
    loginLogo: '',
    uiUrl: '',
    apiUrl: '',
    contentGeneration: '',
    brickfoxBridge: false,
    error: null,
  },
  // menu-items
  menuItems: [],
  fetchAndSetMenuItems: async () => {},
  setMenuItems: (items) => {},
  findMenuItem: (items, url, id) => items[0],
  findIframeMenuItems: (items, results) => [],
  getNameAndWindowTitle: (menuItem, locale) => ({ name: "", windowTitle: "" }),
  insertKeyAndTitleInMenuItem: (items) => {},
});

export const useEntityAccess = () => useContext(EntityAccessContext);

export const EntityAccessContextContainer = ({ children }) => {
  const [bb2Config, setBB2Config] = useLocalStorageState('bb2Config', {
    ssr: false,
    defaultValue: {
      name: '',
      loginLogo: '',
      uiUrl: '',
      apiUrl: '',
      brickfoxBridge: false,
      aiForecastingServiceToken: '',
      error: null,
      contentGeneration: '',
      brickfoxApiUrl: '',
      brickfoxApiKey: ''
    },
  });
  const getConfig = async () => {
    const Subdomain = getSubdomain();
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_BB2_CONFIG_URL + '/config',
        {
          headers: { Subdomain },
        }
      );
      const { name, loginLogo, uiUrl, apiUrl, aiForecastingServiceToken, contentGeneration, brickfoxApiUrl, brickfoxApiKey, brickfoxBridge = false } = data;
      setBB2Config({ name, loginLogo, uiUrl, apiUrl, aiForecastingServiceToken, brickfoxBridge, contentGeneration, brickfoxApiUrl, brickfoxApiKey });
    } catch (error) {
      printError(error);
      setBB2Config({
        name: '',
        loginLogo: '',
        uiUrl: '',
        apiUrl: '',
        aiForecastingServiceToken: '',
        brickfoxBridge: false,
        contentGeneration: '0',
        brickfoxApiUrl: '',
        brickfoxApiKey: '',
        error: error.response.status
      });
    }
  };
  useEffect(() => {
    getConfig();
    // eslint-disable-next-line
  }, []);

  const [menuItems, setMenuItems] = useLocalStorageState('menu-items', {
    ssr: false,
    defaultValue: [],
  });
  const [locale] = useLocaleState();

  const findMenuItem = (items, url, id = null) => {
    for (const item of items) {
      const { menuUrl, children = [] } = item;
      if (url) {
        if (menuUrl?.url === url) {
          return item;
        }
      } else if (id) {
        if (item.id === id) {
          return item;
        }
      }
      if (children.length > 0) {
        const result = findMenuItem(children, url, id);
        if (result) return result;
      }
    }
  };

  const findIframeMenuItems = (items, results) => {
    for (const item of items) {
      const { menuUrl, children = [] } = item;
      const target = menuUrl?.target || item.target;
      if (target === "_iframe") {
        results.push(item);
      }
      if (children.length > 0) {
        findIframeMenuItems(children, results);
      }
    }
  };

  const getNameAndWindowTitle = (menuItem, locale) => {
    if (!menuItem) return { name: undefined, windowTitle: undefined };
    const descriptions = menuItem.descriptions || [];
    const desc =
      descriptions.find((d) => d.language.code === locale) ||
      descriptions[0] ||
      {};
    const { name, windowTitle } = desc;
    return { name, windowTitle };
  };

  const insertKeyAndTitleInMenuItem = (items) => {
    for (const item of items) {
      const { id, descriptions, children } = item;
      const desc =
        descriptions.find((d) => d.language.code === locale) ||
        descriptions[0] ||
        {};
      item.key = id;
      item.title = desc.name;
      if (children.length > 0) {
        insertKeyAndTitleInMenuItem(children);
      }
    }
  };

  const sortChildrenMenuItems = (items) => {
    for (const item of items) {
      if (item.children.length > 0) {
        item.children = item.children.sort(
          (a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)
        );
        sortChildrenMenuItems(item.children);
      }
    }
  };

  const fetchAndSetMenuItems = async () => {
    const buildTarget = (url) => {
      let target = "_self";
      if (url.startsWith("http")) target = "_blank";
      if (url.startsWith("/BFpublic") || url.startsWith("https://ipn.brickfox.net")) {
        target = "_iframe";
      }
      return target;
    };
    const getOneMenu = (m) => {
      const { id, parentId, url, iconCls, sort, level, bfMenusLanguages } = m;
      let menuUrl = null;
      if (url) {
        menuUrl = {
          target: buildTarget(url),
          url: url.startsWith("/BFpublic") ? url.substring(1) : url,
        };
      }
      const descriptions = [];
      Object.keys(bfMenusLanguages).forEach((key) => {
        const { languagesCode, menuTitle, windowTitle } = bfMenusLanguages[key];
        descriptions.push({
          name: menuTitle,
          windowTitle,
          language: { code: languagesCode },
        })
      });
      const one = {
        id,
        level,
        menuUrl,
        sortOrder: sort,
        parent: parentId,
        icon: toMuiIcon(iconCls),
        descriptions,
        children: [],
      };
      return one;
    }

    const { apiUrl } = bb2Config;
    const token = localStorage.getItem("token");
    let menu_items = [];
    try {
      const { data } = await axios.get(apiUrl + "/BFpublic/Rest/bfmenus", {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      menu_items = data.data;
    } catch (error) {
      printError(error);
      return { error };
    }

    const _menuItems = [];
    const roots = menu_items.filter((m) => !m.parentId);
    roots.forEach((m) => _menuItems.push(getOneMenu(m)));
    _menuItems.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));

    const children = menu_items.filter((m) => m.parentId);
    for (let i = 1; i < 10; i++) {
      const levelItems = children.filter((m) => m.level === i);
      levelItems.forEach((m) => {
        const p = findMenuItem(_menuItems, null, m.parentId);
        if (p) {
          p.children.push(getOneMenu(m));
        } else {
          console.error("impossible menu parent");
        }
      });
    }

    const differences = diff(menuItems, _menuItems);
    if (differences.length > 0) {
      sortChildrenMenuItems(_menuItems);
      setMenuItems(_menuItems);
    }
    return {};
  };

  return (
    <EntityAccessContext.Provider
      value={{
        bb2Config,
        // menu-items
        menuItems,
        fetchAndSetMenuItems,
        setMenuItems,
        findMenuItem,
        findIframeMenuItems,
        getNameAndWindowTitle,
        insertKeyAndTitleInMenuItem,
      }}
    >
      {children}
    </EntityAccessContext.Provider>
  );
};
