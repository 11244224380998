import { TranslationMessages } from 'react-admin';
import deutschMessages from 'ra-language-german';

const customDeutschMessages: TranslationMessages = {
    ...deutschMessages,
    pos: {
        noLayout: {
            login_title: "Mit Benutzerdaten einloggen",
            language: 'Sprache',
            saveLogin: 'Meinen Login auf diesem Computer speichern',
        },
        dashboard: {
            name: 'Dashboard',
            welcome: {
                title: 'Unified Commerce Hub',
                subtitle: 'Entdecken Sie den neuen brickfox Admin',
            },
        },
        footer: {
            msg1: '© 2022, brickfox',
            msg2: 'Über uns',
        },
        drawer: {
            settings: 'Einstellungen',
            master_data: 'Stammdaten',
        },
        no_options: 'Keine Optionen',
        configuration: {
            name: 'Konfiguration',
            grid_conf: 'Listen-Konfigurationen',
            grid_config_buttons_label: 'Listen - Konfigurationen',
            select_view: 'View',
            search_username: 'Benutzername',
            search_username_no_options: 'Keine Benutzer gefunden',
            conf_title: 'Titel',
            click_save_grid_conf: 'Nach der Konfiguration klicken Sie auf "Einstellungen speichern"',
            config_save_success: 'Die Konfiguration wurde erfolgreich gespeichert.',
            gird_conf_fields: {
                id: 'ID',
                bfUsersId: 'User',
                bfViewIdentifier: 'Identifier',
                bfViewPageSize: 'Anzahl Datensätze pro Seite',
                columnModel: 'Spalten',
                sortModel: 'Sortieung',
                filterModel: 'Filter',
            },
        },
        pim: {
            name: 'PIM',
        },
        iframe: {
            loading: 'Lade ...',
            orders: 'Bestellungen',
            helpdesk: 'Anleitungen',
            reports: {
                name: 'Statistiken',
                menus: {
                    channelPerformance: 'Verkaufskanäle',
                    productGroups: 'Warengruppen',
                    assortment: 'Sortiment',
                    paymentMethods: 'Zahlungsweisen',
                },
            },
        },
        prompts: {
            dirty: 'Alle nicht gespeicherten Änderungen gehen verloren wenn Sie den aktuellen Bereich verlassen.',
        },
        dataGrid: {
            noItems: 'Keine Daten gefunden',
            noFilterItems: 'Keine Daten für diese Filtereinstellungen.',
            filterFor: 'Filtern nach ',
            showHelpdesk: 'Hilfe anzeigen',
            help: 'Hilfe',
        },
        tour: {
            tour: 'Tour',
            tooltip: 'Probieren Sie unseren Guide aus',
            next: 'Weiter',
            back: 'Zurück',
            done: 'Fertig',
            steps: {
                switchLang: 'Sprache wechseln',
                switchLangHere: 'Hier können Sie die Spache anpassen',
                sidebarMenu: 'Sidebar Menü Ein - und Ausklappen',
                userMenu: 'Navigation',
                userMenuHere: 'Hier können Sie Ihr Profil editieren oder sich abmelden',
                last: 'Viel Spaß mit brickfox !',
            },
        },
        add_new: 'Neuer Datensatz',
        save_grid_conf: 'Einstellungen speichern',
        actions: 'Aktionen',
        save: 'Speichern',
        cancel: 'Abbrechen',
        edit: 'Bearbeiten',
        delete: 'Löschen',
        more: 'Mehr ...',
        warning: 'Warnung',
        delete_warning: {
            msg1: 'Der Datensatz wird endgültig gelöscht.',
            msg2: 'Sind Sie sicher?',
        },
        forgot_your_password: 'Passwort vergessen ?',
        submit: 'Absenden',
        email: 'Email',
        require_reset_password: 'Sie sollten Ihr Passwort zurücksetzen.',
        change_password: 'Passwort Ändern',
        new_password: 'Neues Passwort',
        confirm_password: 'Passwort bestätigen',
        password_mismatch: 'Die Passwörter stimmen nicht überein.',
        email_mismatch: 'Email und Benutzername stimmen nicht überein',
        reset_password_success: 'Passwort wurde erfolgreich zurückgesetzt.',
        reset_password_failure: 'Zurücksetzen des Passworts fehlgeschlagen.',
        ok: 'OK',
        success: 'Erfolg',
        failure: 'Fehler',
        logged_out: 'Sie wurden erfolgreich ausgeloggt.',
        good_day: 'Bis zum nächsten mal.',
        go_to_login: 'WEITER ZUM LOGIN',
        client_not_found: 'Kunde nicht gefunden',
        profile: 'Profil',
        password: 'Passwort',
        user_name: 'Benutzername',
        first_name: 'Vorname',
        last_name: 'Nachname',
        theme_bf: 'brickfox',
        theme_pixi: 'Pixi',
        select_theme: 'Theme wählen',
        search: "Suche",
        temporary_password_change_title: 'Das vorläufige Passwort für die erste Anmeldung muss ersetzt werden.',
        temporary_password_button_caption: 'Passwort ersetzen',
        temporary_password_new: 'Neues Passwort',
        temporary_password_repeat: 'Neues Passwort wiederholen',
        temp_password_has_changed: 'Das temporäre Passwort wurde geändert.',
        passwords_mismatch: 'Das neue Passwort und die Widerholung sind nicht gleich.'
    },
    bb2: {
        name: 'brickfox Bridge 2',
        pages: {
            overview: 'Übersicht',
            workflow: 'Workflow',
            logs: 'Logs',
            billing: 'Rechnungen',
        },
        fields: {
            total_traffic: "Aufrufe",
            total_traffic_tooltip: "Anzahl der übertragenen API-Aufrufe in diesem Monat.",
            since_last_month: "Seit letztem Monat",
            last_12_months: "Letzte 12 Monate",
            last_month: "Letzter Monat",
            last_7_days: "Letzte 7 Tage",
            last_24_hours: "Letzte 24 Stunden",
            total_errors: "Fehler",
            total_errors_tooltip: "Anzahl der Fehlermeldungen in diesem Monat.",
            total_warnings: "Warnungen",
            total_warnings_tooltip: "Anzahl der Warnmeldungen in diesem Monat.",
            performance: "Leistung",
            start_workflow: "Arbeitsablauf starten",
            service: "Dienst",
            title: "Nachricht",
            type: "Art",
            date: "Datum",
            pretty_print: "Formatierte Anzeige",
            auto_refresh: "Automatische Aktualisierung",
            workflow_usage_per_month: "Workflow-Nutzung pro Monat",
            usage: "Nutzung",
            invoice: "Rechnung",
            invoice_month: "Rechnung / Monat",
            month: "Monat",
            estimated: "geschätzt",
            estimation_this_month: "Schätzung für diesen Monat",
            billing: "Rechnung",
            total_api_calls_per_month: "API-Aufrufe insgesamt pro Monat",
            month_name: "Monat",
            total_usage: "Gesamtverbrauch",
            costs: "Kosten",
            billing_plan: "Fakturierungsplan",
            your_personal_billing_plan: "Ihr persönlicher Abrechnungstarif für BB2 Engine API-Aufrufe",
            api_call_range: "API-Aufrufe-Bereich",
            price: "Preis",
        },
    },
    resources: {
        countries: {
            name: 'Länder',
            fields: {
                id: 'ID',
                iso: 'ISO - Code',
                country: 'Land',
                countries_matchings: 'Matchings',
            },
        },
        countries_description: {
            name: 'Ländereigenschaften',
            fields: {
                id: 'ID',
                language: 'Sprache',
                countriesName: 'Land'
            },
        },
        countries_matchings: {
            name: 'Länder-Matchings',
            fields: {
                id: 'ID',
                countriesMatchingsName: 'Name',
            },
        },
        languages: {
            name: 'Sprachen',
            fields: {
                id: 'ID',
                code: 'Code',
                iso6392: 'ISO 6392',
                name: 'Name'
            },
        },
        content_generation: {
            name: 'Content Generator',
        },
        suppliers: {
            name: 'Lieferanten',
            fields: {
                id: 'ID',
                externalId: 'Externe ID',
                suppliersNumber: 'Nummer',
                suppliersName: 'Name',
                suppliersCustomerNumber: 'Kundennummer',
                suppliersStreet: 'Straße',
                suppliersCity: 'Stadt',
                suppliersZip: 'Plz',
                countriesId: 'Land',
                suppliersPhone: 'Tel.',
                suppliersFax: 'Fax',
                suppliersEmail: 'Email',
                suppliersEmailOrders: 'Bestellung Email',
                suppliersUrl: 'Url',
                currenciesId: 'Währung'
            },
        },
        tax_rates: {
            name: 'Steuerklassen',
            fields: {
                id: 'ID',
                countriesId: 'Land',
                taxCategoriesId: 'Steuerklasse',
                taxRate: 'Steuersatz'
            },
        },
        stock_locations: {
            name: 'Lagerorte',
            fields: {
                id: 'ID',
                externStockLocationsId: 'Externe ID',
                stockLocationsName: 'Name',
                stockLocationsAddress: 'Straße',
                stockLocationsCity: 'Stadt',
                stockLocationsZip: 'Plz',
                stockLocationsCountry: 'Land'
            },
        },
        currencies: {
            name: 'Währungen',
            fields: {
                id: 'ID',
                exchangeRate: 'Faktor',
                defaultIsNet: 'Ist Netto',
                currenciesName: 'Name',
                currenciesCode: 'Code',
                currenciesSymbol: 'Symbol',
                htmlEntity: 'Html',
                decimalPoint: 'Trennung (Dez.)',
                thousandsPoint: 'Trennung (Tsd.)',
                decimalPlaces: 'Dezimalstellen',
                symbolPosition: 'Position (Symbol)',
                currenciesIsoCode: 'ISO - Code',
                sort: 'Sortierung'
            },
            select: {
                after: 'danach',
                before: 'davor'
            },
        },
        carriers: {
            name: 'Versender',
            fields: {
                id: 'ID',
                carriersName: 'Name',
                carriersDescription: 'Beschreibung',
                trackingUrl: 'Tracking URL'
            },
        },
        units: {
            name: 'Maßeinheiten',
            fields: {
                id: 'ID',
                measure: 'Typ',
                baseUnitsFactor: 'Faktor',
                sortOrder: 'Sortierung',
                baseUnitsId: 'Referenz',
                name: 'Name',
            },
        },
        units_description: {
            name: 'Maßeinheiten Übersetzung',
            fields: {
                id: 'ID',
                language: 'Sprache',
                unitsName: 'Name',
            },
        },
        coupons_reduce_types: {
            name: 'Gutschein-Rabatt-Typen',
            fields: {
                id: 'ID',
                reduceCode: 'Code',
                couponsReduceTypesDescription: 'Name',
            },
        },
        media_categories: {
            name: 'Media-Kategorien',
            fields: {
                id: 'ID',
                mediaCategoriesCode: 'Code',
                mediaCategoriesName: 'Name',
            },
        },
        delivery_times: {
            name: 'Lieferzeiten',
            fields: {
                id: 'ID',
                languagesId: 'Sprache',
                deliveryTimesName: 'Name',
                deliveryTimesDescription: 'Name',
                deliveryTimesCode: 'Code',
                sortOrder: 'Sortierung',
            },
        },
        shipping_methods: {
            name: 'Versandarten',
            fields: {
                id: 'ID',
                shippingMethodsCode: 'Code',
                ordersHoldStatusId: 'Bestellhaltestatus',
                sortOrder: 'Sortierung',
            },
        },
        shipping_methods_description: {
            name: 'Versandarten Übersetzung',
            fields: {
                id: 'ID',
                name: 'Name',
                languagesId: 'Sprache',
            },
        },
        payment_methods: {
            name: 'Zahlungsarten',
            fields: {
                id: 'ID',
                paymentMethodsCode: 'Code',
                ordersHoldStatusId: 'Bestellhaltestatus',
                paymentProvidersId: 'Provider',
                paymentClass: 'Klasse',
            },
        },
        payment_methods_description: {
            name: 'Zahlungsarten Übersetzung',
            fields: {
                id: 'ID',
                languagesId: 'Sprache',
                paymentMethodsName: 'Name',
            },
        },
        products_classification: {
            name: 'Produkt-Klassifizierung',
            fields: {
                id: 'ID',
                productsClassificationCode: 'Code',
                productsClassificationAvailabilityTypesId: 'Verfügbarkeit',
                productsClassificationDeliveryTimesTypesId: 'Lieferzeiten Typ',
                sortOrder: 'Sortierung',
            },
        },
        products_conditions: {
            name: 'Bearbeitungs-Status',
            fields: {
                id: 'ID',
                productsConditionsCode: 'Code',
                sortOrder: 'Sortierung',
            },
        },
        products_conditions_description: {
            name: 'Bearbeitungs-Status Übersetzung',
            fields: {
                id: 'ID',
                languagesId: 'Sprache',
                productsConditionsName: 'Name',
            },
        },
        bf_users: {
            name: 'Benutzer',
            gender: {
                male: 'männlich',
                female: 'weiblich',
            },
            fields: {
                id: 'ID',
                userName: 'Nutzername',
                firstName: 'Vorname',
                lastName: 'Nachname',
                gender: 'Geschlecht',
                language: 'Sprache',
                email: 'Email',
                isEmployee: 'Angestellter',
                enableLogin: 'Anmeldung aktivieren',
                enableGetLogin: 'Login aktivieren',
                initials: 'Initialen',
                unit: 'Einheit',
                active: 'Aktiv',
            },
        },
        bf_groups: {
            name: 'Benutzergruppen',
            fields: {
                id: 'ID',
                bfGroupsLanguages: 'Name',
                bfGroupsVisible: 'Sichtbar',
            },
        },
        bf_groups_languages: {
            name: 'Benutzergruppen Übersetzung',
            fields: {
                id: 'ID',
                languagesId: 'Sprache',
                languagesCode: 'Sprache',
                groupsName: 'Name',
                groupsDescription: 'Beschreibung',
            },
        },
        products: {
            name: 'Produkte',
            fields: {
                id: 'ID',
                itemNumber: 'Artikelnummer',
                brandsId: 'Marke',
                productsStatus: 'Status',
                productsConditionsId: 'Bearbeitungsstatus',
                minPrice: 'Min Preis',
                maxPrice: 'Max Preis',
            },
            relations: {
                default: 'Stammdaten',
                shippingMethods: 'Versandarten',
                taxCategories: 'Steuerklassen',
                costCentre: 'Kostenstelle',
                groups: 'Gruppen',
                brands: 'Marken',
                productsConditions: 'Bearbeitungszustände',
                countries: 'Länder',
                textBlocksGroups: 'Text-Blöcke-Gruppen',
                productsToProducts: 'Produktverknüpfungen',
                productsToAssignedProducts: 'Produktverknüpfungen 2',
                productsBundles: 'Bundles',
                productsHistory: 'History',
                productsMedia: 'Media',
                productsToGroups: 'Gruppenverknüpfungen',
                productsToProductsAttributesNew: 'Attribute',
                productsToProductsOptions: 'Optionen',
                productsToProductsVariationsDiffsOptions: 'ToProductsVariationsDiffsOptions',
                productsVariationsCombinationsHistory: 'VariationsCombinationsHistory',
                productsVariationsCombinationsMovementHistoryFrom: 'VariationsCombinationsMovementHistoryFrom',
                productsVariationsCombinationsMovementHistoryTo: 'VariationsCombinationsMovementHistoryTo',
                shopsProducts: 'Kanäle',
            },
        },
        products_description: {
            name: 'Produkt Übersetzungen',
            fields: {
                id: 'ID',
                languagesId: 'Sprache',
                productsName: 'Titel',
            },
        },
        brands: {
            name: 'Marke',
            fields: {
                id: 'ID',
                brandsName: 'Titel',
            },
        },
    },
    messages: {
        createSuccess: 'Datensatz erfolgreich gespeichert.',
        updateSuccess: 'Änderung erfolgreich gespeichert',
        deleteSuccess: 'Datensatz erfolgreich gelöscht',
        error: 'Ein Fehler ist aufgetreten: ',
        duplicated: 'Doppelter Eintrag kann nicht gespeichert werden.',
        constraint: 'Constraint-Verletzung, der Datensatz wird in anderen Tabellen verwendet.',
        comingSoon: 'Bald verfügbar ...',
        errorOnFetchingRelated: 'Fehler beim Laden ver Verknüpfungen ...',
    },
    tabs: {
        system: 'System',
        userInterface: 'Oberflächen',
        products: 'Produkte',
        orders: 'Bestellungen',
        images: 'Bilder',
        media: 'Medien',
        pim: 'PIM',
        automaticProductNumbers: 'Automatische Artikelnummer-Vergabe',
        reservations: 'Reservierungen',
        salesChannels: 'Vertriebskanäle',
        suppliers: 'Lieferanten',
        csvConnector: 'CSV - Connector',
        shopware: 'Shopware',
        bundles: 'Bundles',
        masterData: 'Stammdaten',
    },
    configuration: {
        bfVersion: "Version",
        accessKey: "Access Key",
        secretKey: "Secret Key",
        bfClient: "Client",
        sessionDuration: "Automatischer Logout",
        checkUserRights: "Listen anhand von User-Rechten filtern",
        dbInnodbLockWaitTimeout: "InnoDB lock wait timeout in Sekunden",
        defaultAttributesSetId: "Attributset",
        defaultCountryId: "Land",
        defaultCurrencyId: "Währung",
        defaultLanguageId: "Sprache",
        defaultPriceUnitsId: "Preis-Einheit",
        defaultStockLocationId: "Lagerort",
        defaultSuppliersId: "Lieferant",
        language: "Backend Sprache",
        languagesList: "Content Sprachen",
        countriesList: "Content Länder",
        currenciesList: "Content Währungen",
        pageTitle: "Seiten Titel",
        menuTitle: "Menü Titel",
        maximizeDesktopWindows: "Fenster maximieren",
        bfFavicon: "Favicon",
        bfLogo: "Logo",
        bfTheme: "Theme",
        showIndividualVirtualStocks: "Individuelle virtuelle Bestände einblenden",
        defaultDatePeriod: "Vorausgewählte Einstellung des Datumsfilters",
        defaultMailingsSendStatusCodeFilter: "Vorausgewählter Mailingstatus",
        showNewsFeedButton: "News Button einblenden",
        newsFeedCode: "News-Feed code",
        csvOrderLinesExportActive: "Bestellpositionen in Bestellexport exportieren",
        csvOrderLinesExportFieldsAndHeaders: "JSON-Konfiguration Bestellexport",
        prioritizeShopsCustomersIdForOrderCustomerId: "Shop-Kunden-Nummer für Anzeige von Kunden-Nr. bevorzugen",
        removeControlCharsFromProductsDescriptionAndName: "Steuerzeichen aus Produktbeschreibung/-name entfernen",
        possibleOrderStatusChangeCheckActive: "Bestellstatusänderungen prüfen",
        enableOrderShipmentsTab: "Order-Shipments anzeigen",
        showOrdersPaymentsStatus: "Bezahlstatus anzeigen",
        ordersLinesAddInfosKeysToShow: "Button der die zusätzlichen Infos einer Bestellposition anzeigt aktivieren",
        showBackendPrice: "Backend Preise anzeigen",
        customersLastImportDate: "Import letzte Bestellung",
        monitoringStuckOrdersStatusIds: "Zu überwachende Bestellstati",
        monitoringStuckOrdersDaysNotUpdated: "Tage seit Statusupdate",
        monitoringStuckOrdersEmailRecipients: "Report Empfänger",
        monitoringStuckOrdersAddBccBrickfoxSupport: "BCC an support@brickfox.de senden",
        extendedOrdersExport: "Erweiterter CSV-Export",
        ordersCsvLastExport: "Letzter CSV-Export",
        imageServerProducts: "URL Produkt Bilder Server",
        imageServerType: "Produkt Bilder Server Typ",
        imageServerUseSsl: "Produkt Bilder Server SSL verwenden",
        imageNotExistsThumb: "Standard Bild",
        imageFtpHost: "FTP - Host",
        imageFtpPort: "FTP - Port",
        imageFtpUser: "FTP - User",
        imageFtpPassword: "FTP - Password",
        imageFtpDir: "Root - Verzeichnis Pfad",
        imageFtpRemoteDirPostFix: "Postfix für Root - Verzeichnis",
        imageFtpUseSsl: "SSL für FTP verwenden",
        imageFtpOverwriteExisting: "Existierende Bilder überschreiben",
        imageFtpPassiveMode: "Passiv Modus aktiv",
        imageLocalDefaultDir: "Standard Verzeichnis (lokal)",
        imageLocalThumbs: "Standard Bild (lokal)",
        mediaServerProducts: "Media-Server Url",
        mediaServerType: "Media-Server Typ",
        mediaFtpHost: "FTP-Host",
        mediaFtpPort: "FTP-Port",
        mediaFtpUser: "FTP-User",
        mediaFtpPassword: "FTP-Passwort",
        mediaFtpDir: "Root - Verzeichnis Pfad",
        mediaFtpRemoteDirPostFix: "Postfix für Root - Verzeichnis",
        mediaFtpUseSsl: "SSL für FTP verwenden",
        mediaFtpOverwriteExisting: "Existierende Media-Dateien überschreiben",
        mediaFtpPassiveMode: "Passiv Modus aktiv",
        mediaLocalDefaultDir: "Standard Verzeichnis (lokal)",
        groupsSortingType: "Warengruppenbaum Sortierung",
        hideProductsDescriptionWysiwyg: "Beschreibung WYSIWYG aktivieren",
        enableProductsDescriptionShortWysiwyg: "Kurzbeschreibung WYSIWYG aktivieren",
        showSeoFields: "Anzeige SEO-Daten aktivieren",
        uniqueOrdernumber: "Anzeige von SEO-Daten aktivieren",
        calculateProductsStatusByVariations: "Attribut zum Speichern von Bullets als HTML-Liste",
        productsConditionCodeActive: "Produktstatus durch Variantenstatus anpassen",
        productsConditionCodeInactive: "Bearbeitungsstatus wenn aktiv",
        maxLengthProductsDescriptionBullet: "Maximale länge der Auflistungen",
        enableExternIdEdit: "Bearbeiten der Externen Referenz IDs aktivieren",
        articleDuplicateEnabled: "Artikel duplizieren erlauben",
        showBundleCheckox: "Bundle checkbox anzeigen",
        enableStockOnProductLevel: "Lagerbestandskonfiguration auf Shopproduktebene aktivieren",
        enableAvailabilityStockEdit: "Verfügbarkeit bei Varianten editierbar",
        showButtonDeleteVariations: "Löschen Button bei Varianten aktivieren",
        uniqueOrdernumberVariants: "Prüfung einzigartige Artikelnummer (Varianten) aktivieren",
        useProductsDescriptionBulletsAttribute: "Attribut zum Speichern von Bullets als HTML-Liste aktivieren",
        calculateOnlyForSpecifixCurrency: "Nur im Vertriebskanal hinterlegte Währungen berechnen bei Neuanlage",
        mainSupplierCheckboxVisible: "Hauptlieferant-Feld anzeigen",
        deliveryTimesFromSupplier: "Lieferzeitbestellung durch Hauptlieferant",
        enableTimeControlledPrices: "Zeitgesteuerte Preise im Preise-Tab anzeigen",
        enableTimeControlledSuppliersPrices: "Zeitgesteuerte Preise im Lieferanten-Tab anzeigen",
        calculateThirdPartyStockFlag: "Drittlagerbestand-Flag berechnen",
        enableProductsDiffsAddDeleteBtnByDeletedVariations: "Variantenmerkmale hinzufügen erlauben wenn nur gelöschte Varianten vorhanden sind",
        editVariationsNameAndDescription: "Varientennname und Beschreibung editieren",
        disableJsEanCheck: "EAN-Validierung deaktivieren",
        variationsPerShopActive: "Varianten pro Marktplatz aktivieren / deaktivieren",
        availableProductsAssignmentsTypes: "Zusätzliche Tabs",
        useBfLockedGuis: "Verwendung des Artikel Sperr Flags",
        expandTreeVariationImages: "Bildertab - Verzeichnisbaum aufgeklappt",
        optionsCheckAvailable: "Optionen Prüfung aktivieren",
        dirtyCheckDisabled: "Prüfung für ungespeicherte Änderungen abschalten",
        useAsyncFilters: "Asynchrone Prozessberechnung aktivieren",
        enablePermanentProductAndVariationDeletion: "Dauerhaften Löschen von Produkten und Varianten",
        defaultProductConditionId: "Standard Bearbeitungsstatus",
        defaultNeverOutOfStockValue: "Never out of stock - Flag",
        oldProductsToDeleteMonth: "Anzahl der Monate bei Processoldproducts - Script",
        automaticPOrdernumber: "(Produkte) automatische Artikelnummern-Vergabe",
        automaticPOrdernumberRangeStart: "(Produkte) Nummerkreis Start",
        automaticPOrdernumbeRangeEnd: "(Produkte) Nummerkreis Ende",
        automaticPOrdernumbeActual: "(Produkte) Nummernkreis aktuell",
        automaticPOrdernumbeLength: "(Produkte) Länge Nummer",
        automaticPOrdernumberPrefix: "(Produkte) Nummer Präfix",
        automaticPOrdernumberSuffix: "(Produkte) Nummer Suffix",
        automaticPvOrdernumber: "(Varinaten) automatische Artikelnummern-Vergabe",
        automaticPvOrdernumberRangeStart: "(Varinaten) Nummerkreis Start",
        automaticPvOrdernumberRangeEnd: "(Varinaten) Nummerkreis Ende",
        automaticPvOrdernumberActual: "(Varinaten) Nummernkreis aktuell",
        automaticPvOrdernumberLength: "(Varinaten) Länge Nummer",
        automaticPvOrdernumberPrefix: "(Varinaten) Nummer Präfix",
        automaticPvOrdernumberSuffix: "(Varinaten) Nummer Suffix",
        reserveStock: "Reservierungen aktivieren",
        stockReserveStatus: "Bestellstatus-Code für Reservierungen",
        stockReserveExcludeShopsIds: "Shops für Reservierungen ignorieren",
        stockReserveExcludePaymentMethodIds: "Zahlungsweisen für Reservierungen ignorieren",
        stockReserveExcludeShippingMethodIds: "Versandarten für Reservierungen ignorieren",
        ignoreUpdatesWhileProductsExportIsRunning: "Keine Produktupdates wenn Produktexport läuft",
        shopsAttributeMapping: "Attribut-Mapping einblenden",
        enableMultiAttributeMappings: "Mehrfach Attribut-Mappings erlauben",
        showSuppliersTasksFieldSet: "Lieferantenanbindung Felder Anzeigen",
        csvExportBeta: "Export Beta aktivieren",
        csvShopImportBeta: "Shop-Import Beta aktivieren",
        csvEnableVariantConfiguratorTab: "Varienten-Bundle import aktivieren",
        csvVariantConfiguratorDeactivateAbsentBundles: "Fehlende Produkte deaktivieren",
        csvLargeDataExport: "CSV-Export großer Daten",
        csvDelimiter: "CSV Trenner",
        dontImportUnknownProductsConditions: "Nur bekannte Bearbeitungstatus importieren",
        CsvCreateMediaCategoriesDisabled: "Nur bekannte Media-Kategorien importieren",
        csvCategoryDelimiter: "Default Kategorie Trennzeichen",
        csvSecondaryCategoryDelimiter: "Kategorie-Trennzeichen",
        csvAttributeDelimiter: "Default Attribut Trennzeichen",
        neverOutOfStockEnabled: "Abverkaufsartikel-Flag aktivieren",
        enableVariationsBundlesGui: "Oberfläche anzeigen",
        splitBundleOrdersLines: "Bestellpositionen aufteilen",
        productsVariationsBundlesSourceStockLocationsIds: "Zu berücksichtigende Lagerort-IDs",
        productsVariationsBundlesUseOriginalStockLocations: "Lagerarte der realen Artikel übernehmen",
        productsVariationsBundlesStockLocationsId: "Bestände aller Lagerorte in diesem Lagerort aufsummiert speichern",
        currenciesExchangeRateXml: "URI der Währungs-XML",
        currenciesExchangeRateCurrencies: "Automatisch zu aktualiserende Währungen",
    },
};

export default customDeutschMessages;
