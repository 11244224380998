import React, { createContext, useContext, useEffect, useState } from 'react';
import { sortObjectArray } from 'utils/sort';
import { useApi } from './useApi';

const RelatedDataContext = createContext({
    taxCategoriesList: [],
    mutateTaxCategory: (one) => one,
    setTaxCategoriesList: (list) => { },
    fetchTaxCategoriesList: async () => { },
    countriesList: [],
    mutateCountry: (one) => one,
    setCountriesList: (list) => { },
    fetchCountriesList: async () => { },
    currenciesList: [],
    mutateCurrency: (one) => one,
    setCurrenciesList: (list) => { },
    fetchCurrenciesList: async () => { },
    languagesList: [],
    mutateLanguage: (one) => one,
    setLanguagesList: (list) => { },
    fetchLanguagesList: async () => { },
    unitsList: [],
    mutateUnit: (one) => one,
    setUnitsList: (list) => { },
    fetchUnitsList: async () => { },
    ordersHoldStatusList: [],
    mutateOrdersHoldStatus: (one) => one,
    setOrdersHoldStatusList: (list) => { },
    fetchOrdersHoldStatusList: async () => { },
    paymentProvidersList: [],
    mutatePaymentProvider: (one) => one,
    setPaymentProvidersList: (list) => { },
    fetchPaymentProvidersList: async () => { },
    productsClassificationAvailabilityTypesList: [],
    mutateProductsClassificationAvailabilityType: (one) => one,
    setProductsClassificationAvailabilityTypesList: (list) => { },
    fetchProductsClassificationAvailabilityTypesList: async () => { },
    productsClassificationDeliveryTimesTypesList: [],
    mutateProductsClassificationDeliveryTimesType: (one) => one,
    setProductsClassificationDeliveryTimesTypesList: (list) => { },
    fetchProductsClassificationDeliveryTimesTypesList: async () => { },
    brandsList: [],
    mutateBrand: (one) => one,
    setBrandsList: (list) => { },
    fetchBrandsList: async () => { },
    configurationObj: {},
    setConfigurationObj: (obj) => { },
    fetchConfigurationObj: async () => { },
    productsAttributesSetsList: [],
    mutateProductsAttributesSet: (one) => one,
    setProductsAttributesSetsList: (list) => { },
    fetchProductsAttributesSetsList: async () => { },
    stockLocationsList: [],
    mutateStockLocation: (one) => one,
    setStockLocationsList: (list) => { },
    fetchStockLocationsList: async () => { },
    suppliersList: [],
    mutateSupplier: (one) => one,
    setSuppliersList: (list) => { },
    fetchSuppliersList: async () => { },
    productConditionsList: [],
    mutateProductCondition: (one) => one,
    setProductConditionsList: (list) => { },
    fetchProductConditionsList: async () => { },
});

export const useRelatedDataContext = () => useContext(RelatedDataContext);

export const RelatedDataContextContainer = ({ children }) => {
    const { recursiveGetList, getValueBasedOnLanguages } = useApi();

    const [configurationObj, setConfigurationObj] = useState({});
    const fetchConfigurationObj = async () => {
        const resource = 'bf-configuration';
        const wholeArray = [];
        await recursiveGetList(wholeArray, resource);
        const obj = {};
        wholeArray.forEach(one => obj[one.configurationKey] = one);
        setConfigurationObj(obj);
    }

    const [countriesList, setCountriesList] = useState([]);
    const mutateCountry = one => {
        const descs = Object.values(one.countriesDescription || {});
        let countryName = '';
        let label = `(${one.countriesIsoCode})`;
        if (descs.length) {
            countryName = getValueBasedOnLanguages(descs, 'countriesName');
            label = countryName + ' ' + label;
        }
        const matchings = Object.values(one.countriesMatchings || {});
        return {
            ...one,
            value: one.id,
            label,
            label2: countryName || label,
            matching: (matchings[0] || {}).countriesMatchingsName || '',
        }
    }
    const fetchCountriesList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'countries', {
            associations: {
                countriesDescription: {},
            },
        });
        const wholeCountriesMatchings = [];
        await recursiveGetList(wholeCountriesMatchings, 'countries-matchings');
        wholeCountriesMatchings.forEach(matching => {
            const country = wholeArray.find(country => country.countriesId === matching.countriesId);
            if (country) {
                if (!country.countriesMatchings) country.countriesMatchings = {};
                country.countriesMatchings[matching.id] = matching;
            }
        });
        const list = wholeArray.map(mutateCountry);
        setCountriesList(sortObjectArray(list, 'label'));
    }

    const [currenciesList, setCurrenciesList] = useState([]);
    const mutateCurrency = one => {
        return {
            ...one,
            value: one.id,
            label: `${one.currenciesName} (${one.currenciesSymbol})`,
        }
    }
    const fetchCurrenciesList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'currencies');
        const list = wholeArray.map(mutateCurrency);
        setCurrenciesList(sortObjectArray(list, 'label'));
    }

    const [taxCategoriesList, setTaxCategoriesList] = useState([]);
    const mutateTaxCategory = one => {
        return {
            ...one,
            value: one.id,
            label: one.name,
        }
    }
    const fetchTaxCategoriesList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'tax-categories-values');
        const list = wholeArray.map(mutateTaxCategory);
        setTaxCategoriesList(sortObjectArray(list, 'label'));
    }

    const [languagesList, setLanguagesList] = useState([]);
    const mutateLanguage = one => {
        return {
            ...one,
            value: one.id,
            label: one.name + ` (${one.code})`,
        }
    }
    const fetchLanguagesList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'languages');
        const list = wholeArray.map(mutateLanguage);
        setLanguagesList(sortObjectArray(list, 'label'));
    }

    const [unitsList, setUnitsList] = useState([]);
    const mutateUnit = one => {
        const descs = Object.values(one.unitsDescription || {});
        let label = '';
        if (descs.length) {
            label = getValueBasedOnLanguages(descs, 'unitsName');
        }
        return {
            ...one,
            value: one.id,
            label,
        }
    }
    const fetchUnitsList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'units', {
            associations: {
                unitsDescription: {},
            },
        });
        const list = wholeArray.map(mutateUnit);
        setUnitsList(sortObjectArray(list, 'label'));
    }

    const [ordersHoldStatusList, setOrdersHoldStatusList] = useState([]);
    const mutateOrdersHoldStatus = one => {
        const descs = Object.values(one.ordersHoldStatusDescription || {});
        let label = `(${one.ordersHoldStatusCode})`;
        if (descs.length) {
            const name = getValueBasedOnLanguages(descs, 'ordersHoldStatusName');
            if (name) label = name;
        }
        return {
            ...one,
            value: one.id,
            label,
        }
    }
    const fetchOrdersHoldStatusList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'orders-hold-status', {
            associations: {
                ordersHoldStatusDescription: {},
            }
        });
        const list = wholeArray.map(mutateOrdersHoldStatus);
        setOrdersHoldStatusList(sortObjectArray(list, 'label'));
    }

    const [paymentProvidersList, setPaymentProvidersList] = useState([]);
    const mutatePaymentProvider = one => {
        return {
            ...one,
            value: one.id,
            label: one.paymentProvidersName,
        }
    }
    const fetchPaymentProvidersList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'payment-providers');
        const list = wholeArray.map(mutatePaymentProvider);
        setPaymentProvidersList(sortObjectArray(list, 'label'));
    }

    const [productsClassificationAvailabilityTypesList, setProductsClassificationAvailabilityTypesList] = useState([]);
    const mutateProductsClassificationAvailabilityType = one => {
        const descs = Object.values(one.productsClassificationAvailabilityTypesDescription || {});
        let label = `(${one.availabilityTypesCode})`;
        if (descs.length) {
            const name = getValueBasedOnLanguages(descs, 'name');
            if (name) label = name;
        }
        return {
            ...one,
            value: one.id,
            label,
        }
    }
    const fetchProductsClassificationAvailabilityTypesList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'products-classification-availability-types', {
            associations: {
                productsClassificationAvailabilityTypesDescription: {}
            }
        })
        const list = wholeArray.map(mutateProductsClassificationAvailabilityType);
        setProductsClassificationAvailabilityTypesList(sortObjectArray(list, 'label'));
    }

    const [productsClassificationDeliveryTimesTypesList, setProductsClassificationDeliveryTimesTypesList] = useState([]);
    const mutateProductsClassificationDeliveryTimesType = one => {
        const descs = Object.values(one.productsClassificationDeliveryTimesTypesDescription || {});
        let label = `(${one.deliveryTimesTypesCode})`;
        if (descs.length) {
            const name = getValueBasedOnLanguages(descs, 'name');
            if (name) label = name;
        }
        return {
            ...one,
            value: one.id,
            label,
        }
    }
    const fetchProductsClassificationDeliveryTimesTypesList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'products-classification-delivery-times-types', {
            associations: {
                productsClassificationDeliveryTimesTypesDescription: {}
            }
        })
        const list = wholeArray.map(mutateProductsClassificationDeliveryTimesType);
        setProductsClassificationDeliveryTimesTypesList(sortObjectArray(list, 'label'));
    }

    const [productsAttributesSetsList, setProductsAttributesSetsList] = useState([]);
    const mutateProductsAttributesSet = (one) => {
        const descs = Object.values(one.productsAttributesSetsDescription || {});
        let label = `(${one.productsAttributesSetsCode})`;
        if (descs.length) {
            const name = getValueBasedOnLanguages(descs, 'name');
            if (name) label = name;
        }
        return {
            ...one,
            value: one.id,
            label,
        }
    }
    const fetchProductsAttributesSetsList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'products-attributes-sets', {
            associations: {
                productsAttributesSetsDescription: {},
            }
        })
        const list = wholeArray.map(mutateProductsAttributesSet);
        setProductsAttributesSetsList(sortObjectArray(list, 'label'));
    }

    const [stockLocationsList, setStockLocationsList] = useState([]);
    const mutateStockLocation = (one) => {
        return {
            ...one,
            value: one.id,
            label: one.stockLocationsName,
        }
    }
    const fetchStockLocationsList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'stock-locations');
        const list = wholeArray.map(mutateStockLocation);
        setStockLocationsList(sortObjectArray(list, 'label'));
    }

    const [suppliersList, setSuppliersList] = useState([]);
    const mutateSupplier = (one) => {
        return {
            ...one,
            value: one.id,
            label: one.suppliersName,
        }
    }
    const fetchSuppliersList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'suppliers');
        const list = wholeArray.map(mutateSupplier);
        setSuppliersList(sortObjectArray(list, 'label'));
    }

    const [productConditionsList, setProductConditionsList] = useState([]);
    const mutateProductCondition = (one) => {
        const descs = Object.values(one.productsConditionsDescription || {});
        let label = `(${one.productsConditionsCode})`;
        if (descs.length) {
            const name = getValueBasedOnLanguages(descs, 'productsConditionsName');
            if (name) label = name;
        }
        return {
            ...one,
            value: one.id,
            label,
        }
    }
    const fetchProductConditionsList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'products-conditions');
        const list = wholeArray.map(mutateProductCondition);
        setProductConditionsList(sortObjectArray(list, 'label'));
    }

    const [brandsList, setBrandsList] = useState([]);
    const mutateBrand = (one) => {
        return {
            ...one,
            value: one.id,
            label: one.brandsName,
        }
    }
    const fetchBrandsList = async () => {
        const wholeArray = [];
        await recursiveGetList(wholeArray, 'brands');
        const list = wholeArray.map(mutateBrand);
        setBrandsList(sortObjectArray(list, 'label'));
    }

    // const fetchDebug = async () => {
    //     const resource = 'products-conditions';
    //     const wholeArray = [];
    //     await recursiveGetList(wholeArray, resource, {
    //         associations: {
    //             productsConditionsDescription: {}
    //         }
    //     });
    //     console.log(resource, wholeArray);
    // }

    useEffect(() => {
        // fetchCountriesList();
        // fetchCurrenciesList();
        // fetchTaxCategoriesList();
        // fetchLanguagesList();
        // fetchUnitsList();
        // fetchOrdersHoldStatusList();
        // fetchPaymentProvidersList();
        // fetchProductsClassificationAvailabilityTypesList();
        // fetchProductsClassificationDeliveryTimesTypesList();
        // fetchBrandsList();

        // fetchConfigurationObj();
        // fetchProductsAttributesSetsList();
        // fetchStockLocationsList();
        // fetchSuppliersList();
        // fetchProductConditionsList();
        // fetchDebug();
        /* eslint-disable-next-line */
    }, []);

    return (
        <RelatedDataContext.Provider
            value={{
                taxCategoriesList,
                mutateTaxCategory,
                setTaxCategoriesList,
                fetchTaxCategoriesList,
                countriesList,
                mutateCountry,
                setCountriesList,
                fetchCountriesList,
                currenciesList,
                mutateCurrency,
                setCurrenciesList,
                fetchCurrenciesList,
                languagesList,
                mutateLanguage,
                setLanguagesList,
                fetchLanguagesList,
                unitsList,
                mutateUnit,
                setUnitsList,
                fetchUnitsList,
                ordersHoldStatusList,
                mutateOrdersHoldStatus,
                setOrdersHoldStatusList,
                fetchOrdersHoldStatusList,
                paymentProvidersList,
                mutatePaymentProvider,
                setPaymentProvidersList,
                fetchPaymentProvidersList,
                productsClassificationAvailabilityTypesList,
                mutateProductsClassificationAvailabilityType,
                setProductsClassificationAvailabilityTypesList,
                fetchProductsClassificationAvailabilityTypesList,
                productsClassificationDeliveryTimesTypesList,
                mutateProductsClassificationDeliveryTimesType,
                setProductsClassificationDeliveryTimesTypesList,
                fetchProductsClassificationDeliveryTimesTypesList,
                brandsList,
                mutateBrand,
                setBrandsList,
                fetchBrandsList,

                configurationObj,
                setConfigurationObj,
                fetchConfigurationObj,
                productsAttributesSetsList,
                mutateProductsAttributesSet,
                setProductsAttributesSetsList,
                fetchProductsAttributesSetsList,
                stockLocationsList,
                mutateStockLocation,
                setStockLocationsList,
                fetchStockLocationsList,
                suppliersList,
                mutateSupplier,
                setSuppliersList,
                fetchSuppliersList,
                productConditionsList,
                mutateProductCondition,
                setProductConditionsList,
                fetchProductConditionsList,
            }}
        >
            {children}
        </RelatedDataContext.Provider>
    )
}
