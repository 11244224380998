import React, { useState } from 'react';
import { Form, SaveButton, SelectInput, TextInput, Title, useTranslate } from 'react-admin';
import { RichTextField } from 'ra-ui-materialui';
import { Grid, TextField } from "@mui/material";
import axios from "axios";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));

const ProductSearchForm = () => {
    const classes = useStyles();

    const textTypeChoices = [
        {
            id: "product title",
            name: "Product title"
        },
        {
            id: "product description",
            name: "Product description"
        },
        {
            id: "product short description",
            name: "Product short description"
        },
        {
            id: "product meta description",
            name: "Product meta description"
        }
    ];

    const textAgeGroupsChoices = [
        {
            id: "kids",
            name: "0-7 years"
        }, {
            id: "juvenile",
            name: "13-19 years"
        }, {
            id: "adult",
            name: "19-30 years"
        }, {
            id: "older people",
            name: "31-59 years"
        }, {
            id: "oldest people",
            name: "60-90 years"
        }
    ]

    const genderChoices = [
        {
            id: "male",
            name: "Male"
        }, {
            id: "female",
            name: "Female"
        }, {
            id: "divers",
            name: "Divers"
        }
    ]

    const textTargetLanguageChoices = [
        {
            id: "german",
            name: "German"
        }, {
            id: "english",
            name: "English"
        }, {
            id: "klingon",
            name: "Klingon"
        },
    ]

    const textCharacteristicChoices = [
        {
            id: "neutral",
            name: "neutral"
        },
        {
            id: "friendly,accommodating,friendly,benevolent",
            name: "friendly"
        },
        {
            id: "euphoric,profusely,cheered up",
            name: "euphoric"
        }
    ];

    const [serviceResponse, setServiceResponse] = useState("Initial");

    const [value, setValue] = useState({
        description: {},
    });

    const JSONtoCSV = (input) => {
        let output = `${input.description.productsName}, ${input.description.productsNameShort}, ${input.description.productsDescription}`;

        output += " and the following properties: ";

        Object.values(input.attributes).forEach(attribute => {
            if (attribute.value) {
                output += `, ${attribute.name}: ${attribute.value}`;
            }
        });

        return output;
    }

    const searchProduct = (data) => {
        let brickfoxApiUrl = JSON.parse(localStorage.getItem("bb2Config"))?.brickfoxApiUrl;
        let brickfoxApiKey = JSON.parse(localStorage.getItem("bb2Config"))?.brickfoxApiKey;

        axios.get(`${brickfoxApiUrl}?itemNumber=${data.productItemNumber}`, {
            headers: {
                apikey: brickfoxApiKey
            }
        })
            .then(response => {
                //setValue(response.data.data[0]);
                setValue(JSONtoCSV(response.data.data[0]));
            });
    };

    const postSave = (data) => {
        let textLimit = "";

        if(data.textType === "product title") {
            textLimit = "80"
        } else if(data.textType === "product description") {
            textLimit = "1600-4000"
        } else if(data.textType === "product meta description") {
            textLimit = "160"
        } else if(data.textType === "product short description") {
            textLimit = "300"
        }

        axios.post(process.env.REACT_APP_CONTENT_GENERATION_URL   , {
            customer: "bb2",
            id: "test",
            queueId: "test",
            context: {
                customer: "bb2",
                extra: {
                    chatGptKey: process.env.REACT_APP_CHAT_GPT_KEY,
                    parameters: {
                        textType: data.textType,
                        textInput: data.textInput === undefined ? '' : data.textInput,
                        textCharacteristic: data.textCharacteristic,
                        ageGroup: data.ageGroup,
                        textLimit,
                        outputLanguage: data.outputLanguage,
                        gender: data.gender
                    },
                    template: "I want you to act as a copy writer. Please write me a {textType} in {outputLanguage} language for {gender} customers with {textCharacteristic} sounding and for the age group {ageGroup}. Keep in mind the maximum length of {textLimit} characters for the output. The input text is {textInput}"
                }
            }
        })
            .then(response => {
                setServiceResponse(response.data.text + " (" + response.data.text.length + " Zeichen)");
            });
    };

    return (
        <>
            {/*<TextField source="productExternalId" placeholder={"Product external id"} value={value.externProductsId}/>*/}
            {/*<TextField source="productNumber" placeholder={"Product number"} value={value.itemNumber}/>*/}
            {/*<TextField source="productLongTitle" placeholder={"Product title long"} value={value.description.productsName}/>*/}
            {/*<TextField source="productShortTitle" placeholder={"Product title short"} value={value.description.productsNameShort}/>*/}
            {/*<TextField source="productLongDescription" placeholder={"Product long description"} value={value.description.productsDescription}/>*/}
            {/*<TextField source="productShortDescription" placeholder={"Product short description"} value={value.description.productsDescriptionShort}/>*/}

            <div style={{"margin-top": "1rem"}}>
                <Form onSubmit={searchProduct}>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <TextInput fullWidth placeholder={"Product item number"} label={"Product item number"}
                                       source={"productItemNumber"}/>
                        </Grid>
                        <Grid item md={6}>
                            <SaveButton label={"Produkt suchen"}/>
                        </Grid>
                    </Grid>
                </Form>
            </div>

            <TextField label={"Product data input"} source="productSearchResultData" placeholder={" - "} value={value}
                       readOnly style={{display: "none"}}/>

            <div className={classes.root}>
                <Form onSubmit={postSave}>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <TextInput placeholder={"Extra input"} label={"Extra input"} source={"textInput"}
                                       fullWidth/>
                        </Grid>
                        <Grid item md={6}>
                            <SelectInput source={"textType"} placeholder={"Text type"} label={"Text type"}
                                         choices={textTypeChoices} fullWidth/>
                        </Grid>
                        <Grid item md={6}>
                            <SelectInput source={"textCharacteristic"} placeholder={"Text characteristic"}
                                         label={"Text characteristic"}
                                         choices={textCharacteristicChoices} fullWidth/>
                        </Grid>
                        <Grid item md={6}>
                            <SelectInput source={"gender"} placeholder={"Gender"}
                                         label={"Gender"}
                                         choices={genderChoices} fullWidth/>
                        </Grid>
                        <Grid item md={6}>
                            <SelectInput source={"ageGroup"} placeholder={"Target age group"}
                                         label={"Target age group"}
                                         choices={textAgeGroupsChoices} fullWidth/>
                        </Grid>
                        <Grid item md={6}>
                            <SelectInput source={"outputLanguage"} placeholder={"Target language"}
                                         label={"Target language"}
                                         choices={textTargetLanguageChoices} fullWidth/>
                        </Grid>
                        <Grid item md={12}>
                            <SaveButton label={"Text Generieren"}/>
                        </Grid>
                    </Grid>
                </Form>
            </div>

            <RichTextField style={{background: "white", color: "black", padding: "1rem"}} source="body" record={{body: serviceResponse}}/>
            {/*<TextField label={"Service response"} source="serviceResponse" placeholder={" - "} value={serviceResponse}
                       readOnly/>*/}
        </>
    );
}

export const ContentGeneration = (props) => {
    const translate = useTranslate();

    return (
        <>
            <Title title={translate('resources.content_generation.name')}/>
            <ProductSearchForm/>
        </>
    )
}
