import React, { useMemo, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useNotify, useTranslate } from "react-admin";
import { useQuery } from "react-query";
import MuiSelect from "components/MuiSelect";
import ApexChart from "components/ApexChart";
import CircularProgressAbsolute from "components/CircularProgressAbsolute";
import { bb2api } from "../bb2-api";
import moment from "moment";
import { useBB2MandantContext } from "../BB2MandantContext";

const timeRnageOptions = [
  "last_12_months",
  "last_month",
  "last_7_days",
  "last_24_hours",
];

const TrafficLineChart = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const { getMandantTokens, mandant } = useBB2MandantContext();
  const [timeRange, setTimeRange] = useState(timeRnageOptions[0]);
  const timeFormat = useMemo(() => {
    switch (timeRange) {
      case "last_12_months":
        return "MMM-yyyy";
      case "last_month":
      case "last_7_days":
      case "last_24_hours":
      default:
        return "DD.MM.yyyy";
    }
  }, [timeRange]);
  const [trafficName, setTrafficName] = useState("");
  const { data: trafficData, isLoading } = useQuery(["usage-workflow", mandant, timeRange], async () => {
    const fetchTraffic = async (from, to) => {
      const { data, error } = await bb2api.getUsageWorkflow(from, to, getMandantTokens().billingToken);
      if (error) {
        notify(error.message, { type: "error" });
        return 0;
      }
      let sum = 0;
      data.forEach(({ name, data }) => {
        setTrafficName(name);
        data.forEach(({ total_usage_per_workflow }) => {
          sum += Number(total_usage_per_workflow);
        });
      });
      return sum;
    }

    const times = {};
    switch (timeRange) {
      case "last_12_months": {
        const addTimes = (beginMom, obj) => {
          const key = beginMom.clone().startOf("month").format("yyyy-MM-DD");
          const from = beginMom.clone().startOf("month").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
          const to = beginMom.clone().add(1, "month").startOf("month").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
          obj[key] = { from, to };
        };
        const startMom = moment().startOf("month");
        for (let i = 11; i > 0; i--) {
          const mom = startMom.clone().subtract(i, "month");
          addTimes(mom, times);
        }
        addTimes(startMom, times);
        break;
      }
      case "last_month":
      case "last_7_days": {
        const addTimes = (beginMom, obj) => {
          const key = beginMom.clone().startOf("day").format("yyyy-MM-DD");
          const from = beginMom.clone().startOf("day").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
          const to = beginMom.clone().add(1, "day").startOf("day").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
          obj[key] = { from, to };
        }
        const startMom = moment().startOf("day");
        const days = timeRange === "last_month" ? 30 : 7;
        for (let i = (days - 1); i > 0; i--) {
          const mom = startMom.clone().subtract(i, "day");
          addTimes(mom, times);
        }
        addTimes(startMom, times);
        break;
      }
      case "last_24_hours": {
        const addTimes = (beginMom, obj) => {
          const key = beginMom.format("HH:mm");
          const from = beginMom.clone().format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
          const to = beginMom.clone().add(1, "hour").subtract(1, "second").format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
          obj[key] = { from, to };
        }
        const startMom = moment().subtract(1, "hour");
        for (let i = 23; i > 0; i--) {
          const mom = startMom.clone().subtract(i, "hour");
          addTimes(mom, times);
        }
        addTimes(startMom, times);
        break;
      }
      default:
        break;
    }
    const keys = Object.keys(times);
    const promises = keys.map((key) => {
      const { from, to } = times[key];
      return fetchTraffic(from, to);
    });
    const results = await Promise.all(promises);
    const data = keys.map((key, i) => ({
      x: key,
      y: results[i],
    }));
    return data;
  });

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container sx={{ position: "relative" }}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">{translate("bb2.pages.overview")}</Typography>
          <Typography variant="caption">{translate("bb2.fields.total_traffic")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <MuiSelect
            fullWidth
            options={timeRnageOptions.map((value) => ({
              value,
              label: translate(`bb2.fields.${value}`),
            }))}
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <ApexChart
            id="traffic-line-chart"
            type="line"
            dateFormat={timeFormat}
            colors={[process.env.REACT_APP_THEME_COLORS_PRIMARY_GREEN]}
            series={[
              {
                name: trafficName,
                data: trafficData,
              }
            ]}
          />
        </Grid>
        <CircularProgressAbsolute active={isLoading} />
      </Grid>
    </Paper>
  );
};

export default TrafficLineChart;
