import React from "react";
import { useTranslate } from "react-admin";
import Iframe from "react-iframe";
import { useEntityAccess } from "contexts/EntityAccessContextContainer.js";

const IFrame = ({ domain = "", path = "" }) => {
  const translate = useTranslate();
  const { bb2Config: { apiUrl } } = useEntityAccess();
  let url = apiUrl + "/" + path;
  if (!apiUrl.startsWith("http")) url = "https://" + url;
  if (path.startsWith("http")) url = path;
  return (
    <Iframe
      name={"BusinessOneUi"}
      url={url}
      width="100%"
      height="100%"
      position="relative"
      frameBorder="0"
      loading={translate("pos.iframe.loading")}
    />
  );
};

export default IFrame;
